/* eslint-disable no-console */
const handleError = (error) => {
  let message = {
    html: 'Network Error.',
    icon: 'fa-warning',
    timeout: 2500,
  };

  //Setup Error Message
  if (typeof error !== 'undefined') {
    if (error.message) message.html = error.message;
  }

  if (typeof error.response !== 'undefined') {
    //Setup Generic Response Messages
    if (error.response.status === 400) {
      message.html = 'Bad Request';
      //Emit Logout Event
    }
    if (error.response.status === 401) {
      message.html = 'UnAuthorized';
      //Emit Logout Event
    } else if (error.response.status === 404) {
      message.html = 'API Route is Missing or Undefined';
    } else if (error.response.status === 405) {
      message.html = 'API Route Method Not Allowed';
    } else if (error.response.status === 422) {
      //Validation Message
    } else if (error.response.status >= 500) {
      message.html = 'Server Error';
    }

    //Try to Use the Response Message
    if (error.response && error.response.data) {
      if (error.response.data.message && error.response.data.message.length > 0)
        message.html = error.response.data.message;
    }
  }

  //Toast the Message
  if (message.html.length > 0) {
    console.log(message);
  }
};

export default handleError;
