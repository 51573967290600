import { gql } from '@apollo/client';

const CREATE_PROVIDER = gql`
  mutation createProvider($provider: AddProviderInput!) {
    addProvider(addProviderInput: $provider) {
      _id
    }
  }
`;

const UPDATE_PROVIDER = gql`
  mutation modifyProvider($provider: EditProviderInput!) {
    editProvider(editProviderInput: $provider) {
      _id
    }
  }
`;

const UPDATE_PROVIDER_BULK_ENABLE_DISABLE = gql`
  mutation updateBulkEnableDisable($provider: EnableDisableBulkInput!) {
    enableDisableProviders(enableDisableBulkInput: $provider) {
      ok
      Ids
    }
  }
`;

export {
  CREATE_PROVIDER,
  UPDATE_PROVIDER,
  UPDATE_PROVIDER_BULK_ENABLE_DISABLE,
};
