import { gql } from '@apollo/client';
const FETCH_CUSTOM_REPORTS = gql`
  query fetchCustomReports($filter: QueryFilter!) {
    customReports(queryFilter: $filter) {
      items {
        _id
        reportTitle
        dateFilter
        metrics {
          metricType
        }
        updatedAt
        customReportStatus
      }
      page
      totalCount
    }
  }
`;

const FETCH_CUSTOM_REPORT_BY_ID = gql`
  query fetchCustomReport($id: String!) {
    customReport(id: $id) {
      _id
      reportTitle
      dateFilter
      charts {
        chartTitle
        chartType
        limitResult
        orderByMetric
        metricOrder
        dimensions
        breakdown1
        breakdown2
        breakdown3
      }
      filters {
        fieldType
        fieldValue
        operator
      }
      metrics {
        metricType
        includeTotal
      }
    }
  }
`;

const GET_DOWNLOAD_LINK = gql`
  query downloadCustomReport($reportFilter: ReportFilterInput!) {
    downloadCustomReport(reportFilter: $reportFilter) {
      url
    }
  }
`;

const generateFetchCustomReportQuery = (metrics) => {
  const metricTypes = {
    Clicks: 'clicks',
    Impressions: 'impressions',
    Revenue: 'revenue',
    CPC: 'cpc',
    eCPC: 'eCPC',
    CTR: 'ctr',
    eCPM: 'eCPM',
  };
  let metricsString = '';
  if (metrics?.length) {
    metrics.map((metric) => {
      metricsString += ' ' + metricTypes[metric.metricType];
    });
  }
  return gql`
    query fetchChartsForCustomReport($reportFilter: ReportFilterInput!) {
      charts(reportFilter: $reportFilter) {
          totals {
              values {
                  metricName
                  metricValue
              }
          }
          allTimeTotals {
              values {
                  metricName
                  metricValue
              }
          }
        charts {
          chartTitle
          chartType
          dimensions
          count
          data {
            breakdownFields {
              key
              value
            }
            totals {
              key
              value
            }
            breakdown {
              breakdownFields {
                key
                value
              }
              totals {
                key
                value
              }
              breakdown {
                breakdownFields {
                  key
                  value
                }
                totals {
                  key
                  value
                }
                breakdown {
                  reportDate
                  displayName
                    ${metricsString}
                }
              }
            }
          }
        }
      }
    }
  `;
};
export {
  FETCH_CUSTOM_REPORTS,
  generateFetchCustomReportQuery,
  GET_DOWNLOAD_LINK,
  FETCH_CUSTOM_REPORT_BY_ID,
};
