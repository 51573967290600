import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  showRequestSpinner,
  hideRequestSpinner,
} from '../../redux/store/ui/spinner';
import { showSnakbar } from '../../redux/store/ui/snakbar';

const useGraphQuery = (props) => {
  const dispatch = useDispatch();
  const { loading, error, data } = useQuery(props, {
    onError: (error) => {},
  });

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        dispatch(showRequestSpinner());
        // dispatch(showSnakbar({ severity: 'warning', message: 'Fetching record(s)' }));
      }, 0);
    }
    if (error) {
      setTimeout(() => {
        dispatch(hideRequestSpinner());
        dispatch(
          showSnakbar({
            severity: 'error',
            message: error?.message || 'Error occurred save/update record(s)',
          }),
        );
      }, 0);
    }
    if (data) {
      setTimeout(() => {
        dispatch(hideRequestSpinner());
        // dispatch(showSnakbar({ severity: 'success', message: 'Successfully fetched record(s)' }));
      }, 0);
    }
  }, [loading, error, data]);

  return { loading, error, data };
};

export default useGraphQuery;
