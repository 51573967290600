import { createStore, compose } from 'redux';
import applyMiddleware from '../../middlewares';
import RootReducer from './RootReducer';

const middlewares = [
  applyMiddleware,
  ...(process.env.NODE_ENV !== 'production' &&
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? [window.__REDUX_DEVTOOLS_EXTENSION__()]
    : []),
];

const enhancer = compose(...middlewares);
export const store = createStore(RootReducer, {}, enhancer);
