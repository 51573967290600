import { fade, makeStyles } from '@material-ui/core/styles';
import { drawerWidth } from '../../constants/app';

export const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  root: {
    display: 'flex',
  },
  appBar: {
    background: theme.palette.common.white,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: ' 0px 4px 4px #2B2D3703',
    borderBottom: '1px solid #F5F6FA',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    minHeight: '80px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  logo: {
    width: '200px',
    height: '80px',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: '100%',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    display: 'flex',
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(2, 2, 2, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1.375),
  },
  btnProfile: {
    marginLeft: theme.spacing(1),
  },
  timezone: {
    textAlign: 'left',
    letterSpacing: theme.spacing(0),
    color: '#A0A5BA',
    opacity: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
  },
  timezoneValue: {
    marginLeft: theme.spacing(1.25),
    marginRight: theme.spacing(1.25),
  },
  userName: {
    letterSpacing: '0px',
    color: '#74798C',
    opacity: '1',
    fontSize: '14px',
    fontFamily: 'Poppins, sans-serif',
  },
  filterDropDown: {
    position: 'absolute',
    height: '100%',
    left: `${drawerWidth}px`,
  },
  highLevelFilter: {
    background: '#F7F7F7',
    borderRadius: '5px',
    borderBottom: 'none',
  },
  fullSearch: { width: '23%' },
  menuIcon: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));
