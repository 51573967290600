import { gql } from '@apollo/client';

const getBrandAutoCompleteQuery = (createdUserByModal) => {
  const querySignature = !!createdUserByModal
    ? `query brandsAutoCompleteByUser($userId: String!, $autoCompleteQuery: AutoCompleteQuery!) {
    autoComplete: brandsAutoCompleteByUser(
      userId: $userId,
      autoCompleteQuery: $autoCompleteQuery
    )
    `
    : `query brandsAutoComplete($autoCompleteQuery: AutoCompleteQuery!) {
      autoComplete: brandsAutoComplete(
        autoCompleteQuery: $autoCompleteQuery
      )
      `;

  return gql`
    ${querySignature} {
        hitsCount
        documents {
          _id
          name
          description
          isEnabled
          type
        }
        page
      }
    }
  `;
};

const getPublisherAutoCompleteQuery = (createdUserByModal) => {
  const querySignature = !!createdUserByModal
    ? `query publishersAutoCompleteByUser($userId: String!, $autoCompleteQuery: AutoCompleteQuery!) {
    autoComplete: publishersAutoCompleteByUser(
      userId: $userId,
      autoCompleteQuery: $autoCompleteQuery
    )
    `
    : `query publishersAutoComplete($autoCompleteQuery: AutoCompleteQuery!) {
      autoComplete: publishersAutoComplete(
        autoCompleteQuery: $autoCompleteQuery
      )
      `;

  return gql`
    ${querySignature} {
        hitsCount
        documents {
          _id
          name
          description
          isEnabled
          parentId
          type
        }
        page
      }
    }
  `;
};

const getWebsiteAutoCompleteQuery = (createdUserByModal) => {
  const querySignature = !!createdUserByModal
    ? `query websitesAutoCompleteByUser($userId: String!, $autoCompleteQuery: AutoCompleteQuery!) {
    autoComplete: websitesAutoCompleteByUser(
      userId: $userId,
      autoCompleteQuery: $autoCompleteQuery
    )
    `
    : `query websitesAutoComplete($autoCompleteQuery: AutoCompleteQuery!) {
      autoComplete: websitesAutoComplete(
        autoCompleteQuery: $autoCompleteQuery
      )
      `;

  return gql`
    ${querySignature} {
        hitsCount
        documents {
          _id
          name
          description
          type
        }
        page
      }
    }
  `;
};

const getProviderAutoCompleteQuery = (createdUserByModal) => {
  const querySignature = !!createdUserByModal
    ? `query providersAutoCompleteByUser($userId: String!, $autoCompleteQuery: AutoCompleteQuery!) {
    autoComplete: providersAutoCompleteByUser(
      userId: $userId,
      autoCompleteQuery: $autoCompleteQuery
    )
    `
    : `query providersAutoComplete($autoCompleteQuery: AutoCompleteQuery!) {
      autoComplete: providersAutoComplete(
        autoCompleteQuery: $autoCompleteQuery
      )
      `;

  return gql`
    ${querySignature} {
        hitsCount
        documents {
          _id
          name
          description
          type
        }
        page
      }
    }
  `;
};

const getWidgetAutoCompleteQuery = (createdUserByModal) => {
  const querySignature = !!createdUserByModal
    ? `query widgetsAutoCompleteByUser($userId: String!, $autoCompleteQuery: AutoCompleteQuery!) {
    autoComplete: widgetsAutoCompleteByUser(
      userId: $userId,
      autoCompleteQuery: $autoCompleteQuery
    )
    `
    : `query widgetsAutoComplete($autoCompleteQuery: AutoCompleteQuery!) {
      autoComplete: widgetsAutoComplete(
        autoCompleteQuery: $autoCompleteQuery
      )
      `;

  return gql`
    ${querySignature} {
        hitsCount
        documents {
          _id
          name
          description
          type
        }
        page
      }
    }
  `;
};

const getUserAutoCompleteQuery = (createdUserByModal) => {
  const querySignature = !!createdUserByModal
    ? `query usersAutoCompleteByUser($userId: String!, $autoCompleteQuery: AutoCompleteQuery!) {
    autoComplete: usersAutoCompleteByUser(
      userId: $userId,
      autoCompleteQuery: $autoCompleteQuery
    )
    `
    : `query usersAutoComplete($autoCompleteQuery: AutoCompleteQuery!) {
      autoComplete: usersAutoComplete(
        autoCompleteQuery: $autoCompleteQuery
      )
      `;

  return gql`
    ${querySignature} {
        hitsCount
        documents {
          _id
          name
          description
          isEnabled
          type
        }
        page
      }
    }
  `;
};

export {
  getBrandAutoCompleteQuery,
  getPublisherAutoCompleteQuery,
  getWebsiteAutoCompleteQuery,
  getProviderAutoCompleteQuery,
  getWidgetAutoCompleteQuery,
  getUserAutoCompleteQuery,
};
