import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  appliedFilterWrapper: {
    marginLeft: '8px',
    maxWidth: '1200px',
    position: 'relative',
    zIndex: 5,
  },
}));
