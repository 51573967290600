import { gql } from '@apollo/client';

const CREATE_WEBSITE = gql`
  mutation createWebsite($website: AddWebsiteInput!) {
    addWebsite(addWebsiteInput: $website) {
      _id
    }
  }
`;

const CREATE_WEBSITE_BY_USER = gql`
  mutation addWebsiteByUser($userId: String!, $website: AddWebsiteInput!) {
    addWebsiteByUser(userId: $userId, addWebsiteInput: $website) {
      _id
    }
  }
`;

const UPDATE_WEBSITE = gql`
  mutation modifyWebsite($website: EditWebsiteInput!) {
    editWebsite(editWebsiteInput: $website) {
      _id
    }
  }
`;

const ADD_USER_TO_WEBSITE = gql`
  mutation addUserToWebsite($user: AddUserToWebsiteInput!) {
    addUserToWebsite(addUserToWebsiteInput: $user) {
      _id
    }
  }
`;
const UPDATE_WEBSITE_BULK_ENABLE_DISABLE = gql`
  mutation updateBulkEnableDisable($website: EnableDisableBulkInput!) {
    enableDisableWebsites(enableDisableBulkInput: $website) {
      ok
      Ids
    }
  }
`;

const DELETE_WEBSITE = gql`
  mutation deleteWebsite($id: String!) {
    deleteWebsite(id: $id) {
      _id
      websiteStatus
    }
  }
`;

export {
  CREATE_WEBSITE,
  CREATE_WEBSITE_BY_USER,
  UPDATE_WEBSITE,
  ADD_USER_TO_WEBSITE,
  UPDATE_WEBSITE_BULK_ENABLE_DISABLE,
  DELETE_WEBSITE,
};
