import * as yup from 'yup';
import 'yup-phone';
export const UserSchema = yup.object({
  _id: yup.string(),
  firstName: yup.string().required().min(3),
  lastName: yup.string().required().min(3),
  phone: yup.string().required().phone().min(7),
  email: yup.string().email().required(),
  password: yup.string().when('_id', (_id, schema) => {
    if (!_id)
      return schema.when('enableSetPassword', (enableSetPassword, schema) => {
        if (enableSetPassword)
          return schema
            .required('Please Enter your password')
            .matches(
              process.env.REACT_APP_ENABLE_STRONG_PASSWORD === 'true'
                ? /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
                : '',
              'Must Contain 8 Characters, One Uppercase, Lowercase, Number and special case character',
            );
        return schema;
      });
    return schema;
  }),
  role: yup.string().required().min(3),
  isEnabled: yup.boolean(),
  brandIds: yup.array().when('role', (role, schema) => {
    if (role === 'admin') return schema;
    if (role === 'brand-manager') {
      return schema.min(1).required();
    } else {
      return schema.min(1).max(1).required();
    }
  }),
  publisher: yup.object().when('role', (role, schema) => {
    if (role === 'website-manager') {
      return schema.required();
    }
  }),
});

export const UserModel = {
  _id: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  password: '',
  role: '',
  brandIds: [],
  isEnabled: true,
  publisher: '',
  enableSetPassword: false,
  userStatus: 'Pending',
};

/**
 * Forgot Password
 */

export const ForgotPasswordSchema = yup.object({
  email: yup.string().email().required(),
});

export const ForgotPasswordModel = {
  email: '',
};

export const AdminChangePasswordSchema = yup.object({
  password: yup
    .string()
    .required('Please Enter your password')
    .matches(
      process.env.REACT_APP_ENABLE_STRONG_PASSWORD === 'true'
        ? /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
        : '',
      'Must Contain 8 Characters, One Uppercase, Lowercase, Number and special case character',
    ),
});

export const AdminChangePasswordModel = {
  _id: '',
  password: '',
};
