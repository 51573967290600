import { gql } from '@apollo/client';

const LOOKUP_WEBSITES = gql`
  query fetchLookupWebsites($filter: QueryFilter!) {
    websites(queryFilter: $filter) {
      items {
        _id
        displayName
        websiteCategory
        websiteDomain
        websiteLanguage
        isEnabled
      }
    }
  }
`;

const LOOKUP_EXTERNAL_WIDGETS = gql`
  query fetchExternalWidgets($id: String!) {
    externalWidgetByWidgetId(id: $id) {
      _id
      widgetName
      isEnabled
    }
  }
`;

const LOOKUP_PUBLISHERS = gql`
  query fetchPublisher($filter: QueryFilter!) {
    publishers(queryFilter: $filter) {
      items {
        _id
        publisherName
        isEnabled
        publisherUser {
          _id
          email
        }
      }
    }
  }
`;

const LOOKUP_BRANDS = gql`
  query fetchBrand($filter: QueryFilter!) {
    brands(queryFilter: $filter) {
      items {
        _id
        brandName
        isEnabled
      }
    }
  }
`;

const LOOKUP_WIDGETS = gql`
  query fetchWidgets($filter: QueryFilter!) {
    widgets(queryFilter: $filter) {
      items {
        _id
        widgetName
        widgetType
        isEnabled
        widgetId
      }
    }
  }
`;

const LOOKUP_PROVIDERS = gql`
  query fetchProdivers($filter: QueryFilter!) {
    providers(queryFilter: $filter) {
      items {
        _id
        providerName
        isEnabled
        paymentType
        providerStatus
        dealInfo {
          dealType
        }
        parameters {
          parameterName
          parameterLabel
        }
        adFormats {
          adFormatName
          regex
          parameters {
            parameterName
            parameterLabel
            parameterType
            parameterValue
          }
        }
      }
    }
  }
`;

export {
  LOOKUP_WEBSITES,
  LOOKUP_EXTERNAL_WIDGETS,
  LOOKUP_PUBLISHERS,
  LOOKUP_BRANDS,
  LOOKUP_PROVIDERS,
  LOOKUP_WIDGETS,
};
