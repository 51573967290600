import {
  SET_USERS_ITEMS,
  SET_USERS_TOTAL_COUNT,
  SET_USERS_PAGE,
  APPEND_USERS_ITEMS,
  CLEAR_USERS,
} from '../../../../constants/actionTypes';

export const setUsers = (data) => async (dispatch) => {
  dispatch({
    type: SET_USERS_ITEMS,
    payload: data?.users?.items,
  });
  dispatch({
    type: SET_USERS_TOTAL_COUNT,
    payload: data?.users?.totalCount,
  });
};

export const appendUsers = (data) => async (dispatch) => {
  dispatch({
    type: APPEND_USERS_ITEMS,
    payload: data?.users?.items,
  });
  dispatch({
    type: SET_USERS_TOTAL_COUNT,
    payload: data?.users?.totalCount,
  });
};

export const setUsersPage = (page) => ({
  type: SET_USERS_PAGE,
  payload: page,
});

export const clearUsers = () => ({
  type: CLEAR_USERS,
});
