export const getProviderTagScriptRegex = (provider) => {
  switch (provider) {
    case 'Outbrain':
      return /data-src="(.+?)" data-widget-id="(.+?)"><\/div>/g;
    case 'mgId':
      return /id="(.+?)">\n<\/div>\n<script src="(.+?)"/g;
    case 'MPI':
      return /pubid:(.+?),webid:(.+?),wid:(.+?),/g;
    case 'PropellerAds':
      return /src="(.*?)"/g;
    case 'Jubna':
    case 'Monetized':
      return /<div id="monetizd_(.+?)"><\/div>\n<script type="text\/javascript"> !function\(\){var e={id:"(.+?)",d:"(.+?)",cb/g;
    case 'Taboola':
      return /cdn.taboola.com\/libtrc\/(.+?)\/loader.+?mode: '(.+?)'.*?container: '(.+?)'.*?placement: '(.+?)'.*?target_type: '(.+?)'/gms;
    case 'RevContent':
      return /id="(.+?)" data-rc-widget data-widget-host="habitat" data-endpoint="\/\/trends.revcontent.com" data-widget-id="(.+?)"/g;
    case 'Fortico':
      return /publisher_id'.*?'(\d+)'/g;
    case 'RunNative':
      return /element_id: "(.*?)",.*?spot: "(.*?)".*?cols: (.*?),.*?rows: (.*?),.*?title: "(.*?)",/gms;
    case 'VidSpot':
      return /p=(\d+).*\&w=(\d+)\&h=(\d+).*?\&sid=(.*?)\&gdpr=(.*?)\&gdpr_consent=(.*?)\&us_privacy=(.*?)\&schain=(.*?)"/g;
    case 'AdMaven':
      return /whiid=(.*?)".*?width="(.*?)" height="(.*)"/gms;
    default:
      return;
  }
};

export const getSecondProviderTagScriptRegex = (provider) => {
  switch (provider) {
    case 'PropellerAds':
      return /\('(.*?)',(.*?),/g;
    default:
      return;
  }
};
