import React, { useState, useEffect } from 'react';
import { useStyles } from './BarChartWidget.styles';
import {
  BarSeries,
  Chart,
  Legend,
  ArgumentAxis,
  ValueAxis,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import numeral from 'numeral';
import * as d3Format from 'd3-format';
import GraphContainer from './GraphContainer';
import GraphTabs from './GraphTabs';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { EventTracker, Palette } from '@devexpress/dx-react-chart';
import {
  NUMBER_HUMANIZE_FORMAT,
  SMALL_NUMBER_HUMANIZE_FORMAT,
} from '../../constants/enum';
import { calculateMetric } from './ReportsCalculations';
import { Tooltip as MUIToolTip } from '@material-ui/core/';
import { truncate } from '../../utils';

const BarChartWidget = ({
  widgetName,
  chartData,
  colors,
  tabs,
  selectedTab,
  order = 'DESC',
}) => {
  const [barChartData, setBarChartData] = useState([]);
  let data = [];
  const keys =
    chartData && chartData.length > 0 ? Object.keys(chartData[0]) : [];
  //Dimension

  let [metric, setMetric] = useState(0);
  let dataElement = {};
  barChartData.map((element, index) => {
    dataElement['key' + index] = element['Dimension'];
    dataElement[element['Dimension']] = calculateMetric(
      element,
      tabs[selectedTab],
    );
  });

  const tooltipContentTitleStyle = {
    fontWeight: 'bold',
    paddingBottom: 0,
    fontSize: '15px',
  };
  const tooltipContentBodyStyle = {
    paddingTop: 0,
  };
  const formatTooltip = d3Format.format(',.2r');
  const TooltipContent = (props) => {
    const { targetItem, text, ...restProps } = props;
    return (
      <div>
        <div>
          <Tooltip.Content
            {...restProps}
            style={tooltipContentTitleStyle}
            text={targetItem.series}
          />
        </div>
        <div>
          <Tooltip.Content
            {...restProps}
            style={tooltipContentBodyStyle}
            text={formatTooltip(data[targetItem.point][targetItem.series])}
          />
        </div>
      </div>
    );
  };

  data.push(dataElement);
  const legendRootStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    height: '35%',
    overflow: 'auto',
    paddingTop: '0px',
    paddingBottom: '0px',
    position: 'absolute',
    left: '50%',
    transform: 'translateY(10px) translateX(-50%)',
  };
  const LegendRoot = (props) => {
    return <Legend.Root {...props} style={legendRootStyle} />;
  };

  const legendItemStyle = {
    flexDirection: 'row',
    fontSize: '13px',
    width: '100%',
  };
  const LegendItem = (props) => {
    const barsData = data[0] || {};
    const { name, color } = props?.children[0]?.props;
    return (
      <Legend.Item {...props} style={legendItemStyle}>
        <div style={{ color, width: '100%' }}>
          <FiberManualRecordIcon className={classes.icon} />
          <React.Fragment>
            <MUIToolTip
              title={<div style={{ fontSize: '15px' }}>{name}</div>}
              style={{ fontSize: '15px' }}
              aria-label={name}
            >
              <span className={classes.label}>{truncate(name, 20)}</span>
            </MUIToolTip>
            <span className={classes.percentage}>
              {numeral(barsData[name] || 0).format(
                barsData[name] < 1
                  ? SMALL_NUMBER_HUMANIZE_FORMAT
                  : NUMBER_HUMANIZE_FORMAT,
              )}
            </span>
          </React.Fragment>
        </div>
      </Legend.Item>
    );
  };

  const handleTabChanged = (e, value) => {
    setMetric(value);
  };

  const classes = useStyles();

  useEffect(() => {
    setBarChartData([]);
    setTimeout(
      () =>
        setBarChartData(
          [...chartData].sort((a, b) => {
            if (order === 'DESC') {
              return calculateMetric(a, tabs[selectedTab]) >
                calculateMetric(b, tabs[selectedTab])
                ? -1
                : 1;
            } else {
              return calculateMetric(a, tabs[selectedTab]) >
                calculateMetric(b, tabs[selectedTab])
                ? 1
                : -1;
            }
          }),
        ),
      300,
    );
  }, [chartData, order]);

  return barChartData?.length > 0 ? (
    <GraphContainer widgetName={widgetName}>
      <GraphTabs
        value={metric}
        tabs={keys.splice(1)}
        handleChange={handleTabChanged}
        theme={classes}
      />
      <Chart height={300} data={data} style={{ overflowY: 'scroll' }}>
        <Palette scheme={colors} />
        <ArgumentAxis showLabels={false} showLine={true} showGrid={false} />
        <ValueAxis
          showLabels={true}
          showTicks={true}
          showLine={false}
          showGrid={true}
        />
        {barChartData?.map((element, index) => (
          <BarSeries
            key={keys[selectedTab] + `-${element['Dimension']}-` + index}
            valueField={element['Dimension']}
            argumentField={'key' + index}
            name={element['Dimension']}
            barWidth={0.25}
          />
        ))}

        <Legend
          position="bottom"
          rootComponent={LegendRoot}
          itemComponent={LegendItem}
        />
        <EventTracker />
        <Tooltip contentComponent={TooltipContent} />
      </Chart>
    </GraphContainer>
  ) : null;
};
export default BarChartWidget;
