import { gql } from '@apollo/client';

const CREATE_WIDGET = gql`
  mutation createWidget($widget: AddWidgetInput!) {
    addWidget(addWidgetInput: $widget) {
      _id
    }
  }
`;

const UPDATE_WIDGET = gql`
  mutation modifyWidget($widget: EditWidgetInput!) {
    editWidget(editWidgetInput: $widget) {
      _id
    }
  }
`;

const ADD_AD_GROUP = gql`
  mutation addAdGroup($adGroup: UpsertAdGroup!) {
    addAdGroup(adGroupInput: $adGroup) {
      _id
    }
  }
`;

const EDIT_AD_GROUP = gql`
  mutation editAdGroup($adGroup: UpsertAdGroup!) {
    editAdGroup(adGroupInput: $adGroup) {
      _id
    }
  }
`;

const DELETE_AD_GROUP = gql`
  mutation deleteAdGroup($adGroup: AdGroupDeleteInput!) {
    deleteAdGroup(deleteAdGroupInput: $adGroup) {
      _id
    }
  }
`;

const REMOVE_WIDGET_EXTERNAL = gql`
  mutation removeWidgetExternalWidget($widget: AddWidgetExternalWidgetInput!) {
    deleteWidgetExternalWidget(deleteWidgetExternalWidgetInput: $widget) {
      _id
    }
  }
`;

const ADD_PROVIDER_TO_WIDGET = gql`
  mutation addProviderToWidget($provider: AddWidgetProviderInput!) {
    addProviderToWidget(addWidgetProviderInput: $provider) {
      _id
    }
  }
`;

const UPDATE_WIDGET_BULK_ENABLE_DISABLE = gql`
  mutation updateBulkEnableDisable($widget: EnableDisableBulkInput!) {
    enableDisableWidgets(enableDisableBulkInput: $widget) {
      ok
      Ids
    }
  }
`;

const SYNC_WIDGET_DATA = gql`
  mutation syncWidget($manualApiSyncWidgetInput: ManualApiSyncWidgetInput!) {
    syncWidget(manualApiSyncWidgetInput: $manualApiSyncWidgetInput) {
      reportRuns {
        _id
      }
    }
  }
`;

const DELETE_WIDGET = gql`
  mutation deleteWidget($id: String!) {
    deleteWidget(id: $id) {
      _id
      widgetStatus
    }
  }
`;

export {
  CREATE_WIDGET,
  UPDATE_WIDGET,
  ADD_AD_GROUP,
  EDIT_AD_GROUP,
  REMOVE_WIDGET_EXTERNAL,
  ADD_PROVIDER_TO_WIDGET,
  UPDATE_WIDGET_BULK_ENABLE_DISABLE,
  DELETE_AD_GROUP,
  SYNC_WIDGET_DATA,
  DELETE_WIDGET,
};
