import { gql } from '@apollo/client';

const CREATE_BRAND = gql`
  mutation createBrand($brand: CreateBrandInput!) {
    addBrand(addBrandPostData: $brand) {
      _id
    }
  }
`;

const UPDATE_BRAND = gql`
  mutation modifyBrand($brand: EditBrandInput!) {
    editBrand(editBrandPostData: $brand) {
      _id
    }
  }
`;

const UPDATE_BRAND_BULK_STATUS = gql`
  mutation updateBulkStatus($brand: UpdateBrandStatusBulkInput!) {
    updateBrandStatusBulk(updateBrandStatusBulkInput: $brand) {
      ok
    }
  }
`;

const UPDATE_BRAND_BULK_ENABLE_DISABLE = gql`
  mutation updateBulkEnableDisable($brand: UpdateBrandStatusBulkInput!) {
    updateBrandenableDisableBulk(updateBrandStatusBulkInput: $brand) {
      ok
      Ids
    }
  }
`;

const DELETE_BRAND = gql`
  mutation deleteBrand($id: String!) {
    deleteBrand(id: $id) {
      _id
      brandStatus
    }
  }
`;

export {
  CREATE_BRAND,
  UPDATE_BRAND,
  UPDATE_BRAND_BULK_STATUS,
  UPDATE_BRAND_BULK_ENABLE_DISABLE,
  DELETE_BRAND,
};
