import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  hideSnakbar,
  selectSnakBarIsOpen,
  selectSnakBarMessage,
  selectSnakBarSeverity,
} from '../../redux/store/ui/snakbar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const Notify = () => {
  const classes = useStyles();
  const [transition] = useState(() => TransitionDown);
  const open = useSelector(selectSnakBarIsOpen);
  const severity = useSelector(selectSnakBarSeverity);
  const message = useSelector(selectSnakBarMessage);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    dispatch(hideSnakbar());
  };
  if (!!message && message.length > 0) {
    return (
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionComponent={transition}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MuiAlert
          onClose={handleClose}
          severity={severity}
          elevation={6}
          variant="filled"
        >
          {message}
        </MuiAlert>
      </Snackbar>
    );
  } else {
    return null;
  }
};

export default Notify;
