import { combineReducers } from 'redux';
import { authReducer, initialState as authInitialState } from '../store/auth';
import {
  snakbarReducer,
  initialState as snakbarInitialState,
} from '../store/ui/snakbar';
import {
  sidenavReducer,
  initialState as sidenavInitialState,
} from '../store/ui/sidenav';
import {
  spinnerReducer,
  initialState as spinnerInitialState,
} from '../store/ui/spinner';
import {
  commonReducer,
  initialState as commonInitialState,
} from '../store/ui/common';
import { websitesReducer } from '../store/appData/websites';
import { widgetsReducer } from '../store/appData/widgets';
import { providersReducer } from '../store/appData/providers';
import { publishersReducer } from '../store/appData/publishers';
import { brandsReducer } from '../store/appData/brands';
import { customReportsReducer } from '../store/appData/custom-reports';
import { usersReducer } from '../store/appData/users';
import { lookUpReducer } from '../store/ui/lookUp';
import { filtersReducers } from '../store/ui/filters';
import { deductionsReducer } from '../store/appData/deductions';

export const rootInitialState = {
  auth: authInitialState,
  ui: {
    common: commonInitialState,
    sidenav: sidenavInitialState,
    snakbar: snakbarInitialState,
    spinnerReducer: spinnerInitialState,
  },
};

const rootReducer = combineReducers({
  auth: authReducer,
  appData: combineReducers({
    brands: brandsReducer,
    customReports: customReportsReducer,
    publishers: publishersReducer,
    websites: websitesReducer,
    widgets: widgetsReducer,
    providers: providersReducer,
    users: usersReducer,
    deductions: deductionsReducer,
  }),
  ui: combineReducers({
    common: commonReducer,
    filters: filtersReducers,
    lookUp: lookUpReducer,
    sidenav: sidenavReducer,
    snakbar: snakbarReducer,
    spinner: spinnerReducer,
  }),
});

export default rootReducer;
