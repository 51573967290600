export const initialState = {
  isFilterPanelOpen: false,
  target: null,
  targetType: null,
  USERS: {},
  BRANDS: {},
  PUBLISHERS: {},
  WEBSITES: {},
  WIDGETS: {},
  PROVIDERS: {},
  REPORTS_BY_PROVIDER: {},
  REPORTS_BY_COUNTRY: {},
  REPORTS_BY_WIDGET: {},
  REPORTS_BY_WEBSITE: {},
  VIEW_CUSTOM_REPORT: {},
};
