import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: '#fff',
  },
  signInLogo: {
    borderRadius: '50%',
    width: '0px',
    padding: '12.5%',
    backgroundColor: '#fff',
    backgroundPosition: 'center center',
    backgroundSize: '60%',
    backgroundRepeat: 'no-repeat',
  },
  spinner: {
    width: '20px',
    height: '20px',
    alignSelf: 'center',
  },
}));
