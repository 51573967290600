import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  input: {
    '& .MuiOutlinedInput-root': {
      width: '100%',
      background: '#F7F7F7',
      height: '45px',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: '0px',
      borderColor: 'none',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: '0px',
      borderColor: 'none',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '0px',
      borderColor: 'none',
    },
  },
  autoComplete: {
    '& .MuiAutocomplete-inputRoot': { borderRadius: '10px' },
  },
  group: {
    margin: '10px',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '16px',
  },
  option: {
    width: '100%',
    color: '#74798C',
    cursor: 'pointer',
    fontSize: '14px',
    paddingLeft: '5px',
  },
  icon: {
    color: '#848899',
  },
}));
