import React, { useCallback, useEffect, useState } from 'react';
import { TextField, InputAdornment, Icon } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useGraphLazyQuery } from '../../core/hooks';
import { FULL_SEARCH } from '../../graphql/query';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import client from '../../core/http/apiClient';
import { first } from 'lodash';
import { useStyles } from './FullSearch.styles';

export default function FullSearch() {
  let history = useHistory();
  const classes = useStyles();
  const [inputValue, setInputValue] = useState();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options?.length === 0;
  const getOptionsDelayed = useCallback(
    debounce((value, callback) => {
      setOptions([]);
      //fullSearch({ variables: { term: value } });
      setTheData(value);
      setOpen(true);
    }, 300),
    [],
  );

  const setTheData = async (data) => {
    let search = await getTypeSenseData(data);
    setOptions(search);
  };

  useEffect(() => {
    if (inputValue?.length >= 3) {
      getOptionsDelayed(inputValue, (filteredOptions) => {
        setOptions(filteredOptions);
      });
      setOpen(true);
    } else {
      setInputValue('');
      setOpen(false);
    }
  }, [inputValue, getOptionsDelayed]);

  const [fullSearch] = useGraphLazyQuery(FULL_SEARCH, (data) => {
    if (data) {
      const { search } = data;
      setOptions(search);
    }
  });

  const getTypeSenseData = async (q) => {
    const response = await client.get(`/autocomplete/query_all?q=${q}`);
    const search = response.data;
    const docs = search.map((obj) => obj.result.documents);
    const result = docs.map((doc) => {
      return doc.map((d) => {
        return {
          _id: d?._id,
          name: d?.name,
          description: d?.description,
          type: d?.type,
        };
      });
    });
    return result.flat();
  };

  const renderGroup = (params) => {
    return [
      <>
        <div className={classes.group} key={params.key}>
          {params.group}
        </div>
      </>,
      params.children,
    ];
  };

  return (
    <Autocomplete
      className={classes.autoComplete}
      clearOnBlur={true}
      clearOnEscape={true}
      fullWidth
      noOptionsText={'No results'}
      autoComplete
      filterSelectedOptions
      id="full-search"
      open={open}
      onClose={() => {
        setInputValue('');
        setOpen(false);
      }}
      getOptionLabel={(option) => {
        return `${option.name}: ${option.description}`;
      }}
      renderOption={(option) => (
        <>
          <div
            className={classes.option}
            onClick={() => {
              if (option?.type === 'CustomReport')
                history.replace(`/custom-reports/view/${option?._id}`);
              else
                history.replace(
                  `/${option?.type?.toLowerCase()}s/view/${option?._id}`,
                );
            }}
          >
            {option?.name}
          </div>
        </>
      )}
      groupBy={(option) => option.type}
      renderGroup={renderGroup}
      options={options}
      popupIcon={null}
      ListboxProps={{ style: { backgroundColor: '#F7F7F7' } }}
      onInputChange={(event, value) => {
        setInputValue(value);
      }}
      renderInput={(params) => (
        <TextField
          className={classes.input}
          {...params}
          fullWidth
          size="small"
          variant="outlined"
          placeholder="Search Everything"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            maxLength: 60,
            endAdornment: (
              <InputAdornment position="end">
                <Icon className={classes.icon}>search</Icon>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
