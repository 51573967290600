import {
  SET_BRANDS_ITEMS,
  SET_BRANDS_TOTAL_COUNT,
  SET_BRANDS_PAGE,
  APPEND_BRANDS_ITEMS,
  CLEAR_BRANDS,
} from '../../../../constants/actionTypes';

export const setBrands = (data) => async (dispatch) => {
  dispatch({
    type: SET_BRANDS_ITEMS,
    payload: data?.brands?.items,
  });
  dispatch({
    type: SET_BRANDS_TOTAL_COUNT,
    payload: data?.brands?.totalCount,
  });
};

export const appendBrands = (data) => async (dispatch) => {
  dispatch({
    type: APPEND_BRANDS_ITEMS,
    payload: data?.brands?.items,
  });
  dispatch({
    type: SET_BRANDS_TOTAL_COUNT,
    payload: data?.brands?.totalCount,
  });
};

export const setBrandsPage = (page) => ({
  type: SET_BRANDS_PAGE,
  payload: page,
});

export const clearBrands = () => ({
  type: CLEAR_BRANDS,
});
