import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './Login.styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Sentry from '@sentry/react';
import { useFormik } from 'formik';
import { UserAuthSchema } from '../../validation/schema/auth.schema';
import { history } from '../../utils/history';
import Alert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import ValidationMessage from '../../validation/message/ValidationMessage';
import { isPermittedToAccess } from '../../utils';
import {
  login,
  selectAuthError,
  selectAuthLoading,
  selectCurrentUser,
} from '../../redux/store/auth';

function Copyright() {
  return (
    <Typography variant="body2" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://ketshwa.com/">
        ketshwa
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Login = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser);
  const isLoading = useSelector(selectAuthLoading);
  const loginError = useSelector(selectAuthError);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (currentUser._id != null) {
      if (isPermittedToAccess('ViewReports', currentUser)) {
        history.replace('/dashboard');
      } else {
        history.replace('/websites');
      }
      Sentry.setUser({ email: currentUser.email });
    }
  }, [currentUser]);

  const AuthUser = (credential) => dispatch(login(credential));
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: UserAuthSchema,
    onSubmit: (values) => AuthUser(values),
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div
          className={classes.signInLogo}
          style={{ backgroundImage: `url(${process.env.PUBLIC_URL}logo.png)` }}
        />
        <Typography id="Login-title" component="h1" variant="h5">
          Sign in
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            {...formik.getFieldProps('email')}
          />
          <ValidationMessage formik={formik} name="email" />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...formik.getFieldProps('password')}
          />
          <div style={{ marginTop: '18px' }}>
            <ValidationMessage formik={formik} name="password" />
          </div>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            id="Login-button"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isLoading}
            startIcon={
              isLoading ? (
                <CircularProgress
                  style={{ width: '20px', height: '20px', marginRight: '10px' }}
                />
              ) : null
            }
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/forgot" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      {loginError?.data?.message && (
        <Alert severity="error">{loginError.data.message}</Alert>
      )}
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Login;
