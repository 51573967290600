/* eslint-disable no-console */
import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import autLink from '../middlewares/token.middleware';
import { onError } from '@apollo/client/link/error';
import { store } from '../redux/config';
import { showSnakbar } from '../redux/store/ui/snakbar';
import authService from '../services/auth.service';
import { clearUser } from '../redux/store/auth';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      if (message === 'Unauthorized') {
        store.dispatch(clearUser());
        store.dispatch(
          showSnakbar({
            severity: 'error',
            message: 'Session expired',
          }),
        );
        authService.removeToken();
      }
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
      return;
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
  uri:
    process.env.REACT_APP_GRPAHQL_URL || 'https://app.ketshwaapp.com/graphql',
});

const link = from([autLink, errorLink, httpLink]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
