import {
  AccountBalanceWallet,
  Dashboard,
  Description,
  DonutLarge,
  FlashOn,
  Group,
  Label,
  Language,
  Person,
  PhotoAlbum,
  Poll,
  Power,
  Public,
  Settings,
  Web,
  Class,
} from '@material-ui/icons';
import React from 'react';

export const bottomnavItems = [];

export const topnavItems = [
  { to: '/dashboard', name: 'Dashboard', exact: true, icon: <Dashboard /> },
  {
    to: '/',
    name: 'Reports',
    exact: false,
    icon: <Description />,
    subElements: [
      {
        to: '/reports-by-provider',
        name: 'By Provider',
        exact: false,
        icon: <Description />,
      },
      {
        to: '/reports-by-country',
        name: 'By Country',
        exact: false,
        icon: <Description />,
      },
      {
        to: '/reports-by-widget',
        name: 'By Widget',
        exact: false,
        icon: <Description />,
      },
      {
        to: '/reports-by-website',
        name: 'By Website',
        exact: false,
        icon: <Description />,
      },
      {
        to: '/external-reports',
        name: 'External reports',
        exact: false,
        icon: <Description />,
      },
    ],
  },
  {
    to: '/custom-reports',
    name: 'Custom Reports',
    exact: false,
    icon: <DonutLarge />,
    subElements: [],
  },
  {
    to: '/',
    name: 'Administration',
    exact: false,
    icon: <Settings />,
    subElements: [
      { to: '/brands', name: 'Brands', exact: false, icon: <Label /> },
      {
        to: '/publishers',
        name: 'Publishers',
        exact: false,
        icon: <PhotoAlbum />,
      },
      { to: '/websites', name: 'Websites', exact: false, icon: <Public /> },
      { to: '/widgets', name: 'Widgets', exact: false, icon: <Poll /> },
      {
        to: '/providers',
        name: 'Providers',
        authorizationType: 'role',
        exact: false,
        icon: <Power />,
      },
      { to: '/users', name: 'Users', exact: false, icon: <Group /> },
      {
        to: '/deductions',
        name: 'Deduct Earnings',
        authorizationType: 'role',
        exact: false,
        icon: <Class />,
      },
    ],
  },
  {
    to: '/',
    name: 'Account',
    exact: false,
    icon: <Person />,
    subElements: [
      // { to: '/users', name: 'Manage users', exact: false, icon: <Web /> },
      { to: '/profile', name: 'My profile', exact: false, icon: <Web /> },
      {
        to: '/earnings',
        name: 'Earnings',
        exact: false,
        icon: <AccountBalanceWallet />,
      },
    ],
  },
  { to: '/settings', name: 'Global Settings', exact: true, icon: <Language /> },
  { to: '/setup', name: 'Setup New Account', exact: true, icon: <FlashOn /> },
];
