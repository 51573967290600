import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  addFilterWrapper: {
    marginRight: '16px',
    position: 'relative',
  },
  addFilterButton: {
    height: '37px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 10px',
    margin: 0,
    whiteSpace: 'nowrap',
    color: '#848899',
    borderRadius: '8px',
    cursor: 'pointer',
    background: '#F7F7F7',
    boxShadow: '0px 0px 1px 1px rgb(132 136 153 / 25%)',
  },
  filterIcon: {
    width: '20px',
    height: '20px',
  },
  buttonText: {
    fontSize: '14px',
    margin: 0,
    marginLeft: '4px',
    color: '#848899',
    fontWeight: 500,
    fontFamily: 'Poppins, sans-serif',
  },
  panelWrapper: {
    zIndex: 1000,
    position: 'absolute',
    top: 'calc(100% + 8px)',
    right: 0,
    width: '250px',
    backgroundColor: 'transparent',
  },
}));
