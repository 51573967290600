import React, { useEffect } from 'react';
import PaperContainer from '../../composition/PaperContainer';
import { useStyles } from './ViewCustomReport.styles';
import { useGraphLazyQuery, useNoLoaderGraphQuery } from '../../core/hooks';
import { urlParamFilter } from '../../utils';
import {
  FETCH_CUSTOM_REPORT_BY_ID,
  generateFetchCustomReportQuery,
} from '../../graphql/query';
import {
  selectDateRangeValue,
  selectSelectedCustomReport,
  setCustomReportTableChartPage,
  setLastNode,
  setSelectedCustomReport,
} from '../../redux/store/ui/common';
import { useDispatch, useSelector } from 'react-redux';
import LineChartWidget from '../Dashboard/LineChartWidget';
import DonutChartWidget from '../Dashboard/DonutChartWidget';
import { reportsColorPalette } from '../../constants/app';
import BarChartWidget from '../Dashboard/BarChartWidget';
import { parseCustomReportData } from './view.helpers';
import Grid from '@material-ui/core/Grid';
import GeoChartWidget from '../Dashboard/GeoChartWidget';
import GraphTabs from '../Dashboard/GraphTabs';
import NumberDisplayTileWidget from '../Dashboard/NumberDisplayTileWidget';
import TableChart from './TableChart';
import { addFilter, selectFilterFor } from '../../redux/store/ui/filters';
import { getViewMode } from '../../components/Filter/helpers/getTargets';
import { QueryVariables } from '../../core/models';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import { selectSpinnerLoading } from '../../redux/store/ui/spinner';
import { useQuery } from '../../components/DateRangePicker';

const queryVariables = new QueryVariables();

const ViewCustomReports = ({ location, match, id }) => {
  const dateFormat = 'YYYY-M-D';
  const dispatch = useDispatch();
  const _id = urlParamFilter(match) || id;
  const classes = useStyles();
  const query = useQuery();
  const selectedReport = useSelector(selectSelectedCustomReport);
  const appliedFilters = useSelector(selectFilterFor(getViewMode(location)));
  const dateRangeValue = useSelector(selectDateRangeValue);
  const requestLoading = useSelector(selectSpinnerLoading);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [lineChart, setLineChart] = React.useState([]);
  const [pieChart, setPieChart] = React.useState([]);
  const [barChart, setBarChart] = React.useState([]);
  const [geoChart, setGeoChart] = React.useState([]);
  const [tableChart, setTableChart] = React.useState([]);
  const [numberWidgets, setNumberWidgets] = React.useState([]);
  const [allTimesRev, setAllTimesRev] = React.useState(null);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);

  const addLineChart = (toAddChart) => {
    setLineChart((prev) => prev.concat(toAddChart));
  };

  const addPieChart = (toAddChart) => {
    setPieChart((prev) => prev.concat(toAddChart));
  };

  const addBarChart = (toAddChart) => {
    setBarChart((prev) => prev.concat(toAddChart));
  };

  const addGeoChart = (toAddChart) => {
    setGeoChart((prev) => prev.concat(toAddChart));
  };

  const addTableChart = (toAddChart) => {
    setTableChart((prev) => prev.concat(toAddChart));
  };

  const clearCharts = () => {
    setLineChart([]);
    setPieChart([]);
    setBarChart([]);
    setGeoChart([]);
    setTableChart([]);
  };

  const [viewCustomReport] = useGraphLazyQuery(
    generateFetchCustomReportQuery(selectedReport?.metrics),
    (data) => {
      parseCustomReportData(
        data,
        selectedReport,
        addLineChart,
        addPieChart,
        addBarChart,
        addGeoChart,
        setNumberWidgets,
        addTableChart,
        setAllTimesRev,
      );
    },
  );

  const [loadMoreCustomReport] = useNoLoaderGraphQuery(
    generateFetchCustomReportQuery(selectedReport?.metrics),
    (data) => {
      parseCustomReportData(
        data,
        selectedReport,
        () => {},
        () => {},
        () => {},
        () => {},
        setNumberWidgets,
        addTableChart,
        setAllTimesRev,
      );
      setIsLoadingMore(false);
    },
  );

  const handleTabChange = (e, val) => {
    setSelectedTab(val);
  };

  const [getCustomReport] = useGraphLazyQuery(
    FETCH_CUSTOM_REPORT_BY_ID,
    (data) => {
      dispatch(setLastNode(data.customReport.reportTitle));
      dispatch(setSelectedCustomReport(data.customReport));
      data.customReport.filters.forEach((filter) => {
        delete filter.__typename;
        dispatch(
          addFilter({
            viewMode: 'VIEW_CUSTOM_REPORT',
            key: filter.fieldType,
            value: filter.fieldValue,
          }),
        );
      });
      clearCharts();
      if (appliedFilters?.version >= 0) {
        queryVariables.clearFilters();
        queryVariables.setOffset(0);
        queryVariables.setUiAppliedFilters(appliedFilters);
      }
      const reportFilter = {
        reportId: data.customReport?._id,
        filters: [
          ...data?.customReport?.filters,
          ...queryVariables?.filter?.filters,
        ],
        dateFilter: data.customReport?.dateFilter,
      };
      const table = data.customReport?.charts?.find(
        (chart) => chart.chartType === 'TableChart',
      );
      if (table) {
        reportFilter.pagination = [
          { chartTitle: table.chartTitle, limit: 50, offset: 0 },
        ];
      }
      if (
        query.get('from') &&
        dateRangeValue?.startDate &&
        dateRangeValue?.endDate
      ) {
        queryVariables.setStartDate(
          moment(dateRangeValue.startDate).format(dateFormat),
        );
        queryVariables.setEndDate(
          moment(dateRangeValue.endDate).format(dateFormat),
        );
        reportFilter.startDate = queryVariables.filter.startDate;
        reportFilter.endDate = queryVariables.filter.endDate;
      }
      viewCustomReport({
        variables: {
          reportFilter,
        },
      });
    },
  );

  const onLoadMoreTableData = (tableTitle, page) => {
    if (selectedReport?._id) {
      setIsLoadingMore(true);
      setTableChart([]);
      if (appliedFilters?.version >= 0) {
        queryVariables.clearFilters();
        queryVariables.setOffset(0);
        queryVariables.setUiAppliedFilters(appliedFilters);
      }
      const reportFilter = {
        reportId: selectedReport?._id,
        filters: queryVariables.filter.filters,
        dateFilter: selectedReport?.dateFilter,
        pagination: [{ chartTitle: tableTitle, limit: 50, offset: page * 10 }],
      };
      if (dateRangeValue?.startDate && dateRangeValue?.endDate) {
        queryVariables.setStartDate(
          moment(dateRangeValue.startDate).format(dateFormat),
        );
        queryVariables.setEndDate(
          moment(dateRangeValue.endDate).format(dateFormat),
        );
        reportFilter.startDate = queryVariables.filter.startDate;
        reportFilter.endDate = queryVariables.filter.endDate;
      }
      loadMoreCustomReport({
        variables: {
          reportFilter,
        },
      });
    }
  };

  useEffect(() => {
    if (_id) {
      queryVariables.clearFilters();
      getCustomReport({ variables: { id: _id } });
    }
    // dispatch(clearFilters());
    return () => {
      dispatch(setSelectedCustomReport(null));
      dispatch(setCustomReportTableChartPage(0));
    };
  }, []);

  useEffect(() => {
    if (selectedReport?._id) {
      clearCharts();
      if (appliedFilters?.version >= 0) {
        queryVariables.clearFilters();
        queryVariables.setOffset(0);
        queryVariables.setUiAppliedFilters(appliedFilters);
      }
      const reportFilter = {
        reportId: selectedReport?._id,
        filters: queryVariables.filter.filters,
        dateFilter: selectedReport?.dateFilter,
      };
      const table = selectedReport.charts?.find(
        (chart) => chart.chartType === 'TableChart',
      );
      if (table) {
        reportFilter.pagination = [
          { chartTitle: table.chartTitle, limit: 50, offset: 0 },
        ];
      }
      if (dateRangeValue?.startDate && dateRangeValue?.endDate) {
        queryVariables.setStartDate(
          moment(dateRangeValue.startDate).format(dateFormat),
        );
        queryVariables.setEndDate(
          moment(dateRangeValue.endDate).format(dateFormat),
        );
        reportFilter.startDate = queryVariables.filter.startDate;
        reportFilter.endDate = queryVariables.filter.endDate;
      }
      viewCustomReport({
        variables: {
          reportFilter,
        },
      });
    }
  }, [appliedFilters, dateRangeValue]);

  const onlyTable =
    tableChart.length > 0 &&
    !lineChart.length > 0 &&
    !pieChart.length > 0 &&
    !barChart.length > 0 &&
    !geoChart.length > 0;

  return (
    <>
      {(requestLoading ||
        (!lineChart.length > 0 &&
          !pieChart.length > 0 &&
          !barChart.length > 0 &&
          !geoChart.length > 0 &&
          !tableChart.length > 0)) && (
        <PaperContainer hideFilters>
          <Skeleton variant="rectangular" width="100%">
            <div style={{ width: '90%', height: '50px', margin: ' 16px' }} />
          </Skeleton>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Skeleton variant="rectangular" width="49%" height={200} />
            <Skeleton variant="rectangular" width="49%" height={200} />
          </div>
          <Skeleton variant="rectangular" width="100%">
            <div style={{ width: '90%', height: '400px', margin: ' 16px' }} />
          </Skeleton>
        </PaperContainer>
      )}

      {!onlyTable &&
        (lineChart.length > 0 ||
          pieChart.length > 0 ||
          barChart.length > 0 ||
          geoChart.length > 0 ||
          tableChart.length > 0) &&
        !requestLoading && (
          <PaperContainer>
            <div className={classes.container}>
              {allTimesRev && (
                <Grid container>
                  <Grid item xs={12}>
                    <span className={classes.allTimeRevenueTitle}>
                      All time revenue:{' '}
                    </span>
                    <span className={classes.allTimeRevenueValue}>
                      ${allTimesRev}
                    </span>
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                className={classes.numberDisplayContainer}
                spacing={2}
              >
                {numberWidgets.map((widget, index) => (
                  <Grid
                    key={`number-display-tile-widget-${widget.label}`}
                    item
                    xs
                  >
                    <NumberDisplayTileWidget
                      label={widget.label}
                      value={widget.value}
                      index={index}
                      focused={widget.label === 'revenue'}
                      type={widget.type}
                      forceMinimalPrecision={widget.metric === 'CTR'}
                    />
                  </Grid>
                ))}
              </Grid>
              {selectedReport?.metrics?.length > 0 &&
                (pieChart.length > 0 ||
                  barChart.length > 0 ||
                  geoChart.length > 0) && (
                  <Grid item xs={12}>
                    <GraphTabs
                      value={selectedTab}
                      tabs={selectedReport.metrics.map(
                        (metric) =>
                          metric.metricType.charAt(0).toUpperCase() +
                          metric.metricType.slice(1),
                      )}
                      handleChange={handleTabChange}
                      isShown
                    />
                  </Grid>
                )}
              <Grid container spacing={2}>
                {pieChart.map(
                  (pie) =>
                    pie?.data?.length > 0 && (
                      <Grid item xs={6}>
                        <DonutChartWidget
                          widgetName={pie.chartTitle}
                          data={pie.data}
                          colors={reportsColorPalette}
                          tabs={pie.tabs}
                          selectedTab={selectedTab}
                          order={
                            selectedReport?.charts?.find(
                              (chart) => chart.chartType === 'PieChart',
                            )?.metricOrder
                          }
                        />
                      </Grid>
                    ),
                )}
                {barChart.map(
                  (bar) =>
                    bar?.data?.length > 0 && (
                      <Grid item xs={6}>
                        <div style={{ height: '500px' }}>
                          <BarChartWidget
                            widgetName={bar.chartTitle}
                            chartData={bar.data}
                            colors={reportsColorPalette}
                            tabs={bar.tabs}
                            selectedTab={selectedTab}
                            order={
                              selectedReport?.charts?.find(
                                (chart) => chart.chartType === 'BarChart',
                              )?.metricOrder
                            }
                          />
                        </div>
                      </Grid>
                    ),
                )}
                {geoChart.map(
                  (geo) =>
                    geo?.data?.length > 0 && (
                      <Grid item xs={6}>
                        <GeoChartWidget
                          widgetName={geo.chartTitle}
                          data={geo?.data}
                          tabs={geo.tabs}
                          selectedTab={selectedTab}
                          colors={reportsColorPalette}
                          order={
                            selectedReport?.charts?.find(
                              (chart) => chart.chartType === 'GeoChart',
                            )?.metricOrder
                          }
                        />
                      </Grid>
                    ),
                )}
                {lineChart.map(
                  (line) =>
                    line?.data?.length > 0 && (
                      <Grid
                        item
                        xs={
                          selectedReport.charts?.filter(
                            (chart) => chart.chartType !== 'TableChart',
                          ).length %
                            2 ===
                          0
                            ? 6
                            : 12
                        }
                      >
                        <LineChartWidget
                          widgetName={line.chartTitle}
                          chartData={line.data}
                          colors={reportsColorPalette}
                          tabs={line.tabs}
                          tabMetrics={line.tabMetrics}
                        />
                      </Grid>
                    ),
                )}
              </Grid>
            </div>
          </PaperContainer>
        )}
      {isLoadingMore ? (
        <PaperContainer hideFilters>
          <Skeleton variant="rectangular" width="100%">
            <div style={{ width: '90%', height: '200px', margin: ' 16px' }} />
          </Skeleton>
        </PaperContainer>
      ) : (
        tableChart?.length > 0 &&
        !requestLoading && (
          <PaperContainer hideFilters={!onlyTable}>
            {onlyTable && (
              <>
                {allTimesRev && (
                  <Grid container>
                    <Grid item xs={12}>
                      <span className={classes.allTimeRevenueTitle}>
                        All time revenue:{' '}
                      </span>
                      <span className={classes.allTimeRevenueValue}>
                        ${allTimesRev}
                      </span>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  container
                  className={classes.numberDisplayContainer}
                  spacing={2}
                >
                  {numberWidgets.map((widget, index) => (
                    <Grid
                      key={`number-display-tile-widget-${widget.label}`}
                      item
                      xs
                    >
                      <NumberDisplayTileWidget
                        label={widget.label}
                        value={widget.value}
                        index={index}
                        focused={widget.label === 'revenue'}
                        type={widget.type}
                        forceMinimalPrecision={widget.metric === 'CTR'}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
            <div className={classes.container}>
              {tableChart.map(
                (table) =>
                  table?.data?.length > 0 && (
                    <Grid item xs={12}>
                      <TableChart
                        tableTitle={table.chartTitle}
                        tableData={table.data}
                        metrics={table.tabs}
                        count={table.count}
                        dimensions={table.dimensions}
                        onLoadMoreTableData={onLoadMoreTableData}
                      />
                    </Grid>
                  ),
              )}
            </div>
          </PaperContainer>
        )
      )}
    </>
  );
};

export default ViewCustomReports;
