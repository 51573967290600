import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setTarget } from '../../redux/store/ui/filters';
import getTargets from './helpers/getTargets';
import { useStyles } from './TargetSelector.styles';

const TargetSelector = ({ viewMode }) => {
  const classes = useStyles();
  const [query, setQuery] = useState(null);
  const dispatch = useDispatch();
  const targets = getTargets(viewMode);

  const filteredTargets =
    query === null
      ? targets
      : targets.filter((t) => {
          const label = String(t.value);
          const lowerCaseLabel = label.toLowerCase();
          const lowerCaseValue =
            typeof t.value === 'string' ? t.value.toLowerCase() : '';

          return (
            lowerCaseLabel.includes(query) || lowerCaseValue.includes(query)
          );
        });

  const onClickOption = (target, targetType) => (e) => {
    e.preventDefault();
    dispatch(setTarget({ target, targetType }));
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    if (typeof e.target.value === 'string') {
      const lowerCaseValue = e.target.value.toLowerCase();
      setQuery(lowerCaseValue);
    } else {
      setQuery(e.target.value);
    }
  };

  return (
    <>
      <div className={classes.targetSelector}>
        <div className={classes.targetOptions}>
          {filteredTargets &&
            filteredTargets?.map((t) => (
              <div
                key={`target-option-${t.value}`}
                className={classes.targetOption}
                onClick={onClickOption(t.value, t.type)}
              >
                {t?.label || t.value}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default TargetSelector;
