import { SHOW_SNAKBAR, HIDE_SNAKBAR } from '../../../../constants/actionTypes';

export const showSnakbar = (payload) => {
  return {
    type: SHOW_SNAKBAR,
    payload,
  };
};

export const hideSnakbar = () => {
  return {
    type: HIDE_SNAKBAR,
  };
};
