import { gql } from '@apollo/client';

const FETCH_PROVIDERS = gql`
  query fetchProdivers($filter: QueryFilter!) {
    providers(queryFilter: $filter) {
      items {
        _id
        providerStatus
        updatedAt
        providerName
        providerLogo
        isEnabled
        paymentType
        lastSync
        dealInfo {
          dealType
          value
        }
      }
      page
      totalCount
    }
  }
`;

const FETCH_PROVIDER_BY_ID = gql`
  query fetchProdiver($id: String!) {
    provider(id: $id) {
      _id
      providerName
      providerLogo
      paymentType
      customStyle
      isEnabled
      dealInfo {
        dealType
        value
        percentage
      }
      parameters {
        parameterName
        parameterLabel
        parameterType
      }
      supportsManualReports
      supportsAPIFetch
      loadType
      timezone
      insertionPolicy
      isVisible
      providerStatus
      statusHistory {
        status
        createdAt
      }
      adFormats {
        adFormatName
        regex
        parameters {
          parameterName
          parameterLabel
          parameterType
          parameterValue
        }
      }
    }
  }
`;

export { FETCH_PROVIDERS, FETCH_PROVIDER_BY_ID };
