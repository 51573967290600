export const drawerWidth = 250;

export const reportsColorPalette = [
  '#2BACE4',
  '#BE2EDD',
  '#F0932B',
  '#4650CE',
  '#2CB374',
  '#A0A5BA',
];
