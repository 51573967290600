import {
  SET_PROVIDERS_ITEMS,
  APPEND_PROVIDERS_ITEMS,
  SET_PROVIDERS_PAGE,
  SET_PROVIDERS_TOTAL_COUNT,
  CLEAR_PROVIDERS,
} from '../../../../constants/actionTypes';
import { initialState } from './InitialState';

export const providersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROVIDERS_ITEMS:
      return { ...state, items: action.payload };
    case APPEND_PROVIDERS_ITEMS:
      return { ...state, items: [...state.items, ...action.payload] };
    case SET_PROVIDERS_TOTAL_COUNT:
      return { ...state, totalCount: action.payload };
    case SET_PROVIDERS_PAGE:
      return { ...state, page: action.payload };
    case CLEAR_PROVIDERS:
      return { ...initialState };
    default:
      return state;
  }
};
