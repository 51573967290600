export const DealTypes = [
  { dealTypeName: 'RevShare', _id: 1 },
  { dealTypeName: 'Sale', _id: 2 },
  { dealTypeName: 'Demo', _id: 3 },
  { dealTypeName: 'Hybrid', _id: 4 },
  { dealTypeName: 'Discount', _id: 5 },
];

export const LicenseFeesDealTypes = [
  { dealTypeName: 'RevShare', _id: 1 },
  { dealTypeName: 'Sale', _id: 2 },
  { dealTypeName: 'Demo', _id: 3 },
  { dealTypeName: 'Hybrid', _id: 4 },
];

export const WebsiteDealTypes = [
  { dealTypeName: 'Sale', _id: 2 },
  { dealTypeName: 'Demo', _id: 3 },
];
export const Palettes = [
  { _id: 1, paletteName: 'Primary', palette: '#4791db' },
  { _id: 2, paletteName: 'Secondary', palette: '#e33371' },
];
export const WidgetTypes = [
  { _id: 1, widgetTypeName: 'Sticky' },
  { _id: 2, widgetTypeName: 'Content' },
  { _id: 3, widgetTypeName: 'Sidebar' },
  { _id: 4, widgetTypeName: 'Infeed' },
  { _id: 5, widgetTypeName: 'Video' },
  { _id: 6, widgetTypeName: 'Popup' },
  { _id: 7, widgetTypeName: 'Display' },
  { _id: 8, widgetTypeName: 'PushNotification' },
  { _id: 9, widgetTypeName: 'InternalFeed' },
];
export const Devices = [
  { deviceName: 'Tablet', _id: 0 },
  { deviceName: 'Mobile', _id: 1 },
  { deviceName: 'Desktop', _id: 2 },
];
export const Statuses = [
  { statusName: 'Pending', _id: 1 },
  { statusName: 'Approved', _id: 2 },
];
export const ParameterTypes = [
  { parameterType: 'text', _id: 1 },
  { parameterType: 'number', _id: 2 },
  { parameterType: 'textarea', _id: 3 },
];
export const DeliveryGroupTypes = [
  { type: 'SINGLE', _id: 0 },
  { type: 'MULTIPLE', _id: 1 },
];
export const PaymentTypes = [
  { type: 'Net', _id: 0 },
  { type: 'Gross', _id: 1 },
];
export const InsertionPolicies = [
  { label: 'Insert', value: 'INSERT' },
  { label: 'Upsert', value: 'UPSERT' },
];

export const LoadTypes = [
  { label: 'Once', value: 'ONCE' },
  { label: 'Multiple', value: 'MULTIPLE' },
];

export const currency = [
  { label: 'USD', value: 'USD' },
  // { label: 'EURO', value: 'EURO' },
];

export const cryptoCurrency = [{ label: 'USDT', value: 'USDT' }];

export const timezones = [
  { value: 'Pacific/Midway', label: 'Midway Island, Samoa' },
  { value: 'Pacific/Honolulu', label: 'Hawaii' },
  { value: 'America/Juneau', label: 'Alaska' },
  { value: 'America/Boise', label: 'Mountain Time' },
  { value: 'America/Chihuahua', label: 'Chihuahua, La Paz, Mazatlan' },
  { value: 'America/Phoenix', label: 'Arizona' },
  { value: 'America/Chicago', label: 'Central Time' },
  { value: 'America/Regina', label: 'Saskatchewan' },
  {
    value: 'America/Mexico_City',
    label: 'Guadalajara, Mexico City, Monterrey',
  },
  { value: 'America/Belize', label: 'Central America' },
  { value: 'America/Detroit', label: 'Eastern Time' },
  { value: 'America/Bogota', label: 'Bogota, Lima, Quito' },
  { value: 'America/Caracas', label: 'Caracas, La Paz' },
  { value: 'America/Santiago', label: 'Santiago' },
  { value: 'America/St_Johns', label: 'Newfoundland and Labrador' },
  { value: 'America/Sao_Paulo', label: 'Brasilia' },
  { value: 'America/Tijuana', label: 'Tijuana, Pacific Time' },
  {
    value: 'America/Argentina/Buenos_Aires',
    label: 'Buenos Aires, Georgetown',
  },
  { value: 'America/Godthab', label: 'Greenland' },
  { value: 'Atlantic/Azores', label: 'Azores' },
  { value: 'Atlantic/Cape_Verde', label: 'Cape Verde Islands' },
  { value: 'GMT', label: 'Dublin, Edinburgh, Lisbon, London' },
  { value: 'Africa/Casablanca', label: 'Casablanca, Monrovia' },
  { value: 'Atlantic/Canary', label: 'Canary Islands' },
  {
    value: 'Europe/Belgrade',
    label: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  },
  { value: 'Europe/Sarajevo', label: 'Sarajevo, Skopje, Warsaw, Zagreb' },
  { value: 'Europe/Brussels', label: 'Brussels, Copenhagen, Madrid, Paris' },
  {
    value: 'Europe/Amsterdam',
    label: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  },
  { value: 'Africa/Algiers', label: 'West Central Africa' },
  { value: 'Europe/Bucharest', label: 'Bucharest' },
  { value: 'Africa/Cairo', label: 'Cairo' },
  {
    value: 'Europe/Helsinki',
    label: 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  },
  { value: 'Europe/Athens', label: 'Athens, Istanbul, Minsk' },
  { value: 'Asia/Jerusalem', label: 'Jerusalem' },
  { value: 'Africa/Harare', label: 'Harare, Pretoria' },
  { value: 'Europe/Moscow', label: 'Moscow, St. Petersburg, Volgograd' },
  { value: 'Asia/Kuwait', label: 'Kuwait, Riyadh' },
  { value: 'Africa/Nairobi', label: 'Nairobi' },
  { value: 'Asia/Baghdad', label: 'Baghdad' },
  { value: 'Asia/Tehran', label: 'Tehran' },
  { value: 'Asia/Dubai', label: 'Abu Dhabi, Muscat' },
  { value: 'Asia/Baku', label: 'Baku, Tbilisi, Yerevan' },
  { value: 'Asia/Kabul', label: 'Kabul' },
  { value: 'Asia/Yekaterinburg', label: 'Ekaterinburg' },
  { value: 'Asia/Karachi', label: 'Islamabad, Karachi, Tashkent' },
  { value: 'Asia/Kolkata', label: 'Chennai, Kolkata, Mumbai, New Delhi' },
  { value: 'Asia/Kathmandu', label: 'Kathmandu' },
  { value: 'Asia/Dhaka', label: 'Astana, Dhaka' },
  { value: 'Asia/Colombo', label: 'Sri Jayawardenepura' },
  { value: 'Asia/Almaty', label: 'Almaty, Novosibirsk' },
  { value: 'Asia/Rangoon', label: 'Yangon Rangoon' },
  { value: 'Asia/Bangkok', label: 'Bangkok, Hanoi, Jakarta' },
  { value: 'Asia/Krasnoyarsk', label: 'Krasnoyarsk' },
  {
    value: 'Asia/Shanghai',
    label: 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  },
  { value: 'Asia/Kuala_Lumpur', label: 'Kuala Lumpur, Singapore' },
  { value: 'Asia/Taipei', label: 'Taipei' },
  { value: 'Australia/Perth', label: 'Perth' },
  { value: 'Asia/Irkutsk', label: 'Irkutsk, Ulaanbaatar' },
  { value: 'Asia/Seoul', label: 'Seoul' },
  { value: 'Asia/Tokyo', label: 'Osaka, Sapporo, Tokyo' },
  { value: 'Asia/Yakutsk', label: 'Yakutsk' },
  { value: 'Australia/Darwin', label: 'Darwin' },
  { value: 'Australia/Adelaide', label: 'Adelaide' },
  { value: 'Australia/Sydney', label: 'Canberra, Melbourne, Sydney' },
  { value: 'Australia/Brisbane', label: 'Brisbane' },
  { value: 'Australia/Hobart', label: 'Hobart' },
  { value: 'Asia/Vladivostok', label: 'Vladivostok' },
  { value: 'Pacific/Guam', label: 'Guam, Port Moresby' },
  { value: 'Asia/Magadan', label: 'Magadan, Solomon Islands, New Caledonia' },
  { value: 'Pacific/Fiji', label: 'Fiji Islands, Kamchatka, Marshall Islands' },
  { value: 'Pacific/Auckland', label: 'Auckland, Wellington' },
  { value: 'Pacific/Tongatapu', label: "Nuku'alofa" },
];

export const PaymentTerms = [
  { label: '_0', value: '0' },
  { label: '_15', value: '15' },
  { label: '_30', value: '30' },
  { label: '_45', value: '45' },
  { label: '_60', value: '60' },
];
