import { gql } from '@apollo/client';

const GET_ROLES = gql`
  query fetchRoles {
    roles {
      _id
      roleName
    }
  }
`;

export { GET_ROLES };
