import BaseClient from './baseClient';
import {
  successInterceptor,
  errorInterceptor,
  tokenInterceptor,
} from './interceptors';

let instance = null;

class ApiClient {
  constructor(
    config = {
      baseURL: process.env.REACT_APP_API_URL || 'https://app.ketshwaapp.com/',
    },
  ) {
    if (!instance) {
      instance = new BaseClient(config).client;
      instance
        .useRequestInterceptor(tokenInterceptor)
        .useResponseInterceptor(successInterceptor, errorInterceptor)
        .build();
    }

    return instance;
  }
}

export default new ApiClient()._client;
