export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const descendingComparator = (a, b, orderBy) => {
  if (!orderBy.includes('.')) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  } else {
    const keys = orderBy.split('.');
    const key1 = keys[0];
    const key2 = keys[1];
    if (b[key1][key2] < a[key1][key2]) {
      return -1;
    }
    if (b[key1][key2] > a[key1][key2]) {
      return 1;
    }
  }
  return 0;
};

export const stableSort = (array, orderBy, order) => {
  const collator = new Intl.Collator('en', {
    numeric: true,
    sensitivity: 'base',
  });

  const sorted = array.sort((a, b) => {
    if (order === 'desc') {
      return collator.compare(b[orderBy], a[orderBy]);
    }
    return collator.compare(a[orderBy], b[orderBy]);
  });

  return sorted;
};
