import {
  SET_WEBSITES_ITEMS,
  SET_WEBSITES_TOTAL_COUNT,
  SET_WEBSITES_PAGE,
  APPEND_WEBSITES_ITEMS,
  CLEAR_WEBSITES,
} from '../../../../constants/actionTypes';

export const setWebsites = (data) => async (dispatch) => {
  dispatch({
    type: SET_WEBSITES_ITEMS,
    payload: data?.websites?.items,
  });
  dispatch({
    type: SET_WEBSITES_TOTAL_COUNT,
    payload: data?.websites?.totalCount,
  });
};

export const appendWebsites = (data) => async (dispatch) => {
  dispatch({
    type: APPEND_WEBSITES_ITEMS,
    payload: data?.websites?.items,
  });
  dispatch({
    type: SET_WEBSITES_TOTAL_COUNT,
    payload: data?.websites?.totalCount,
  });
};

export const setWebsitesPage = (page) => ({
  type: SET_WEBSITES_PAGE,
  payload: page,
});

export const clearWebsites = () => ({
  type: CLEAR_WEBSITES,
});
