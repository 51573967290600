import React, { Suspense } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import PrivateRoute from './PrivateRoute';
import { history } from '../utils/history';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MainLayout from '../layout/MainLayout';
/**
 * Pages Components Eager/Lazy Load
 */
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ForgotPassword/ResetPassword';
import ViewCustomReports from '../pages/CustomReports/ViewCustomReport';
import Deductions from '../pages/Deductions';

const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const Websites = React.lazy(() => import('../pages/Websites'));
const Users = React.lazy(() => import('../pages/Users'));
const AddDeductions = React.lazy(() => import('../pages/Deductions/Add'));
const Publishers = React.lazy(() => import('../pages/Publishers'));
const AddPublisher = React.lazy(() => import('../pages/Publishers/Add'));
const AddUser = React.lazy(() => import('../pages/Users/Add'));
const Widgets = React.lazy(() => import('../pages/Widgets'));
const Brands = React.lazy(() => import('../pages/Brands'));
const Reports = React.lazy(() => import('../pages/Reports'));
const Profile = React.lazy(() => import('../pages/Profile'));
const AddWidget = React.lazy(() => import('../pages/Widgets/Add'));
const AdGroups = React.lazy(() => import('../pages/AdGroups/index'));
const WidgetList = React.lazy(() => import('../pages/Widgets/List'));
const AddWebsite = React.lazy(() => import('../pages/Websites/Add'));
const WebsiteList = React.lazy(() => import('../pages/Websites/List'));
const AddBrand = React.lazy(() => import('../pages/Brands/Add'));
const Providers = React.lazy(() => import('../pages/Providers'));
const AddProvider = React.lazy(() => import('../pages/Providers/Add'));
const AddAdGroup = React.lazy(() => import('../pages/AdGroups/Add'));
const EditAdGroup = React.lazy(() => import('../pages/AdGroups/Edit'));
const ExternalReports = React.lazy(() => import('../pages/ExternalReports'));

const AdFormats = React.lazy(() => import('../pages/AdFormats'));
const AddAdFormats = React.lazy(() => import('../pages/AdFormats/Add'));

const AddExternalReports = React.lazy(() =>
  import('../pages/ExternalReports/Add'),
);
const ByProviderReport = React.lazy(() =>
  import('../pages/Reports/byProviderReports'),
);
const ByCountryReport = React.lazy(() =>
  import('../pages/Reports/byCountryReports'),
);
const ByWidgetReport = React.lazy(() =>
  import('../pages/Reports/byWidgetReports'),
);
const ByWebsiteReport = React.lazy(() =>
  import('../pages/Reports/byWebsitesReports'),
);

// const ByAdProviderReport = React.lazy(() =>
//   import('../pages/Reports/byAdProviderReports'),
// );

const ViewBrand = React.lazy(() => import('../pages/Brands/View'));
const ViewPublisher = React.lazy(() => import('../pages/Publishers/View'));
const ViewWebsite = React.lazy(() => import('../pages/Websites/View'));
const ViewWidget = React.lazy(() => import('../pages/Widgets/View'));
const ViewProvider = React.lazy(() => import('../pages/Providers/View'));
const ViewAdProvider = React.lazy(() => import('../pages/AdProviders/View'));
const ViewAdGroup = React.lazy(() => import('../pages/AdGroups/View'));
const ViewUser = React.lazy(() => import('../pages/Users/View'));

const CreateCustomReports = React.lazy(() =>
  import('../pages/CustomReports/CreateCustomReport'),
);

const CustomReports = React.lazy(() =>
  import('../pages/CustomReports/CustomReports'),
);

const GlobalSettings = React.lazy(() => import('../pages/GlobalSettings'));
const Setup = React.lazy(() => import('../pages/Setup'));
const AdProviders = React.lazy(() => import('../pages/AdProviders'));
const AddAdProvider = React.lazy(() => import('../pages/AdProviders/Add'));
const Earnings = React.lazy(() => import('../pages/Eearnings'));
const Unauthorized = React.lazy(() => import('../pages/Unauthorized'));
const getBasename = () => `/${process.env.PUBLIC_URL.split('/').pop()}`;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const AppRoute = () => {
  const classes = useStyles();

  return (
    <>
      {/* <BrowserRouter basename={getBasename()}> */}
      <Router history={history}>
        <LastLocationProvider>
          <Suspense
            fallback={
              <Backdrop className={classes.backdrop} open={true}>
                <CircularProgress color="inherit" />
              </Backdrop>
            }
          >
            <Switch>
              <Route path="/login" exact component={Login} />
              <Route exact path="/forgot" component={ForgotPassword} />
              <Route
                exact
                path={['/reset-password', '/set-password']}
                component={ResetPassword}
              />

              <MainLayout>
                <PrivateRoute exact path="/dashboard" component={Dashboard} />

                <PrivateRoute exact path="/users" component={Users} />
                <PrivateRoute exact path="/users/add" component={AddUser} />
                <PrivateRoute
                  exact
                  path="/users/edit/:id"
                  component={AddUser}
                />
                <PrivateRoute
                  exact
                  path="/users/view/:id"
                  component={ViewUser}
                />
                <PrivateRoute exact path="/profile" component={Profile} />
                <PrivateRoute exact path="/profile/edit" component={Profile} />

                <PrivateRoute exact path="/report" component={Reports} />

                <PrivateRoute exact path="/brands" component={Brands} />
                <PrivateRoute exact path="/brands/add" component={AddBrand} />
                <PrivateRoute
                  exact
                  path="/brands/edit/:id"
                  component={AddBrand}
                />
                <PrivateRoute
                  exact
                  path="/brands/view/:id"
                  component={ViewBrand}
                />

                <PrivateRoute exact path="/deductions" component={Deductions} />
                <PrivateRoute
                  exact
                  path="/deductions/add"
                  component={AddDeductions}
                />

                <PrivateRoute exact path="/publishers" component={Publishers} />
                <PrivateRoute
                  exact
                  path="/publishers/add"
                  component={AddPublisher}
                />
                <PrivateRoute
                  exact
                  path="/publishers/edit/:id"
                  component={AddPublisher}
                />
                <PrivateRoute
                  exact
                  path="/publishers/view/:id"
                  component={ViewPublisher}
                />

                <PrivateRoute exact path="/websites" component={Websites} />
                <PrivateRoute
                  exact
                  path="/websites/add"
                  component={AddWebsite}
                />
                <PrivateRoute
                  exact
                  path="/websites/edit/:id"
                  component={AddWebsite}
                />
                <PrivateRoute
                  exact
                  path="/websites/view/:id"
                  component={ViewWebsite}
                />
                <PrivateRoute
                  exact
                  path="/websites/list"
                  component={WebsiteList}
                />

                <PrivateRoute exact path="/widgets" component={Widgets} />
                <PrivateRoute
                  exact
                  path="/widgets:websiteId"
                  component={Widgets}
                />
                <PrivateRoute exact path="/widgets/add" component={AddWidget} />
                <PrivateRoute
                  exact
                  path="/widgets/edit/:id"
                  component={AddWidget}
                />
                <PrivateRoute
                  exact
                  path="/widgets/view/:id"
                  component={ViewWidget}
                />
                <PrivateRoute exact path="/ad-groups" component={AdGroups} />
                <PrivateRoute
                  exact
                  path="/ad-groups/add"
                  component={AddAdGroup}
                />
                <PrivateRoute
                  exact
                  path="/ad-groups/edit/:id"
                  component={EditAdGroup}
                />
                <PrivateRoute
                  exact
                  path="/ad-groups/view/:id"
                  component={ViewAdGroup}
                />
                <PrivateRoute
                  exact
                  path="/widgets/list"
                  component={WidgetList}
                />

                <PrivateRoute
                  exact
                  path="/ad-providers"
                  component={AdProviders}
                />
                <PrivateRoute
                  exact
                  path="/ad-providers/add"
                  component={AddAdProvider}
                />
                <PrivateRoute
                  exact
                  path="/ad-providers/edit/:id"
                  component={AddAdProvider}
                />
                <PrivateRoute
                  exact
                  path="/ad-providers/view/:id"
                  component={ViewAdProvider}
                />

                <PrivateRoute exact path="/providers" component={Providers} />
                <PrivateRoute
                  exact
                  path="/providers/add"
                  component={AddProvider}
                />
                <PrivateRoute
                  exact
                  path="/providers/edit/:id"
                  component={AddProvider}
                />
                <PrivateRoute
                  exact
                  path="/providers/view/:id"
                  component={ViewProvider}
                />

                <PrivateRoute exact path="/ad-formats/" component={AdFormats} />

                <PrivateRoute
                  exact
                  path="/ad-formats/add"
                  component={AddAdFormats}
                />

                <PrivateRoute
                  exact
                  path="/ad-formats/edit"
                  component={AddAdFormats}
                />

                <PrivateRoute
                  exact
                  path="/external-reports"
                  component={ExternalReports}
                />
                <PrivateRoute
                  exact
                  path="/external-reports/add"
                  component={AddExternalReports}
                />
                <PrivateRoute exact path="/earnings" component={Earnings} />
                <PrivateRoute
                  exact
                  path="/settings"
                  component={GlobalSettings}
                />
                <PrivateRoute exact path="/setup" component={Setup} />
                <PrivateRoute
                  exact
                  path="/reports-by-provider"
                  component={ByProviderReport}
                />
                <PrivateRoute
                  exact
                  path="/reports-by-country"
                  component={ByCountryReport}
                />
                <PrivateRoute
                  exact
                  path="/reports-by-widget"
                  component={ByWidgetReport}
                />
                <PrivateRoute
                  exact
                  path="/reports-by-website"
                  component={ByWebsiteReport}
                />
                {/*<PrivateRoute*/}
                {/*  exact*/}
                {/*  path="/reports-by-ad-provider"*/}
                {/*  component={ByAdProviderReport}*/}
                {/*/>*/}
                <PrivateRoute
                  path="/unauthorized"
                  exact
                  component={Unauthorized}
                />
                <PrivateRoute
                  path="/custom-reports/add"
                  exact
                  component={CreateCustomReports}
                />
                <PrivateRoute
                  path="/custom-reports/edit/:id"
                  exact
                  component={CreateCustomReports}
                />
                <PrivateRoute
                  path="/custom-reports/view/:id"
                  exact
                  component={ViewCustomReports}
                />
                <PrivateRoute
                  path="/custom-reports"
                  exact
                  component={CustomReports}
                />
              </MainLayout>
            </Switch>
          </Suspense>
        </LastLocationProvider>
      </Router>
      {/* </BrowserRouter> */}
    </>
  );
};

export default AppRoute;
