import {
  SHOW_REQUEST_SPINNER,
  HIDE_REQUEST_SPINNER,
} from '../../../../constants/actionTypes';

export const showRequestSpinner = () => {
  return {
    type: SHOW_REQUEST_SPINNER,
  };
};

export const hideRequestSpinner = () => {
  return {
    type: HIDE_REQUEST_SPINNER,
  };
};
