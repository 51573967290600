import {
  ADD_FILTER,
  CLOSE_FILTER_PANEL,
  OPEN_FILTER_PANEL,
  REMOVE_FILTER,
  SET_TARGET,
  CLEAR_FILTERS,
} from '../../../../constants/actionTypes';
import { compose } from 'redux';
import { filterBy } from '../../../../components/Filter/helpers/filterBy';
import {
  removeQuery,
  setQueries,
} from '../../../../components/Filter/helpers/queries';

export const closeFilterPanel = () => {
  return {
    type: CLOSE_FILTER_PANEL,
  };
};

export const openFilterPanel = () => {
  return {
    type: OPEN_FILTER_PANEL,
  };
};

export const setTarget = (payload) => {
  return {
    type: SET_TARGET,
    payload,
  };
};

export const addFilter = (payload) => {
  return {
    type: ADD_FILTER,
    payload,
  };
};

export const removeFilter = (payload) => {
  return {
    type: REMOVE_FILTER,
    payload,
  };
};

export const getFilteredEntities = (entities, filters) => {
  const funnel = Object.entries(filters).map(([_key, _value]) => {
    return filterBy(_key, _value);
  });
  return compose(...funnel)(entities);
};

export const addAndApplyFilter = ({ location, viewMode, value, key }) => (
  dispatch,
) => {
  dispatch(addFilter({ viewMode, key, value }));
  setQueries(location, key, value);
};

export const removeAndApplyFilter = ({ location, viewMode, key, value }) => (
  dispatch,
) => {
  dispatch(removeFilter({ viewMode, key, value }));
  removeQuery(location, key);
};

export const clearFilters = () => {
  return {
    type: CLEAR_FILTERS,
  };
};
