import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
  ChevronLeft,
  ChevronRight,
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';
import { history } from '../../utils';
import { NavLink } from 'react-router-dom';
import { drawerWidth } from '../../constants/app';
import { selectIsSidenavOpen, toggle } from '../../redux/store/ui/sidenav';
import { isPermittedToAccess } from '../../utils/permissions';
import Collapse from '@material-ui/core/Collapse';
import { selectCurrentUser } from '../../redux/store/auth';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useStyles } from './SideNav.styles';
import { topnavItems, bottomnavItems } from './SideNav.helpers';

const ExtendedListItem = ({ index, listItem, pathname, ...rest }) => {
  const currentUser = useSelector(selectCurrentUser);
  const [open, setOpen] = useState(false);
  const drawerOpen = useSelector(selectIsSidenavOpen);
  const classes = useStyles();
  const handleClick = (e, subItemsNumber) => {
    if (subItemsNumber) {
      e.preventDefault();
      setOpen(!open);
    }
  };

  const handleSubItemClick = (to) => {
    history.push(to);
  };

  useEffect(() => {
    if (
      isPermittedToAccess(pathname, currentUser) &&
      listItem?.subElements?.find((item) => item.to === pathname)
    ) {
      setOpen(true);
    }
  }, []);

  return (
    <>
      <Tooltip
        title={<div style={{ fontSize: '15px' }}>{listItem.name}</div>}
        arrow
        placement="right-start"
      >
        <ListItem
          button
          onClick={(e) => handleClick(e, listItem?.subElements?.length)}
          className={index === 0 ? classes.dashboardButton : classes.button}
          component={NavLink}
          to={listItem?.subElements?.length > 0 ? '' : listItem.to}
          {...rest}
        >
          <ListItemIcon className={classes.icon}>{listItem.icon}</ListItemIcon>
          <ListItemText
            className={
              listItem.to === pathname ? classes.activeItem : classes.item
            }
            primary={listItem.name}
          />
          {listItem?.subElements?.length > 0 && (
            <>{open ? <ExpandLess /> : <ExpandMore />}</>
          )}
        </ListItem>
      </Tooltip>
      {listItem?.subElements?.length > 0 && (
        <Collapse
          in={open}
          timeout="auto"
          TransitionProps={{ unmountOnExit: true }}
        >
          <List component="div" disablePadding>
            {listItem?.subElements.map((subitem, index) =>
              isPermittedToAccess(
                subitem.to,
                currentUser,
                subitem.authorizationType,
              ) ? (
                <Tooltip
                  title={<div style={{ fontSize: '15px' }}>{subitem.name}</div>}
                  arrow
                  placement="right-start"
                >
                  <ListItem
                    key={`${subitem.to}-${index}`}
                    className={
                      subitem.to === pathname
                        ? classes.activeSubItem
                        : classes.subItem
                    }
                    className={clsx(
                      subitem.to === pathname
                        ? classes.activeSubItem
                        : classes.subItem,
                      {
                        [classes.drawerOpen]: drawerOpen,
                        [classes.listClose]: !drawerOpen,
                      },
                    )}
                    button
                    onClick={() => handleSubItemClick(subitem.to)}
                  >
                    <ListItemIcon />
                    {subitem.icon}
                    <span style={{ marginLeft: '16px' }}>{subitem.name}</span>
                  </ListItem>
                </Tooltip>
              ) : null,
            )}
          </List>
        </Collapse>
      )}
    </>
  );
};

const SideNav = ({ location }) => {
  const classes = useStyles();
  const theme = useTheme();
  const open = useSelector(selectIsSidenavOpen);
  const deviceIsSmall = useMediaQuery((theme) => theme?.breakpoints?.up('sm'));
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);
  const allowedTopNavItems = topnavItems.filter((item) =>
    isPermittedToAccess(item.to, currentUser),
  );

  const handleDrawerToggle = (value) => {
    dispatch(toggle(value));
  };

  useEffect(() => {
    if (deviceIsSmall) dispatch(toggle(true));
    else dispatch(toggle(false));
  }, [deviceIsSmall]);

  return (
    <>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
        >
          <List>
            <div className={classes.toolbar} color="black">
              <IconButton
                onClick={() => {
                  open ? handleDrawerToggle(false) : handleDrawerToggle(true);
                }}
                style={{ marginTop: '90px' }}
              >
                {open ? <ChevronLeft /> : <ChevronRight />}
              </IconButton>
            </div>
            <Divider />
            {allowedTopNavItems.map((item, index) => (
              <ExtendedListItem
                id={`SideNav-${item.name}`}
                index={index}
                key={`top-${index}`}
                listItem={item}
                pathname={location?.pathname}
              />
            ))}
          </List>
          <div className={classes.gap}></div>
          <List>
            {bottomnavItems.map((item, index) => (
              <ListItem
                button
                key={`bottom-${index}`}
                component={NavLink}
                to={item.to}
                className={classes.navitem}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText className={classes.navitem} primary={item.name} />
              </ListItem>
            ))}
          </List>
          <p
            className={classes.version}
          >{`v${process.env.REACT_APP_VERSION}`}</p>
        </Drawer>
      </Box>
    </>
  );
};

export default SideNav;
