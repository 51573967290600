export const calculateMetric = (metrics, chosenMetric) => {
  const clicks = parseFloat(metrics['clicks']);
  const revenue = parseFloat(metrics['revenue']);
  const impressions = parseFloat(metrics['impressions']);
  switch (chosenMetric) {
    case 'CTR':
    case 'ctr':
      return impressions ? (clicks / impressions) * 100 : 0;
      break;
    case 'eCPM':
    case 'ecpm':
      return impressions ? 1000 * (revenue / impressions) : 0;
      break;
    case 'eCPC':
    case 'ecpc':
      return clicks ? revenue / clicks : 0;
      break;
    default:
      return metrics[chosenMetric];
  }
};
