import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Table } from '@material-ui/core';

const StyledTbl = withStyles((theme) => ({
  root: {
    tableLayout: 'fixed',
  },
}))(Table);

export const StyledTable = ({ children }) => {
  return <StyledTbl>{children}</StyledTbl>;
};
