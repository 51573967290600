import {
  SET_BRANDS_ITEMS,
  APPEND_BRANDS_ITEMS,
  SET_BRANDS_PAGE,
  SET_BRANDS_TOTAL_COUNT,
  CLEAR_BRANDS,
} from '../../../../constants/actionTypes';
import { initialState } from './InitialState';

export const brandsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BRANDS_ITEMS:
      return { ...state, items: action.payload };
    case APPEND_BRANDS_ITEMS:
      return { ...state, items: [...state.items, ...action.payload] };
    case SET_BRANDS_TOTAL_COUNT:
      return { ...state, totalCount: action.payload };
    case SET_BRANDS_PAGE:
      return { ...state, page: action.payload };
    case CLEAR_BRANDS:
      return { ...initialState };
    default:
      return state;
  }
};
