import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  selection: {},
  checkboxes: {
    flexDirection: 'column',
    display: 'flex',
    padding: '8px',
    paddingBottom: 0,
  },
  formControl: {
    height: '30px',
  },
}));
