import axios from 'axios';

class HttpClient {
  constructor(config = axios.defaults) {
    this._client = axios.create(config);
    return this;
  }

  usetransformRequest(transformer) {
    transformer();
    return this;
  }

  ejectInterceptor(interceptor) {
    this._client.interceptors.request.eject(interceptor);
    return this;
  }

  useRequestInterceptor(interceptor) {
    this._client.interceptors.request.use(
      (config) => {
        if (config.shouldIgnoreRequestInterceptor) {
          delete config.shouldIgnoreRequestInterceptor;
        } else {
          interceptor(config);
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    return this;
  }

  useResponseInterceptor(successInercept, errorInercept) {
    this._client.interceptors.response.use(
      (response) => {
        successInercept(response);
        return response;
      },
      (error) => {
        errorInercept(error);
        return Promise.reject({ ...error });
      },
    );

    return this;
  }

  configure(config = {}) {
    this._client.defaults = Object.assign({}, axios.defaults, config);
  }

  build() {
    return this._client;
  }
}

export default HttpClient;
