import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import { DonutLarge } from '@material-ui/icons';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { FlashOn, Language, Settings } from '@material-ui/icons';
import { history, queryParamFilter } from '../../utils';

export const parsePath = ({ pathname, search }) => {
  let { params } = queryParamFilter({ pathname, search });
  const pathnames = pathname.split('/');
  if (params) {
    params = {
      key: Object.keys(params)[0],
      value: params[Object.keys(params)[0]],
    };
  }
  return {
    pathnames: pathnames.filter(
      (item) =>
        item !== '' && !item.match(/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/),
    ),
    params,
  };
};

export const extractIdFromViewOrEdit = (location) => {
  const { pathname } = location;
  if (pathname.includes('/view/')) {
    return pathname.split('view/')[1];
  }

  if (pathname.includes('/edit/')) {
    return pathname.split('edit/')[1];
  }

  return null;
};

export const getBreadcrumbMap = (location, currentUser) => ({
  '/': 'Dashboard',
  '/dashboard': {
    title: 'Dashboard',
    icon: <DashboardIcon />,
    enableDatePicker: true,
    enableFilter: true,
  },
  '/settings': {
    title: 'Global Settings',
    icon: <Language />,
    onClickAdd: () => history.push('/settings'),
  },
  '/setup': {
    title: 'Setup New Account',
    buttonTitle: 'New User',
    icon: <FlashOn />,
    onClickAdd: () => {
      let setupPath = '/users/add?parent=setup';
      if (location.search.includes('topSelector'))
        setupPath = setupPath + location.search.replace('?', '&');
      return history.push(setupPath);
    },
    enableFilter: true,
  },
  '/users': {
    title: 'Users',
    buttonTitle: 'New User',
    onClickAdd: () => history.push('/users/add'),
    parent: {
      title: 'Administration',
      icon: <PersonIcon />,
    },
    directParents: [
      {
        title: 'Brands',
        from: '/brands',
      },
      {
        title: 'Publishers',
        from: '/publishers',
      },
    ],
    enableSearch: true,
    enableFilter: true,
  },
  '/profile': {
    title: 'Profile',
    buttonTitle: 'Edit profile',
    buttonIcon: 'edit',
    onClickAdd: () => history.push('/profile/edit'),
    parent: {
      title: 'Account',
      icon: <PersonIcon />,
    },
  },
  '/profile/edit': {
    title: 'Edit profile',
    parent: {
      title: 'Account',
      icon: <PersonIcon />,
    },
  },
  '/users/add': {
    title: 'Add User',
  },
  '/users/edit': {
    title: 'Edit User',
  },
  '/users/view': {
    title: 'View User',
    tooltipBtns: [
      {
        name: 'Edit',
        type: 'TooltipButtonLink',
        link: '/users/edit',
        params: '/',
        icon: 'edit',
      },
      {
        name: 'Websites',
        type: 'TooltipButtonLink',
        link: '/websites',
        params: '?userId=',
        icon: 'public',
      },
      {
        name: 'Impersonate',
        type: 'TooltipButton',
        link: 'AllowImpersonateUser',
        onClick: 'Impersonate',
        icon: 'person',
      },
    ],
  },
  '/reports-by-provider': {
    title: 'By Provider',
    enableDatePicker: true,
    parent: {
      title: 'Reports',
      icon: <DescriptionIcon />,
    },
    enableFilter: true,
  },
  '/reports-by-country': {
    title: 'By Country',
    enableDatePicker: true,
    parent: {
      title: 'Reports',
      icon: <DescriptionIcon />,
    },
    enableFilter: true,
  },
  '/reports-by-widget': {
    title: 'By Widget',
    enableDatePicker: true,
    parent: {
      title: 'Reports',
      icon: <DescriptionIcon />,
    },
    enableFilter: true,
  },
  '/reports-by-website': {
    title: 'By Website',
    enableDatePicker: true,
    parent: {
      title: 'Reports',
      icon: <DescriptionIcon />,
    },
    enableFilter: true,
  },
  '/brands': {
    title: 'Brands',
    buttonTitle: 'New Brand',
    onClickAdd: () => history.push('/brands/add'),
    parent: {
      title: 'Administration',
      icon: <Settings />,
    },
    enableSearch: true,
    enableFilter: true,
  },
  '/brands/add': {
    title: 'Add Brand',
  },
  '/brands/edit': {
    title: 'Edit Brand',
  },
  '/brands/view': {
    title: 'View Brand',
    tooltipBtns: [
      {
        name: 'Edit',
        type: 'TooltipButtonLink',
        link: '/brands/edit',
        params: '/',
        icon: 'edit',
      },
      {
        name: 'Users',
        type: 'TooltipButtonLink',
        link: '/users',
        params: '?brandId=',
        icon: 'group',
      },
      {
        name: 'Publishers',
        type: 'TooltipButtonLink',
        link: '/publishers',
        params: '?brandId=',
        icon: 'photo_album',
      },
      {
        name: 'Websites',
        type: 'TooltipButtonLink',
        link: '/websites',
        params: '?brandId=',
        icon: 'public',
      },
      {
        name: 'Widgets',
        type: 'TooltipButtonLink',
        link: '/widgets',
        params: '?brandId=',
        icon: 'poll',
      },
    ],
  },
  '/deductions': {
    title: 'Deductions',
    buttonTitle: 'New Deduct',
    onClickAdd: () => history.push('/deductions/add'),
    parent: {
      title: 'Administration',
      icon: <Settings />,
    },
    enableSearch: false,
    enableFilter: false,
  },
  '/deductions/add': {
    title: 'Add Deduct',
  },
  '/publishers': {
    title: 'Publishers',
    buttonTitle: 'New Publisher',
    onClickAdd: () => {
      return history.push('/publishers/add');
    },
    parent: {
      title: 'Administration',
      icon: <Settings />,
    },
    directParents: [
      {
        title: 'Brands',
        from: '/brands',
      },
    ],
    enableSearch: true,
    enableFilter: true,
  },
  '/publishers/add': {
    title: 'Add Publisher',
  },
  '/publishers/edit': {
    title: 'Edit Publisher',
  },
  '/publishers/view': {
    title: 'View Publisher',
    tooltipBtns: [
      {
        name: 'Edit',
        type: 'TooltipButtonLink',
        link: '/publishers/edit',
        params: '/',
        icon: 'edit',
      },
      {
        name: 'Users',
        type: 'TooltipButtonLink',
        link: '/users',
        params: '?publisherId=',
        icon: 'group',
      },
      {
        name: 'Websites',
        type: 'TooltipButtonLink',
        link: '/websites',
        params: '?publisherId=',
        icon: 'public',
      },
      {
        name: 'Widgets',
        type: 'TooltipButtonLink',
        link: '/widgets',
        params: '?publisherId=',
        icon: 'poll',
      },
      {
        name: 'Add',
        type: 'DropdownLinks',
        icon: 'add',
        links: [
          {
            name: 'Add website',
            type: 'TooltipButtonLink',
            link: '/websites/add',
            params: '?publisherId=',
            icon: 'add',
          },
        ],
      },
    ],
  },
  '/custom-reports': {
    enableDatePicker: false,
    parent: {
      title: 'Custom Reports',
      icon: <DonutLarge />,
    },
    buttonTitle: 'New custom report',
    onClickAdd: () => history.push('/custom-reports/add'),
  },
  '/custom-reports/add': {
    title: 'Add custom reports',
  },
  '/custom-reports/edit': {
    title: 'Edit custom reports',
  },
  '/websites': {
    title: 'Websites',
    buttonTitle: 'New Website',
    enableDatePicker: true,
    onClickAdd: () => {
      let url;
      location?.search
        ? (url = '/websites/add' + location.search)
        : (url = '/websites/add');
      history.push(url);
    },
    parent: {
      title: 'Administration',
      icon: <Settings />,
    },
    directParents: [
      {
        title: 'Brands',
        from: '/brands',
      },
      {
        title: 'Publishers',
        from: '/publishers',
      },
      {
        title: 'Users',
        from: '/users',
      },
    ],
    enableSearch: true,
    enableFilter: true,
  },
  '/websites/list': {
    title: 'List',
    parent: {
      title: 'Administration',
      icon: <Settings />,
    },
  },
  '/websites/add': {
    title: 'Add Website',
    parent: {
      title: 'Administration',
      icon: <Settings />,
    },
  },
  '/websites/edit': {
    title: 'Edit Website',
    parent: {
      title: 'Administration',
      icon: <Settings />,
    },
  },
  '/websites/view': {
    title: 'View Website',
    parent: {
      title: 'Administration',
      icon: <Settings />,
    },
    tooltipBtns: [
      {
        name: 'Edit',
        type: 'TooltipButtonLink',
        link: '/websites/edit',
        params: '/',
        icon: 'edit',
      },
      {
        name: 'Widgets',
        link: '/widgets',
        type: 'TooltipButtonLink',
        params: '?websiteId=',
        icon: 'poll',
      },
      {
        name: 'Ad Providers',
        type: 'TooltipButtonLink',
        link: '/ad-providers',
        params: '?websiteId=',
        icon: 'ballot',
      },
      {
        name: 'Add',
        type: 'DropdownLinks',
        icon: 'add',
        links: [
          {
            name: 'Add widget',
            link: '/widgets/add',
            type: 'TooltipButtonLink',
            params: '?websiteId=',
            icon: 'add',
          },
          {
            name: 'Add ad provider',
            link: '/ad-providers/add',
            type: 'TooltipButtonLink',
            params: '?websiteId=',
            icon: 'add',
          },
        ],
      },
    ],
  },
  '/widgets': {
    title: 'Widgets',
    buttonTitle: 'New Widget',
    onClickAdd: (search) => history.push(`/widgets/add${search}`),
    parent: {
      title: 'Administration',
      icon: <Settings />,
    },
    directParents: [
      {
        title: 'Brands',
        from: '/brands',
      },
      {
        title: 'Publishers',
        from: '/publishers',
      },
      {
        title: 'Websites',
        from: '/websites',
      },
    ],
    enableSearch: true,
    enableFilter: true,
  },
  '/widgets/list': {
    title: 'List',
    parent: {
      title: 'Administration',
      icon: <Settings />,
    },
  },
  '/widgets/add': {
    title: 'Add Widget',
    parent: {
      title: 'Administration',
      icon: <Settings />,
    },
  },
  '/widgets/edit': {
    title: 'Edit Widget',
    parent: {
      title: 'Administration',
      icon: <Settings />,
    },
  },
  '/widgets/view': {
    title: 'View Widget',
    parent: {
      title: 'Administration',
      icon: <Settings />,
    },
    tooltipBtns: [
      {
        name: 'Edit',
        type: 'TooltipButtonLink',
        link: '/widgets/edit',
        params: '/',
        icon: 'edit',
      },
      {
        name: 'Ad Groups',
        type: 'TooltipButtonLink',
        link: '/ad-groups',
        params: '?widgetId=',
        icon: 'ballot',
      },
      {
        name: 'Code',
        type: 'WidgetCodeButton',
        icon: 'developer_mode',
      },
      {
        name: 'Add',
        type: 'DropdownLinks',
        icon: 'add',
        links: [
          {
            name: 'Ad ad group',
            type: 'TooltipButtonLink',
            link: '/ad-groups/add',
            params: '?widgetId=',
            icon: 'add',
          },
        ],
      },
    ],
  },
  '/ad-groups': {
    title: 'Ad groups',
    buttonTitle: 'New Ad group',
    enableFilter: true,
    onClickAdd: (search) => history.push(`/ad-groups/add${search}`),
    parent: {
      title: 'Administration',
      icon: <Settings />,
    },
    directParents: [
      {
        title: 'Widgets',
        from: '/widgets',
      },
    ],
  },
  '/ad-groups/add': {
    title: 'Ad groups',
  },
  '/ad-groups/edit': {
    title: 'Ad groups',
  },
  '/ad-groups/view': {
    title: 'View Ad groups',
  },
  '/ad-providers': {
    title: 'Ad providers',
    buttonTitle: 'New Ad Provider',
    onClickAdd: (search) => history.push(`/ad-providers/add${search}`),
    parent: {
      title: 'Administration',
      icon: <Settings />,
    },
    directParents: [
      {
        title: 'Websites',
        from: '/websites',
      },
    ],
  },
  '/ad-providers/add': {
    title: 'Add Ad provider',
  },
  '/ad-providers/edit': {
    title: 'Edit Ad provider',
  },
  '/ad-providers/view': {
    title: 'View Ad provider',
    tooltipBtns: [
      {
        name: 'Edit',
        type: 'TooltipButtonLink',
        link: '/ad-providers/edit',
        params: '/',
        icon: 'edit',
      },
    ],
  },
  '/providers': {
    title: 'Providers',
    buttonTitle: 'New Provider',
    onClickAdd: () => history.push('/providers/add'),
    parent: {
      title: 'Administration',
      icon: <Settings />,
    },
    enableSearch: true,
    enableFilter: true,
  },
  '/providers/add': {
    title: 'Add Provider',
  },
  '/providers/edit': {
    title: 'Edit Provider',
  },
  '/providers/view': {
    title: 'View Provider',
    tooltipBtns: [
      {
        name: 'Edit',
        type: 'TooltipButtonLink',
        link: '/providers/edit',
        params: '/',
        icon: 'edit',
      },
    ],
  },
  '/ad-formats': {
    title: 'Ad formats',
    buttonTitle: 'New Ad Format',
    onClickAdd: (search) => history.push(`/ad-formats/add${search}`),
    parent: {
      title: 'Administration',
      icon: <Settings />,
    },
    directParents: [
      {
        title: 'Providers',
        from: '/providers',
      },
    ],
  },
  '/ad-formats/add': {
    title: 'Add Ad format',
  },
  '/ad-formats/edit': {
    title: 'Edit Ad format',
  },
  '/ad-formats/view': {
    title: 'View Ad format',
    tooltipBtns: [
      {
        name: 'Edit',
        type: 'TooltipButtonLink',
        link: '/ad-formats/edit',
        params: '/',
        icon: 'edit',
      },
    ],
  },
  '/external-reports': {
    title: 'External Reports',
    buttonTitle: 'Upload Reports Manually',
    onClickAdd: () => history.push('/external-reports/add'),
    parent: {
      title: 'Reports',
      icon: <DescriptionIcon />,
    },
    enableDatePicker: true,
  },
  '/external-reports/add': {
    title: 'Upload Reports Manually',
  },
  '/earnings': {
    title: 'Earnings',
    enableSearch: true,
    enableFilter: true,
    enableDatePicker: true,
    parent: {
      title: 'Account',
      icon: <PersonIcon />,
    },
  },
});
