export const initialState = {
  directParent: '',
  lastNode: '',
  dateRangeValue: {},
  isCreateModalOpened: false,
  isEditModalOpened: false,
  propertyId: null,
  createdUser: null,
  searchParam: null,
  highlightTopSelector: false,
  websitesViewMode: 'GRID',
  widgetsViewMode: 'GRID',
  appliedFiltersHeight: 0,
  selectedCustomReport: null,
  customReportTableChartPage: 0,
};
