import { gql } from '@apollo/client';

const FETCH_BRANDS = gql`
  query fetchBrands($filter: QueryFilter!) {
    brands(queryFilter: $filter) {
      items {
        _id
        updatedAt
        brandName
        isEnabled
        brandDomain
        brandStatus
        platformDomain
        companyName
        language
        brandInfo {
          termsOfUsePolicyContent
          privacyPolicyContent
          brandLogo
          brandLabel
          brandColorPallete
        }
        brandContactInfo {
          contactName
          contactEmail
          contactPhone
          conatctCountry
        }
        dealInfo {
          dealType
          value
        }
        managers {
          _id
        }
      }
      page
      totalCount
    }
  }
`;

const FETCH_BRAND_BY_ID = gql`
  query fetchBrand($id: String!) {
    brand(id: $id) {
      _id
      brandName
      isEnabled
      brandStatus
      brandDomain
      platformDomain
      companyName
      language
      brandInfo {
        termsOfUsePolicyContent
        privacyPolicyContent
        brandLogo
        brandLabel
        brandColorPallete
      }
      brandContactInfo {
        contactName
        contactEmail
        contactPhone
        conatctCountry
      }
      dealInfo {
        dealType
        value
        percentage
      }
      licenseFee {
        dealType
        value
        percentage
      }
      managers {
        _id
      }
      statusHistory {
        status
        createdAt
      }
    }
  }
`;

const BRAND_AUTO_COMPLETE_VALUE = gql`
  query fetchBrand($id: String!) {
    autoCompleteValue: brand(id: $id) {
      _id
      name: brandName
    }
  }
`;

export { FETCH_BRANDS, FETCH_BRAND_BY_ID, BRAND_AUTO_COMPLETE_VALUE };
