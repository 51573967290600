import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  hideRequestSpinner,
  showRequestSpinner,
} from '../../redux/store/ui/spinner';

const useGraphLazyQuery = (props, fetched) => {
  const dispatch = useDispatch();
  const [callback, { loading, error, data }] = useLazyQuery(props, {
    fetchPolicy: 'no-cache',
    onError: () => {},
  });

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        dispatch(showRequestSpinner());
        //dispatch(showSnakbar({ severity: 'warning', message: 'Fetching record(s)' }));
      }, 0);
    }
    if (error) {
      setTimeout(() => {
        dispatch(hideRequestSpinner());
        //dispatch(showSnakbar({ severity: 'error', message: 'Error occured fetching record(s)' }));
      }, 0);
    }
    if (data) {
      setTimeout(() => {
        if (fetched) {
          fetched(data);
        }
        dispatch(hideRequestSpinner());
        //dispatch(showSnakbar({ severity: 'success', message: 'Successfully fetched record(s)' }));
      }, 0);
    }
  }, [loading, error, data]);

  return [callback, { loading, error, data }];
};

export default useGraphLazyQuery;
