import { initialState } from './InitialState';

export const filtersReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_FILTER_PANEL':
      return {
        ...state,
        isFilterPanelOpen: true,
      };
    case 'CLOSE_FILTER_PANEL':
      return {
        ...state,
        isFilterPanelOpen: false,
        target: initialState.target,
        targetType: initialState.targetType,
      };
    case 'SET_TARGET':
      return {
        ...state,
        target: action.payload.target,
        targetType: action.payload.targetType,
      };
    case 'ADD_FILTER': {
      const { viewMode: vm, key, value } = action.payload;
      const valueInArray = Array.isArray(value) ? value : [value];
      const updateState = [...valueInArray];
      return {
        ...state,
        [vm]: {
          ...state[vm],
          [key]: updateState,
          version: state[vm]?.version + 1 || 0,
        },
      };
    }
    case 'REMOVE_FILTER': {
      const { viewMode: vm, key, value } = action.payload;
      const targetValue = state[vm][key];
      if (targetValue && Array.isArray(targetValue) && targetValue.length > 1) {
        return {
          ...state,
          [vm]: {
            ...state[vm],

            [key]: targetValue.filter((v) => {
              if (Array.isArray(value)) {
                return !value.includes(v);
              } else {
                return v !== value;
              }
            }),
          },
        };
      } else {
        const updatedState = { ...state };
        delete updatedState[vm][key];
        return {
          ...state,
          [vm]: {
            ...updatedState[vm],
            version: state[vm]?.version + 1 || 0,
          },
        };
      }
    }
    case 'CLEAR_FILTERS':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
