import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
} from '@material-ui/core';
import GridXHead from '../../components/GridX/GridXHead';
import { Countries, stableSort } from '../../utils';
import moment from 'moment';
import TooltipButton from '../../composition/TooltipButton';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import numeral from 'numeral';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCustomReportTableChartPage,
  setCustomReportTableChartPage,
} from '../../redux/store/ui/common';

const getMetricType = (metricType) => {
  switch (metricType) {
    case 'eCPC':
    case 'ecpc':
      return 'eCPC';
    case 'eCPM':
    case 'ecpm':
      return 'eCPM';
    default:
      return metricType;
  }
};

const formatCell = (inputName, value) => {
  switch (inputName) {
    case 'ecpc':
    case 'ecpm':
    case 'revenue':
      return `$${numeral(value).format('0.0000')}`;
    case 'ctr':
      return `${numeral(value * 100).format('0.0000')}%`;
    default:
      return value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 750,
    borderRadius: '10px 10px 0px 0px',
    width: 'auto',
  },
  tableRow: {
    borderRadius: '10px',
    background: '#F5F6FA',
    '&:nth-child(even)': {
      background: '#fff',
    },
  },
  tableCell: {
    borderBottom: 0,
    padding: '16px',
    fontFamily: 'Poppins, sans-serif',
  },
  tableSeparator: {
    height: '20px',
  },
  xgap: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  tableTitle: {
    textTransform: 'capitalize',
    padding: '0px',
    margin: '0px 0px 14px 0px',
    fontSize: '12px',
    color: '#20253A',
  },
}));

export const toDateTime = (milliSeconds) => {
  const seconds = Math.floor(milliSeconds / 1000);
  const date = new Date(1970, 0, 1);
  date.setSeconds(seconds);
  return date;
};

const ExpandableRow = ({ row, expandedRow, indexx, metrics, dimensions }) => {
  const classes = useStyles();
  const [expandedSubRow, setExpandedSubRow] = useState(null);
  const [subTableOrder, setSubTableOrder] = useState('desc');
  const [subTableOrderBy, setSubTableOrderBy] = useState('revenue');

  const handleSubTableRequestSort = (event, property) => {
    const isAsc = subTableOrderBy === property && subTableOrder === 'asc';
    setSubTableOrder(isAsc ? 'desc' : 'asc');
    setSubTableOrderBy(property);
  };

  let heads =
    row?.length && row[0]?.breakdown?.length > 0
      ? [...metrics]
      : [...dimensions, ...metrics];

  let headCells =
    row?.length && row[0]?.breakdown?.length > 0
      ? [
          {
            id: row[0]?.DimensionHead,
            label: row[0]?.DimensionHead,
          },
          ...heads.map((head) => ({
            id: head,
            label: getMetricType(head).toUpperCase(),
          })),
          {
            id: 'actions',
            label: '',
          },
        ]
      : [
          ...heads.map((head) => ({
            id: head,
            label: getMetricType(head).toUpperCase(),
          })),
        ];

  return (
    row?.length > 0 &&
    expandedRow === indexx && (
      <TableRow>
        <TableCell colSpan={metrics?.length + 2}>
          <TableContainer className={classes.table}>
            <Table
              aria-labelledby="tableTitle"
              size={'medium'}
              aria-label="enhanced table"
            >
              <GridXHead
                headCells={headCells}
                classes={classes}
                rowCount={row?.length}
                order={subTableOrder}
                orderBy={subTableOrderBy}
                onRequestSort={handleSubTableRequestSort}
                showTooltip={false}
              />
              <TableBody>
                {stableSort(row, subTableOrderBy, subTableOrder).map(
                  (breakdown, index) => (
                    <React.Fragment key={`report-row-${index}`}>
                      <TableRow className={classes.tableRow}>
                        {row[0]?.breakdown?.length > 0 && (
                          <TableCell
                            padding="none"
                            className={classes.tableCell}
                          >
                            {moment(toDateTime(breakdown.Dimension))?._isValid
                              ? moment(toDateTime(breakdown.Dimension)).format(
                                  'M-D',
                                )
                              : breakdown.Dimension || 'Others'}
                          </TableCell>
                        )}
                        {heads?.map((head, i) => (
                          <TableCell
                            className={classes.tableCell}
                            key={`${head}-${i}`}
                          >
                            {head === 'Date'
                              ? moment(
                                  toDateTime(breakdown[getMetricType(head)]),
                                ).format('M-D')
                              : head === 'ctr' ||
                                head === 'ecpc' ||
                                head === 'ecpm' ||
                                head === 'revenue'
                              ? formatCell(head, breakdown[getMetricType(head)])
                              : head === 'Country'
                              ? Countries?.find(
                                  (country) =>
                                    country.code ===
                                    breakdown[getMetricType(head)],
                                )?.name
                              : formatCell(
                                  head,
                                  breakdown[getMetricType(head)],
                                )}
                          </TableCell>
                        ))}
                        {breakdown?.breakdown?.length > 0 && (
                          <TableCell align="left" className={classes.tableCell}>
                            <TooltipButton
                              component={Button}
                              onClick={() => {
                                if (expandedSubRow === index) {
                                  setExpandedSubRow(null);
                                } else {
                                  setExpandedSubRow(index);
                                }
                              }}
                              title={'Show websites'}
                              size="small"
                              aria-label="public"
                              icon={
                                expandedSubRow === index ? (
                                  <ExpandLessIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )
                              }
                              className={classes.xgap}
                            />
                          </TableCell>
                        )}
                      </TableRow>

                      {breakdown?.breakdown?.length > 0 &&
                        expandedSubRow === index && (
                          <ExpandableRow
                            row={breakdown?.breakdown}
                            expandedRow={expandedSubRow}
                            indexx={index}
                            metrics={metrics}
                            dimensions={dimensions}
                          />
                        )}
                    </React.Fragment>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TableCell>
      </TableRow>
    )
  );
};

const TableChart = ({
  tableTitle,
  tableData,
  metrics,
  dimensions,
  count,
  onLoadMoreTableData,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const customReportTableChartPage = useSelector(
    selectCustomReportTableChartPage,
  );

  const [expandedRow, setExpandedRow] = useState(null);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('revenue');
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setCustomReportTableChartPage(newPage));
    onLoadMoreTableData(tableTitle, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch(setCustomReportTableChartPage(0));
  };

  let heads =
    tableData?.length && tableData[0]?.breakdown?.length > 0
      ? [...metrics]
      : [...dimensions, ...metrics];

  let headCells =
    tableData?.length && tableData[0]?.breakdown?.length > 0
      ? [
          {
            id: tableData[0]?.DimensionHead,
            label: tableData[0]?.DimensionHead.toUpperCase(),
          },
          ...heads.map((head) => ({
            id: head,
            label: getMetricType(head).toUpperCase(),
          })),
          {
            id: 'actions',
            label: '',
          },
        ]
      : [
          ...heads.map((head) => ({
            id: head,
            label: getMetricType(head).toUpperCase(),
          })),
        ];

  return (
    <>
      <h1 className={classes.tableTitle}>{tableTitle}</h1>
      <TableContainer className={classes.table}>
        <Table
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <GridXHead
            headCells={headCells}
            classes={classes}
            rowCount={tableData.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            showTooltip={false}
          />

          <TableBody>
            {stableSort(tableData, orderBy, order).map((row, index) => (
              <React.Fragment key={`provider-report-row-${index}`}>
                <TableRow className={classes.tableRow}>
                  {tableData[0]?.breakdown?.length > 0 && (
                    <TableCell padding="none" className={classes.tableCell}>
                      {moment(toDateTime(row.Dimension))?._isValid
                        ? moment(toDateTime(row.Dimension)).format('M-D')
                        : row.Dimension || 'Others'}
                    </TableCell>
                  )}
                  {heads.map((head, index) => (
                    <TableCell
                      className={classes.tableCell}
                      key={`${head}-${index}`}
                    >
                      {head === 'Date'
                        ? moment(toDateTime(row[getMetricType(head)])).format(
                            'M-D',
                          )
                        : head === 'ctr' ||
                          head === 'ecpc' ||
                          head === 'ecpm' ||
                          head === 'revenue'
                        ? formatCell(head, row[getMetricType(head)])
                        : head === 'Country'
                        ? Countries?.find(
                            (country) =>
                              country.code === row[getMetricType(head)],
                          )?.name
                        : formatCell(head, row[getMetricType(head)])}
                    </TableCell>
                  ))}
                  {row?.breakdown && (
                    <TableCell align="left" className={classes.tableCell}>
                      <TooltipButton
                        component={Button}
                        onClick={() => {
                          if (expandedRow === index) {
                            setExpandedRow(null);
                          } else {
                            setExpandedRow(index);
                          }
                        }}
                        title={'Show websites'}
                        size="small"
                        aria-label="public"
                        icon={
                          expandedRow === index ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )
                        }
                        className={classes.xgap}
                      />
                    </TableCell>
                  )}
                </TableRow>
                <ExpandableRow
                  row={row.breakdown}
                  expandedRow={expandedRow}
                  indexx={index}
                  metrics={metrics}
                  dimensions={dimensions}
                />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50]}
        component="div"
        count={count}
        page={customReportTableChartPage}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default TableChart;
