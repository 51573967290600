import React from 'react';
import { useStyles } from './PapaerContainer.styles';
import Paper from '@material-ui/core/Paper';
import AppliedFilters from '../../components/Filter/AppliedFilters';
import clsx from 'clsx';
import { parsePath } from '../../components/BreadcrumbNav/breadcrumb.helpers';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsCreateModalOpened } from '../../redux/store/ui/common';

const PaperContainer = ({
  children,
  bgColor,
  rounded,
  hideFilters = false,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { pathnames } = parsePath(location);
  const isModalOpened = useSelector(selectIsCreateModalOpened);

  return (
    <>
      <div className={classes.root} style={bgColor ? { padding: 0 } : {}}>
        {!hideFilters &&
          ((pathnames.length === 1 && !isModalOpened) ||
            location?.pathname?.includes('/custom-reports/view')) && (
            <AppliedFilters />
          )}
        <Paper
          className={clsx(classes.paper, {
            [classes.rounded]: rounded,
          })}
          elevation={0}
          style={bgColor ? { background: bgColor } : {}}
        >
          {children}
        </Paper>
      </div>
    </>
  );
};

export default PaperContainer;
