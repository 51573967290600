import {
  CLEAR_LOOK_UPS,
  SET_AD_PROVIDERS_LOOKUP_ITEMS,
  SET_BRANDS_LOOKUP_ITEMS,
  SET_PROVIDERS_LOOKUP_ITEMS,
  SET_PUBLISHERS_LOOKUP_ITEMS,
  SET_WEBSITES_LOOKUP_ITEMS,
  SET_WIDGETS_LOOKUP_ITEMS,
  SET_USERS_LOOKUP_ITEMS,
} from '../../../../constants/actionTypes';

export const setLookUpBrands = (data) => async (dispatch) => {
  dispatch({
    type: SET_BRANDS_LOOKUP_ITEMS,
    payload: data,
  });
};

export const setLookUpWebsites = (data) => async (dispatch) => {
  dispatch({
    type: SET_WEBSITES_LOOKUP_ITEMS,
    payload: data,
  });
};

export const setLookUpProviders = (data) => async (dispatch) => {
  dispatch({
    type: SET_PROVIDERS_LOOKUP_ITEMS,
    payload: data,
  });
};

export const setLookUpWidgets = (data) => async (dispatch) => {
  dispatch({
    type: SET_WIDGETS_LOOKUP_ITEMS,
    payload: data,
  });
};

export const setLookUpAdProviders = (data) => async (dispatch) => {
  dispatch({
    type: SET_AD_PROVIDERS_LOOKUP_ITEMS,
    payload: data,
  });
};

export const setLookUpPublishers = (data) => async (dispatch) => {
  dispatch({
    type: SET_PUBLISHERS_LOOKUP_ITEMS,
    payload: data,
  });
};

export const setLookUpUsers = (data) => async (dispatch) => {
  dispatch({
    type: SET_USERS_LOOKUP_ITEMS,
    payload: data,
  });
};

export const clearLookUps = () => async (dispatch) => {
  dispatch({
    type: CLEAR_LOOK_UPS,
  });
};
