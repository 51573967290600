import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
    '& > *': {
      width: '100%',
      height: '100%',
    },
  },
  paper: {
    background: '#fff',
    padding: '16px',
    marginTop: 0,
  },
  rounded: {
    borderRadius: '10px 10px 0px 0px',
    padding: 0,
  },
}));
