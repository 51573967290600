export const Categories = [
  { name: 'Arts and Entertainment', value: 'ArtsAndEntertainment' },
  { name: 'Animation and Comics', value: 'AnimationAndComics' },
  { name: 'Books and Literature', value: 'BooksAndLiterature' },
  { name: 'Humor', value: 'Humor' },
  { name: 'Music', value: 'Music' },
  { name: 'Performing Arts', value: 'PerformingArts' },
  { name: 'TV Movies and Streaming', value: 'TVMoviesAndStreaming' },
  { name: 'Visual Arts and Design', value: 'VisualArtsAndDesign' },
  {
    name: 'Business and Consumer Services',
    value: 'BusinessAndConsumerServices',
  },
  { name: 'Business Services', value: 'BusinessServices' },
  { name: 'Marketing and Advertising', value: 'MarketingAndAdvertising' },
  { name: 'Online Marketing', value: 'OnlineMarketing' },
  { name: 'Publishing and Printing', value: 'PublishingAndPrinting' },
  { name: 'Real Estate', value: 'RealEstate' },
  {
    name: 'Relocation and Household Moving',
    value: 'RelocationAndHouseholdMoving',
  },
  { name: 'Shipping and Logistics', value: 'ShippingAndLogistics' },
  { name: 'Textiles', value: 'Textiles' },
  { name: 'Community and Society', value: 'CommunityAndSociety' },
  { name: 'Decease', value: 'Decease' },
  { name: 'Faith and Beliefs', value: 'FaithAndBeliefs' },
  { name: 'Holidays and Seasonal Events', value: 'HolidaysAndSeasonalEvents' },
  { name: 'LGBTQ', value: 'LGBTQ' },
  { name: 'Philanthropy', value: 'Philanthropy' },
  { name: 'Romance and Relationships', value: 'RomanceAndRelationships' },
  { name: 'Advertising Networks', value: 'AdvertisingNetworks' },
  { name: 'Computer Hardware', value: 'ComputerHardware' },
  { name: 'Computer Security', value: 'ComputerSecurity' },
  {
    name: 'Computers Electronics and Technology',
    value: 'ComputersElectronicsAndTechnology',
  },
  { name: 'Consumer Electronics', value: 'ConsumerElectronics' },
  { name: 'Email', value: 'Email' },
  { name: 'File Sharing and Hosting', value: 'FileSharingAndHosting' },
  {
    name: 'Graphics Multimedia and Web Design',
    value: 'GraphicsMultimediaAndWebDesign',
  },
  {
    name: 'Programming and Developer Software',
    value: 'ProgrammingAndDeveloperSoftware',
  },
  { name: 'SearchEngines', value: 'SearchEngines' },
  {
    name: 'Social Networks and Online Communities',
    value: 'SocialNetworksAndOnlineCommunities',
  },
  { name: 'Telecommunications', value: 'Telecommunications' },
  { name: 'Web Hosting and Domain Names', value: 'WebHostingAndDomainNames' },
  { name: 'Auctions', value: 'Auctions' },
  { name: 'Classifieds', value: 'Classifieds' },
  { name: 'Coupons and Rebates', value: 'CouponsAndRebates' },
  { name: 'E commerce and Shopping', value: 'ECommerceAndShopping' },
  { name: 'Marketplace', value: 'Marketplace' },
  { name: 'Price Comparison', value: 'PriceComparison' },
  { name: 'Tickets', value: 'Tickets' },
  { name: 'Accounting and Auditing', value: 'AccountingAndAuditing' },
  { name: 'BankingCreditAndLending', value: 'BankingCreditAndLending' },
  { name: 'Finance', value: 'Finance' },
  {
    name: 'FinancialPlanningAndManagement',
    value: 'FinancialPlanningAndManagement',
  },
  { name: 'Insurance', value: 'Insurance' },
  { name: 'Investing', value: 'Investing' },
  { name: 'Beverages', value: 'Beverages' },
  { name: 'Cooking and Recipes', value: 'CookingAndRecipes' },
  { name: 'Food and Drink', value: 'FoodAndDrink' },
  { name: 'Groceries', value: 'Groceries' },
  { name: 'Restaurants and Delivery', value: 'RestaurantsAndDelivery' },
  { name: 'Vegetarian and Vegan', value: 'VegetarianAndVegan' },
  { name: 'Bingo', value: 'Bingo' },
  { name: 'Casinos', value: 'Casinos' },
  { name: 'Gambling', value: 'Gambling' },
  { name: 'Lottery', value: 'Lottery' },
  { name: 'Poker', value: 'Poker' },
  { name: 'Sports Betting', value: 'SportsBetting' },
  { name: 'Board and Card Games', value: 'BoardAndCardGames' },
  { name: 'Games', value: 'Games' },
  { name: 'Puzzles and Brainteasers', value: 'PuzzlesAndBrainteasers' },
  { name: 'Roleplaying Games', value: 'RoleplayingGames' },
  {
    name: 'Video Games Consoles and Accessories',
    value: 'VideoGamesConsolesAndAccessories',
  },
  { name: 'Addictions', value: 'Addictions' },
  {
    name: 'Alternative and Natural Medicine',
    value: 'AlternativeAndNaturalMedicine',
  },
  {
    name: 'Biotechnology and Pharmaceuticals',
    value: 'BiotechnologyAndPharmaceuticals',
  },
  { name: 'Children Health', value: 'ChildrenHealth' },
  { name: 'Dentist and Dental Services', value: 'DentistAndDentalServices' },
  {
    name: 'Developmental and Physical Disabilities',
    value: 'DevelopmentalAndPhysicalDisabilities',
  },
  { name: 'Geriatric and Aging Care', value: 'GeriatricAndAgingCare' },
  { name: 'Health', value: 'Health' },
  {
    name: 'Health Conditions and Concerns',
    value: 'HealthConditionsAndConcerns',
  },
  { name: 'Medicine', value: 'Medicine' },
  { name: 'MenHealth', value: 'MenHealth' },
  { name: 'Mental Health', value: 'MentalHealth' },
  { name: 'Nutrition and Fitness', value: 'NutritionAndFitness' },
  { name: 'Nutrition Diets and Fitness', value: 'NutritionDietsAndFitness' },
  { name: 'Pharmacy', value: 'Pharmacy' },
  { name: 'Public Health and Safety', value: 'PublicHealthAndSafety' },
  { name: 'Women Health', value: 'WomenHealth' },
  { name: 'Aerospace and Defense', value: 'AerospaceAndDefense' },
  { name: 'Agriculture', value: 'Agriculture' },
  { name: 'Architecture', value: 'Architecture' },
  { name: 'Chemical Industry', value: 'ChemicalIndustry' },
  { name: 'Construction and Maintenance', value: 'ConstructionAndMaintenance' },
  { name: 'Energy Industry', value: 'EnergyIndustry' },
  {
    name: 'Heavy Industry and Engineering',
    value: 'HeavyIndustryAndEngineering',
  },
  { name: 'Metals and Mining', value: 'MetalsAndMining' },
  {
    name: 'Waste Water and Environmental',
    value: 'WasteWaterAndEnvironmental',
  },
  { name: 'Ancestry and Genealogy', value: 'AncestryAndGenealogy' },
  { name: 'Antiques and Collectibles', value: 'AntiquesAndCollectibles' },
  {
    name: 'Camping Scouting and Outdoors',
    value: 'CampingScoutingAndOutdoors',
  },
  { name: 'Crafts', value: 'Crafts' },
  { name: 'Hobbies and Leisure', value: 'HobbiesAndLeisure' },
  { name: 'Photography', value: 'Photography' },
  { name: 'Furniture', value: 'Furniture' },
  { name: 'Gardening', value: 'Gardening' },
  { name: 'Home and Garden', value: 'HomeAndGarden' },
  {
    name: 'Home Improvement and Maintenance',
    value: 'HomeImprovementAndMaintenance',
  },
  { name: 'Interior Design', value: 'InteriorDesign' },
  { name: 'Human Resources', value: 'HumanResources' },
  { name: 'Jobs and Career', value: 'JobsAndCareer' },
  { name: 'Jobs and Employment', value: 'JobsAndEmployment' },
  { name: 'Government', value: 'Government' },
  { name: 'Immigration and Visas', value: 'ImmigrationAndVisas' },
  { name: 'Law and Government', value: 'LawAndGovernment' },
  {
    name: 'Law Enforcement and Protective Services',
    value: 'LawEnforcementAndProtectiveServices',
  },
  { name: 'Legal', value: 'Legal' },
  { name: 'National Security', value: 'NationalSecurity' },
  { name: 'Beauty and Cosmetics', value: 'BeautyAndCosmetics' },
  { name: 'Childcare', value: 'Childcare' },
  { name: 'Fashion and Apparel', value: 'FashionAndApparel' },
  { name: 'Gifts and Flowers', value: 'GiftsAndFlowers' },
  { name: 'Jewelry and Luxury Products', value: 'JewelryAndLuxuryProducts' },
  { name: 'Lifestyle', value: 'Lifestyle' },
  { name: 'Tobacco', value: 'Tobacco' },
  { name: 'Weddings', value: 'Weddings' },
  { name: 'News and Media', value: 'NewsAndMedia' },
  { name: 'Pets and Animals', value: 'PetsAndAnimals' },
  { name: 'Animals', value: 'Animals' },
  { name: 'Birds', value: 'Birds' },
  { name: 'Fish and Aquaria', value: 'FishAndAquaria' },
  { name: 'Horses', value: 'Horses' },
  { name: 'Pet Food and Supplies', value: 'PetFoodAndSupplies' },
  { name: 'Pets', value: 'Pets' },
  {
    name: 'Dictionaries and Encyclopedias',
    value: 'DictionariesAndEncyclopedias',
  },
  { name: 'Maps', value: 'Maps' },
  {
    name: 'Public Records and Directories',
    value: 'PublicRecordsAndDirectories',
  },
  { name: 'Reference Materials', value: 'ReferenceMaterials' },
  { name: 'Astronomy', value: 'Astronomy' },
  { name: 'Biology', value: 'Biology' },
  { name: 'Business Training', value: 'BusinessTraining' },
  { name: 'Chemistry', value: 'Chemistry' },
  { name: 'Colleges and Universities', value: 'CollegesAndUniversities' },
  { name: 'Earth Sciences', value: 'EarthSciences' },
  { name: 'Education', value: 'Education' },
  { name: 'Environmental Science', value: 'EnvironmentalScience' },
  {
    name: 'Grants Scholarships and Financial Aid',
    value: 'GrantsScholarshipsAndFinancialAid',
  },
  { name: 'History', value: 'History' },
  { name: 'Libraries and Museums', value: 'LibrariesAndMuseums' },
  { name: 'Literature', value: 'Literature' },
  { name: 'Math', value: 'Math' },
  { name: 'Philosophy', value: 'Philosophy' },
  { name: 'Physics', value: 'Physics' },
  { name: 'Science and Education', value: 'ScienceAndEducation' },
  { name: 'Social Sciences', value: 'SocialSciences' },
  { name: 'Universities and Colleges', value: 'UniversitiesAndColleges' },
  { name: 'Weather', value: 'Weather' },
  { name: 'American Football', value: 'AmericanFootball' },
  { name: 'Baseball', value: 'Baseball' },
  { name: 'Basketball', value: 'Basketball' },
  { name: 'Boxing', value: 'Boxing' },
  { name: 'Climbing', value: 'Climbing' },
  { name: 'Cycling and Biking', value: 'CyclingAndBiking' },
  { name: 'Extreme Sports', value: 'ExtremeSports' },
  { name: 'Fantasy Sports', value: 'FantasySports' },
  { name: 'Fishing', value: 'Fishing' },
  { name: 'Golf', value: 'Golf' },
  { name: 'Hunting and Shooting', value: 'HuntingAndShooting' },
  { name: 'Martial Arts', value: 'MartialArts' },
  { name: 'Rugby', value: 'Rugby' },
  { name: 'Running', value: 'Running' },
  { name: 'Soccer', value: 'Soccer' },
  { name: 'Sports', value: 'Sports' },
  { name: 'Tennis', value: 'Tennis' },
  { name: 'Volleyball', value: 'Volleyball' },
  { name: 'Water Sports', value: 'WaterSports' },
  { name: 'Winter Sports', value: 'WinterSports' },
  { name: 'Accommodation and Hotels', value: 'AccommodationAndHotels' },
  { name: 'Air Travel', value: 'AirTravel' },
  { name: 'Car Rentals', value: 'CarRentals' },
  { name: 'Ground Transportation', value: 'GroundTransportation' },
  { name: 'Tourist Attractions', value: 'TouristAttractions' },
  {
    name: 'Transportation and Excursions',
    value: 'TransportationAndExcursions',
  },
  { name: 'Travel and Tourism', value: 'TravelAndTourism' },
  { name: 'Automotive Industry', value: 'AutomotiveIndustry' },
  { name: 'Aviation', value: 'Aviation' },
  { name: 'Boats', value: 'Boats' },
  { name: 'Makes and Models', value: 'MakesAndModels' },
  { name: 'Motorcycles', value: 'Motorcycles' },
  { name: 'Motorsports', value: 'Motorsports' },
  { name: 'Vehicles', value: 'Vehicles' },
  { name: 'Adult', value: 'Adult' },
];
