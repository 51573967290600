import React from 'react';
import { useSelector } from 'react-redux';
import AppliedFiltersTag from './AppliedFiltersTag';
import { selectFilterFor } from '../../redux/store/ui/filters';
import { getViewMode } from './helpers/getTargets';
import { useLocation } from 'react-router-dom';
import { useStyles } from './AppliedFilters.styles';

const AppliedFilters = () => {
  const classes = useStyles();
  const viewMode = getViewMode(useLocation());
  const filters = useSelector(selectFilterFor(viewMode));

  return (
    <>
      <div className={classes.appliedFilterWrapper}>
        {filters &&
          Object.entries(filters).map(([key, value], id) => {
            if (key === 'version') {
              return null;
            } else if (Array.isArray(value)) {
              return value.map((v, i) => (
                <AppliedFiltersTag
                  viewMode={viewMode}
                  key={`applied-filter-tag-${id}-${i}`}
                  label={key}
                  value={v}
                />
              ));
            } else {
              return (
                <AppliedFiltersTag
                  viewMode={viewMode}
                  key={`applied-filter-tag-${id}`}
                  label={key}
                  value={value}
                />
              );
            }
          })}
      </div>
    </>
  );
};

export default AppliedFilters;
