import React from 'react';
import Selection from './Selection';

const options = [
  { label: 'True', value: 'true', disabled: false },
  { label: 'False', value: 'false', disabled: false },
];

const BooleanSelection = ({ setValue }) => {
  return <Selection options={options} withInput={false} setValue={setValue} />;
};

export default BooleanSelection;
