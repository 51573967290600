import targetsForUsers from '../targets/targetsForUsers';
import targetsForBrands from '../targets/targetsForBrands';
import targetsForPublishers from '../targets/targetsForPublishers';
import targetsForWebsites from '../targets/targetsForWebsites';
import targetsForWidgets from '../targets/targetsForWidgets';
import targetsForProviders from '../targets/targetsForProviders';
import targetsForSetup from '../targets/targetsForSetup';
import targetsForProviderReports from '../targets/targetsForProviderReport';
import targetsForCountryReports from '../targets/targetsForCountryReport';
import targetsForWidgetReports from '../targets/targetsForWidgetReport';
import targetsForWebsiteReports from '../targets/targetsForWebsiteReport';
import targetsForViewCustomReport from '../targets/targetsForViewCustomReport';
import targetsForEarnings from '../targets/targetsForEarnings';
import targetsForAdGroups from '../targets/targetsForAdGroups';

export const getViewMode = (location) => {
  if (location.pathname.includes('/users')) {
    return 'USERS';
  }
  if (location.pathname.includes('/brands')) {
    return 'BRANDS';
  }
  if (location.pathname.includes('/publishers')) {
    return 'PUBLISHERS';
  }
  if (location.pathname.includes('/websites')) {
    return 'WEBSITES';
  }
  if (location.pathname.includes('/widgets')) {
    return 'WIDGETS';
  }
  if (location.pathname.includes('/providers')) {
    return 'PROVIDERS';
  }
  if (location.pathname.includes('/setup')) {
    return 'SETUP';
  }
  if (location.pathname.includes('/reports-by-provider')) {
    return 'REPORTS_BY_PROVIDER';
  }
  if (location.pathname.includes('/reports-by-country')) {
    return 'REPORTS_BY_COUNTRY';
  }
  if (location.pathname.includes('/reports-by-widget')) {
    return 'REPORTS_BY_WIDGET';
  }
  if (location.pathname.includes('/reports-by-website')) {
    return 'REPORTS_BY_WEBSITE';
  }
  if (
    location.pathname.includes('/dashboard') ||
    location.pathname.includes('/custom-reports/view')
  ) {
    return 'VIEW_CUSTOM_REPORT';
  }
  if (location.pathname.includes('/earnings')) {
    return 'EARNINGS';
  }
  if (location.pathname.includes('/ad-groups')) {
    return 'AD_GROUPS';
  }
};

const getTargets = (viewMode) => {
  if (viewMode === 'USERS') {
    return targetsForUsers;
  }
  if (viewMode === 'BRANDS') {
    return targetsForBrands;
  }
  if (viewMode === 'PUBLISHERS') {
    return targetsForPublishers;
  }
  if (viewMode === 'WEBSITES') {
    return targetsForWebsites;
  }
  if (viewMode === 'WIDGETS') {
    return targetsForWidgets;
  }
  if (viewMode === 'PROVIDERS') {
    return targetsForProviders;
  }
  if (viewMode === 'SETUP') {
    return targetsForSetup;
  }
  if (viewMode === 'REPORTS_BY_PROVIDER') {
    return targetsForProviderReports;
  }
  if (viewMode === 'REPORTS_BY_COUNTRY') {
    return targetsForCountryReports;
  }
  if (viewMode === 'REPORTS_BY_WIDGET') {
    return targetsForWidgetReports;
  }
  if (viewMode === 'REPORTS_BY_WEBSITE') {
    return targetsForWebsiteReports;
  }
  if (viewMode === 'VIEW_CUSTOM_REPORT') {
    return targetsForViewCustomReport;
  }
  if (viewMode === 'EARNINGS') {
    return targetsForEarnings;
  }
  if (viewMode === 'AD_GROUPS') {
    return targetsForAdGroups;
  }
};

export default getTargets;
