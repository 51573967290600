import moment from 'moment';

function getEndOfPreviousWeek() {
  const date = new Date();
  const today = date.getDate() - 1;
  const dayOfTheWeek = date.getDay();
  const newDate = date.setDate(today - (dayOfTheWeek || 7));
  return new Date(newDate);
}

export const getDateRange = (dateFilter) => {
  if (dateFilter) {
    let date1 = new Date();
    let date2 = new Date();
    switch (dateFilter) {
      case 'Yesterday':
        date1.setDate(date1.getDate() - 1);
        date2.setDate(date2.getDate() - 1);
        return { startDate: date1, endDate: date2 };
      case 'Today':
        return { startDate: date1, endDate: date2 };
      case 'Last30Days':
        date1.setDate(date1.getDate() - 30);
        return { startDate: date1, endDate: date2 };
      case 'LastMonth':
        date1 = new Date(date1.getFullYear(), date1.getMonth() - 1, 1);
        date2 = new Date(
          date2.getFullYear(),
          date2.getMonth() - 1,
          moment(date2.getMonth() - 1).daysInMonth(),
        );
        return { startDate: date1, endDate: date2 };
      case 'ThisMonth':
        date1.setDate(1);
        return { startDate: date1, endDate: date2 };
      case 'LastWeek':
        date2 = getEndOfPreviousWeek();
        date1 = new Date(
          date1.getFullYear(),
          date2.getMonth(),
          date2.getDate() - 6,
        );
        return { startDate: date1, endDate: date2 };
    }
  }
  return null;
};
