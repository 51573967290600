import {
  CLEAR_LOOK_UPS,
  SET_AD_PROVIDERS_LOOKUP_ITEMS,
  SET_BRANDS_LOOKUP_ITEMS,
  SET_PROVIDERS_LOOKUP_ITEMS,
  SET_PUBLISHERS_LOOKUP_ITEMS,
  SET_USERS_LOOKUP_ITEMS,
  SET_WEBSITES_LOOKUP_ITEMS,
  SET_WIDGETS_LOOKUP_ITEMS,
} from '../../../../constants/actionTypes';
import { initialState } from './InitialState';

export const lookUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BRANDS_LOOKUP_ITEMS:
      return { ...state, brands: action.payload };
    case SET_WEBSITES_LOOKUP_ITEMS:
      return { ...state, websites: action.payload };
    case SET_PROVIDERS_LOOKUP_ITEMS:
      return {
        ...state,
        providers: action.payload.map((item) => ({
          ...item,
          dealType: item.dealInfo.dealType,
        })),
      };
    case SET_WIDGETS_LOOKUP_ITEMS:
      return { ...state, widgets: action.payload };
    case SET_AD_PROVIDERS_LOOKUP_ITEMS:
      return { ...state, adProviders: action.payload };
    case SET_PUBLISHERS_LOOKUP_ITEMS:
      return {
        ...state,
        publishers: action.payload.map((item) => ({
          ...item,
          publisherOwner: item?.publisherUser?.email,
        })),
      };
    case SET_USERS_LOOKUP_ITEMS:
      return { ...state, users: action.payload };
    case CLEAR_LOOK_UPS:
      return { ...initialState };
    default:
      return state;
  }
};
