import { gql } from '@apollo/client';

const FETCH_EXTERNAL_WIDGETS = gql`
  query fetchExternalWidgets($filter: QueryFilter!) {
    externalwidgets(queryFilter: $filter) {
      items {
        _id
        widgetName
        widgetStatus
        isEnabled
        externalUniqueIdentifier
        website {
          _id
          displayName
        }
        provider {
          providerId
          parameters {
            parameterName
            parameterLabel
            parameterValue
          }
        }
      }
      page
      totalCount
    }
  }
`;

const FETCH_EXTERNAL_WIDGETS_BY_USER = gql`
  query externalwidgetsByUser($userId: String!, $filter: QueryFilter!) {
    externalwidgetsByUser(userId: $userId, queryFilter: $filter) {
      items {
        _id
        widgetName
        widgetStatus
        isEnabled
        externalUniqueIdentifier
        website {
          _id
          displayName
        }
        provider {
          providerId
          parameters {
            parameterName
            parameterLabel
            parameterValue
          }
        }
      }
      page
      totalCount
    }
  }
`;

const FETCH_EXTERNAL_WIDGET_BY_ID = gql`
  query fetchExternalWidget($id: String!) {
    externalWidget(id: $id) {
      _id
      widgetName
      widgetStatus
      isEnabled
      externalUniqueIdentifier
      website {
        _id
        displayName
      }
      provider {
        providerId
        parameters {
          parameterName
          parameterLabel
          parameterValue
        }
      }
    }
  }
`;

export {
  FETCH_EXTERNAL_WIDGET_BY_ID,
  FETCH_EXTERNAL_WIDGETS_BY_USER,
  FETCH_EXTERNAL_WIDGETS,
};
