import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dateDisplay: {
    background: '#F7F7F7',
    color: '#848899',
    fontSize: '14px',
    fontWeight: '500',
    padding: '5px 10px',
    height: '45px',
    borderRadius: '10px',
    cursor: 'pointer',
    position: 'relative',
  },
  datePicker: {
    position: 'absolute',
    background: '#F7F7F7',
    borderRadius: '5px',
    padding: '10px',
    boxShadow: '0px 0px 2px 2px rgba(132, 136, 153, 0.25)',
    top: `calc(100% + ${theme.spacing(1)}px)`,
    transform: 'translateX(-100%)',
    left: '100%',
  },
  datePickerRoot: {
    position: 'relative',
    marginRight: '10px',
    zIndex: theme.zIndex.drawer,
  },
  applyButton: {
    padding: '8px 18px',
    color: '#fff',
    background: '#54abdf',
    marginLeft: '1rem',
    borderRadius: '8px',
    boxShadow: 'none',
    '&:hover': {
      background: '#439ace',
    },
  },
  actionBar: {
    borderTop: '1px solid #848899',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  presetBane: {
    borderLeft: '1px solid #848899',
  },
  calendar: {},
  calendarIcon: {
    marginRight: '5px',
  },
}));
