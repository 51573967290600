import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  xgap: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  table: {
    minWidth: 750,
    padding: '10px 24px 10px 24px',
  },
  tableRow: {
    borderRadius: '10px',
    background: '#F5F6FA',
  },
  tableCell: {
    borderBottom: 0,
    padding: '16px',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    '& p': {
      fontFamily: 'Poppins, sans-serif',
      fontSize: 13,
      maxWidth: '200px',
      wordWrap: 'break-word',
    },
  },
  tableSeparator: {
    //height: '20px',
  },
  spinnerWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
