import { gql } from '@apollo/client';

const FETCH_EXTERNAL_REPORTS_DATA = gql`
  query fetchExternalReports($filter: QueryFilter!) {
    externalreportsData(queryFilter: $filter) {
      totals {
        totalClicks
        totalRevenue
        totalImpressions
        CTR
        eCPM
        eCPC
      }
      currentCalculated {
        eCPM
        CTR
        eCPM
        eCPC
      }
      beforeCalculated {
        eCPM
      }
      items {
        widget {
          _id
          widgetName
        }
        website {
          _id
          websiteDomain
        }
        provider {
          _id
          providerName
        }
        externalWidget {
          _id
          widgetName
        }
        device
        reportDate
        country
        clicks
        impressions
        revenue
        eCPC
        CTR
        eCPM
      }
      page
      totalCount
    }
  }
`;

const FETCH_BY_PROVIDER_EXTERNAL_REPORTS_DATA = gql`
  query fetchExternalReports($filter: QueryFilter!) {
    externalreportsData(queryFilter: $filter) {
      totals {
        totalClicks
        totalRevenue
        totalImpressions
        CTR
        eCPM
        eCPC
      }
      currentCalculated {
        eCPM
        CTR
        eCPM
        eCPC
      }
      beforeCalculated {
        eCPM
      }
      items {
        website {
          _id
          websiteDomain
        }
        provider {
          _id
          providerName
        }
        reportDate
        clicks
        impressions
        revenue
        eCPC
        CTR
        eCPM
      }
      page
      totalCount
    }
  }
`;

const FETCH_BY_COUNTRY_EXTERNAL_REPORTS_DATA = gql`
  query fetchExternalReports($filter: QueryFilter!) {
    externalreportsData(queryFilter: $filter) {
      totals {
        totalClicks
        totalRevenue
        totalImpressions
        CTR
        eCPM
        eCPC
      }
      currentCalculated {
        eCPM
        CTR
        eCPM
        eCPC
      }
      beforeCalculated {
        eCPM
      }
      items {
        reportDate
        country
        clicks
        impressions
        revenue
        eCPC
        CTR
        eCPM
      }
      page
      totalCount
    }
  }
`;

const FETCH_BY_WEBSITE_EXTERNAL_REPORTS_DATA = gql`
  query fetchExternalReports($filter: QueryFilter!) {
    externalreportsData(queryFilter: $filter) {
      totals {
        totalClicks
        totalRevenue
        totalImpressions
        CTR
        eCPM
        eCPC
      }
      currentCalculated {
        eCPM
        CTR
        eCPM
        eCPC
      }
      beforeCalculated {
        eCPM
      }
      items {
        website {
          _id
          websiteDomain
        }
        device
        reportDate
        clicks
        impressions
        revenue
        eCPC
        CTR
        eCPM
      }
      page
      totalCount
    }
  }
`;

const FETCH_BY_WIDGET_EXTERNAL_REPORTS_DATA = gql`
  query fetchExternalReports($filter: QueryFilter!) {
    externalreportsData(queryFilter: $filter) {
      totals {
        totalClicks
        totalRevenue
        totalImpressions
        CTR
        eCPM
        eCPC
      }
      currentCalculated {
        eCPM
        CTR
        eCPM
        eCPC
      }
      beforeCalculated {
        eCPM
      }
      items {
        widget {
          _id
          widgetName
        }
        device
        reportDate
        clicks
        impressions
        revenue
        eCPC
        CTR
        eCPM
      }
      page
      totalCount
    }
  }
`;

export {
  FETCH_EXTERNAL_REPORTS_DATA,
  FETCH_BY_PROVIDER_EXTERNAL_REPORTS_DATA,
  FETCH_BY_COUNTRY_EXTERNAL_REPORTS_DATA,
  FETCH_BY_WEBSITE_EXTERNAL_REPORTS_DATA,
  FETCH_BY_WIDGET_EXTERNAL_REPORTS_DATA,
};
