import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeFilterPanel,
  openFilterPanel,
  selectFilterTarget,
  selectIsFilterPanelOpen,
} from '../../redux/store/ui/filters';
import FilterPanel from './FilterPanel';
import getTargets, { getViewMode } from './helpers/getTargets';
import { useLocation } from 'react-router-dom';
import { useStyles } from './AddFilterButton.styles';

const AddFilterButton = () => {
  const viewMode = getViewMode(useLocation());
  const classes = useStyles();
  const node = useRef(null);
  const dispatch = useDispatch();
  const isPanelOpen = useSelector(selectIsFilterPanelOpen);
  const targets = getTargets(viewMode);
  const target = useSelector(selectFilterTarget);

  const handleOnClick = (e) => {
    e.preventDefault();
    if (isPanelOpen) {
      dispatch(closeFilterPanel());
    } else {
      dispatch(openFilterPanel());
    }
  };

  const handleOnClickOutSide = (e) => {
    const isClickedInside =
      node.current.contains(e.target) ||
      (typeof e.target.className === 'string' &&
        e.target.className.includes('dropdown')) ||
      (typeof e.target.className === 'string' &&
        e.target.className.includes('calendar')) ||
      e.target.id.includes('Selectbox');
    if (!isClickedInside) {
      dispatch(closeFilterPanel());
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOnClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleOnClickOutSide);
    };
  }, []);

  return (
    <div className={classes.addFilterWrapper} ref={node}>
      <div className={classes.addFilterButton} onClick={handleOnClick}>
        <img
          className={classes.filterIcon}
          src={window.location.origin + '/icons/filter.svg'}
          alt="filter icon"
        />
        {target && (
          <p className={classes.buttonText}>
            {targets?.find((item) => item?.value === target)?.label || target}
          </p>
        )}
      </div>
      {isPanelOpen && (
        <div className={classes.panelWrapper}>
          <FilterPanel />
        </div>
      )}
    </div>
  );
};

export default AddFilterButton;
