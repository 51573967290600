import { SHOW_SNAKBAR, HIDE_SNAKBAR } from '../../../../constants/actionTypes';
import { initialState } from './InitialState';

export const snakbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SNAKBAR:
      return {
        ...state,
        open: true,
        message: action.payload.message,
        severity: action.payload.severity,
      };
    case HIDE_SNAKBAR:
      return { ...state, open: false, message: '' };
    default:
      return state;
  }
};
