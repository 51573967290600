import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  message: {
    color: 'red',
    marginBottom: 0,
  },
  passwordMessage: {
    color: 'red',
    marginTop: '-18px',
  },
}));

const ValidationMessage = ({ formik, name, msg, className }) => {
  const classes = useStyles();
  let errorMsg = '';
  let touched = '';
  if (name) {
    errorMsg = name.split('.').reduce((p, c) => p?.[c], formik.errors);
    touched = name.split('.').reduce((p, c) => p?.[c], formik.touched);
  }

  return touched && errorMsg ? (
    <p
      className={
        className
          ? classes[className]
          : name === 'password'
          ? classes.passwordMessage
          : classes.message
      }
    >
      {errorMsg}
    </p>
  ) : msg ? (
    <p className={classes.message}>{msg}</p> //enable general message without formik
  ) : null;
};

ValidationMessage.propTypes = {
  formik: PropTypes.any,
  name: PropTypes.string,
};

export default ValidationMessage;
