import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '550px',
    position: 'relative',
    border: '1px solid rgba(28,110,164,0.21)',
    borderRadius: '5px 5px 5px 5px',
    boxShadow: 'none',
  },
  cardContainer: {
    paddingBottom: '16px !important',
  },
  chartTitle: {
    textTransform: 'capitalize',
    padding: '0px',
    margin: '0px',
    fontSize: '12px',
    color: '#20253A',
  },
  chartNote: {
    color: grey.A200,
  },
  refreshIcon: {
    cursor: 'pointer',
  },
}));
