import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#fff',
    position: 'sticky',
    top: 80,
    bottom: 20,
    zIndex: 6,
    minHeight: 64,
  },
  breadcurmb: {
    background: '#fff',
    opacity: '1',
    paddingLeft: theme.spacing(1.5),
  },
  button: {
    padding: '8px 18px',
    color: '#fff',
    background: '#54abdf',
    '&:hover': {
      background: '#439ace',
    },
    marginRight: '1rem',
    borderRadius: '8px',
    boxShadow: 'none',
  },
  breadcrumbDropdown: {
    display: 'inline-block',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  search: {
    width: '7rem',
    borderRadius: 50,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    transition: 'width 0.2s ease-in-out',
  },
  lg: {
    width: '17rem',
  },
  tooltipBox: {
    padding: '12px',
  },
  xgap: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  download: {
    height: '37px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 10px',
    margin: 0,
    whiteSpace: 'nowrap',
    color: '#848899',
    borderRadius: '8px',
    cursor: 'pointer',
    background: '#F7F7F7',
    boxShadow: '0px 0px 1px 1px rgb(132 136 153 / 25%)',
    marginRight: '8px',
  },
}));
