import React from 'react';
import { useSelector } from 'react-redux';
import { useStyles } from './MainLayout.styles';
import PrimaryTopBar from '../components/PrimaryTopbar';
import SideNav from '../components/SideNav';
import BreadcrumbNav from '../components/BreadcrumbNav';
import PaperSpinner from '../components/PaperSpinner';
import { Route } from 'react-router-dom';
import { selectSpinnerLoading } from '../redux/store/ui/spinner';

const MainLayout = ({ children, ...restProps }) => {
  const classes = useStyles();
  const requestLoading = useSelector(selectSpinnerLoading);

  return (
    <div className={classes.root}>
      <PrimaryTopBar pathname={restProps?.location?.pathname} />
      <Route component={SideNav} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Route component={BreadcrumbNav} />
        <div className={classes.pageSpinner}>
          {requestLoading && <PaperSpinner />}
        </div>
        {children}
        {requestLoading && <PaperSpinner />}
      </main>
    </div>
  );
};

export default MainLayout;
