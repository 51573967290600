import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  targetSelector: {
    width: '100%',
    maxHeight: 'calc(100vh - 232px)',
  },
  inputWrapper: {
    padding: '16px',
  },
  targetOptions: {
    listStyle: 'none',
    padding: '8px',
    margin: 0,
    width: '100%',
    maxHeight: 'calc(100vh - 296px)',
    overflowY: 'scroll',
  },
  targetOption: {
    width: '100%',
    padding: '4px 16px 4px 20px',
    marginTop: '2px',
    marginBottom: '2px',
    fontSize: '16px',
    fontFamily: 'Poppins, sans-serif',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#54abdf',
      color: '#fff',
      borderRadius: '5px',
    },
  },
}));
