import {
  SET_CUSTOM_REPORTS_ITEMS,
  SET_CUSTOM_REPORTS_TOTAL_COUNT,
  SET_CUSTOM_REPORTS_PAGE,
  APPEND_CUSTOM_REPORTS_ITEMS,
  CLEAR_CUSTOM_REPORTS,
} from '../../../../constants/actionTypes';

export const setCustomReports = (data) => async (dispatch) => {
  dispatch({
    type: SET_CUSTOM_REPORTS_ITEMS,
    payload: data?.customReports?.items,
  });
  dispatch({
    type: SET_CUSTOM_REPORTS_TOTAL_COUNT,
    payload: data?.customReports?.totalCount,
  });
};

export const appendCustomReports = (data) => async (dispatch) => {
  dispatch({
    type: APPEND_CUSTOM_REPORTS_ITEMS,
    payload: data?.customReports?.items,
  });
  dispatch({
    type: SET_CUSTOM_REPORTS_TOTAL_COUNT,
    payload: data?.customReports?.totalCount,
  });
};

export const setCustomReportsPage = (page) => ({
  type: SET_CUSTOM_REPORTS_PAGE,
  payload: page,
});

export const clearCustomReports = () => ({
  type: CLEAR_CUSTOM_REPORTS,
});
