import React, { useEffect, useState } from 'react';
import { useStyles } from './GeoChartWidget.styles';
import { Chart } from 'react-google-charts';
import GraphContainer from './GraphContainer';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { List, ListItem } from '@material-ui/core';
import { Countries } from '../../utils/countries';
import numeral from 'numeral';
import { NUMBER_HUMANIZE_FORMAT } from '../../constants/enum';
import { calculateMetric } from './ReportsCalculations';
import Tooltip from '@material-ui/core/Tooltip';

const GeoChartWidget = ({
  widgetName,
  data,
  colors,
  tabs,
  selectedTab,
  order = 'DESC',
}) => {
  const classes = useStyles();
  let chartData = [];
  let sum = 0;
  let highestFiveSum = 0;
  data.map((element) => {
    sum += parseFloat(element[tabs[selectedTab]]);
    const contryDetails = Countries.filter((country) => {
      return country.code === element.country;
    });
    if (contryDetails?.length) {
      chartData.push([
        contryDetails[0].name,
        parseFloat(calculateMetric(element, tabs[selectedTab])),
      ]);
    } else {
      chartData.push([
        element.country,
        parseFloat(calculateMetric(element, tabs[selectedTab])),
      ]);
    }
  });
  chartData = chartData.sort((a, b) => {
    if (order === 'DESC') {
      return b[1] - a[1];
    } else {
      return a[1] - b[1];
    }
  });
  const needOthers = chartData.length > 5;
  if (chartData.length > 5) {
    chartData = chartData.slice(0, 5);
  }
  let percentages = [];
  let others = 100;
  chartData.map((element, index) => {
    const percent = numeral((100 * element[1]) / sum).format(
      NUMBER_HUMANIZE_FORMAT,
    );
    percentages.push(percent);
    others -= parseFloat(percent);
  });

  if (needOthers) {
    percentages.push(numeral(others).format(NUMBER_HUMANIZE_FORMAT));
  }
  return (
    <GraphContainer widgetName={widgetName}>
      <Chart
        chartType="GeoChart"
        data={[['Country', tabs[selectedTab]], ...chartData]}
        options={{
          displayMode: 'markers',
          colorAxis: { colors },
        }}
        width="100%"
        height={300}
        mapsApiKey={'AIzaSyC3TDKdJ7Qal1oVXn0Lg886xY4uejpDHtc'}
        legend={'none'}
      />
      <List style={{ height: 200, overflow: 'scroll' }}>
        {percentages.map((item, index) => (
          <ListItem
            key={`geo-listitem-${index}`}
            style={{ color: colors[index] }}
          >
            <FiberManualRecordIcon className={classes.icon} />
            <React.Fragment>
              <Tooltip
                title={
                  <div style={{ fontSize: '15px' }}>
                    {index < chartData.length ? chartData[index][0] : 'Others'}
                  </div>
                }
                aria-label={
                  index < chartData.length ? chartData[index][0] : 'Others'
                }
              >
                <span className={classes.label}>
                  {index < chartData.length ? chartData[index][0] : 'Others'}
                </span>
              </Tooltip>
              <span className={classes.percentage}>{item}%</span>
            </React.Fragment>
          </ListItem>
        ))}
      </List>
    </GraphContainer>
  );
};

export default GeoChartWidget;
