import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isPermittedToAccess } from '../utils/permissions';
import { selectCurrentUser } from '../redux/store/auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const currentUser = useSelector(selectCurrentUser);
  let hasRoleToAccess = isPermittedToAccess(rest.path, currentUser, 'role');
  let hasPermissionToAccess = isPermittedToAccess(rest.path, currentUser);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!currentUser || !currentUser?._id) {
          return <Redirect to={{ pathname: '/login' }} />;
        } else {
          // authorized so return component
          if (!hasRoleToAccess) {
            return <Redirect to={{ pathname: '/unauthorized' }} />;
          } else if (!hasPermissionToAccess) {
            return <Redirect to={{ pathname: '/unauthorized' }} />;
          } else {
            return <Component {...props} />;
          }
        }
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
};

export default PrivateRoute;
