import { gql } from '@apollo/client';

const FETCH_USERS = gql`
  query fetchUsers($filter: QueryFilter!) {
    users(queryFilter: $filter) {
      items {
        _id
        userStatus
        updatedAt
        firstName
        lastName
        email
        role {
          _id
          roleName
          permissions {
            permissionName
          }
        }
        isEnabled
      }
      page
      totalCount
    }
  }
`;

const LOOKUP_USERS = gql`
  query fetchUsers($filter: QueryFilter!) {
    users(queryFilter: $filter) {
      items {
        _id
        firstName
        lastName
        email
        role {
          _id
          roleName
          permissions {
            permissionName
          }
        }
        isEnabled
      }
      page
      totalCount
    }
  }
`;

const FETCH_USERS_BY_USER = gql`
  query usersByUser($userId: String!, $filter: QueryFilter!) {
    usersByUser(userId: $userId, queryFilter: $filter) {
      items {
        _id
        firstName
        lastName
        email
        role {
          _id
          roleName
          permissions {
            permissionName
          }
        }
      }
      page
      totalCount
    }
  }
`;

const FETCH_USER_BRANDS = gql`
  query fetchUserBrands {
    getUserBrands {
      _id
      brandName
      isEnabled
      managers {
        _id
        role {
          _id
          roleName
        }
      }
      users {
        _id
        role {
          _id
          roleName
        }
      }
      publishers {
        _id
      }
    }
  }
`;

const FETCH_USER_PAYMENT_METHODS = gql`
  query fetchUserPaymentMethods {
    getUserPaymentMethods {
      _id
      paymentMethods {
        method
        currency
        email
        isDefault
        benefeciaryName
        country
        IBAN
        SWIFT
        bankName
        accountNumber
        address
        crypto
        walletNumber
      }
    }
  }
`;

const FETCH_USER_BY_ID = gql`
  query fetchUser($id: String!) {
    user(id: $id) {
      _id
      firstName
      lastName
      phone
      email
      avatar
      publisher {
        _id
        publisherName
      }
      role {
        _id
        roleName
      }
      contactmanager {
        firstName
        lastName
        email
        phone
        role {
          roleName
        }
      }
      brands {
        _id
        brandName
      }
      isEnabled
      paymentMethods {
        method
        currency
        email
        isDefault
        benefeciaryName
        country
        IBAN
        SWIFT
        bankName
        address
      }
      userStatus
      statusHistory {
        status
        createdAt
      }
    }
  }
`;

export {
  FETCH_USERS,
  FETCH_USERS_BY_USER,
  FETCH_USER_BY_ID,
  FETCH_USER_BRANDS,
  FETCH_USER_PAYMENT_METHODS,
  LOOKUP_USERS,
};
