import { MENU_TOGGLE } from '../../../../constants/actionTypes';
import { initialState } from './InitialState';

export const sidenavReducer = (state = initialState, action) => {
  switch (action.type) {
    case MENU_TOGGLE:
      return { ...state, open: action.payload };
    default:
      return state;
  }
};
