import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';

const useNoLoaderGraphQuery = (props, fetched) => {
  const [callback, { loading, error, data }] = useLazyQuery(props, {
    fetchPolicy: 'no-cache',
    onError: () => {},
  });

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        if (fetched) {
          fetched(data);
        }
      }, 0);
    }
  }, [loading, error, data]);

  return [callback, { loading, error, data }];
};

export default useNoLoaderGraphQuery;
