import client from '../core/http/apiClient';

class AuthService {
  static instance;

  constructor() {
    if (!AuthService.instance) AuthService.instance = this;

    return AuthService.instance;
  }

  login = async (payload) => {
    try {
      const response = await client.post(`/user/login`, payload);
      this.setSession(response.data);
      return response.data;
    } catch (error) {
      throw error.response || error.message;
    }
  };

  impersonateUser = async (payload) => {
    try {
      const response = await client.post(`/user/get-token`, payload);
      this.setImpersonatedUserSession(response.data);
      window.location.reload();
      return response.data;
    } catch (error) {
      throw error.response || error.message;
    }
  };

  changePassword = async (payload) => {
    try {
      const response = await client.post(`/user/resetpassword`, payload);
      this.setSession(response.data);
      return response.data;
    } catch (error) {
      throw error.response || error.message;
    }
  };

  getSession = () => JSON.parse(localStorage.getItem('user'));
  getImpersonatedUserSession = () =>
    JSON.parse(localStorage.getItem('impersonatedUser'));
  setSession = (user) => localStorage.setItem('user', JSON.stringify(user));
  setImpersonatedUserSession = (user) =>
    localStorage.setItem('impersonatedUser', JSON.stringify(user));

  getToken = () => {
    const impersonatedUser = JSON.parse(
      localStorage.getItem('impersonatedUser'),
    );
    const user = JSON.parse(localStorage.getItem('user'));
    return impersonatedUser
      ? impersonatedUser.loginDates[user.loginDates.length - 1].token
      : user
      ? user.loginDates[user.loginDates.length - 1].token
      : null;
  };
  createSession = (payload) => {
    this.setSession(payload);
    window.location.reload();
    return payload;
  };
  removeToken = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('impersonatedUser');
    client.defaults.headers.common['Authorization'] = null;
  };
  removeImpersonatedToken = () => {
    localStorage.removeItem('impersonatedUser');
  };
  logout = async (payload) => {
    try {
      await client.post(`/user/logout`, { userId: payload._id });
      this.removeToken();
      return null;
    } catch (error) {
      throw error.response || error.message;
    }
  };

  endImpersonation = async (payload) => {
    try {
      await client.post(`/user/logout`, { userId: payload._id });
      this.removeImpersonatedToken();
      window.location.reload();
      return null;
    } catch (error) {
      throw error.response || error.message;
    }
  };
}

const instance = new AuthService();
Object.freeze(instance);

export default instance;
