import { gql } from '@apollo/client';

const CREATE_CUSTOM_REPORT = gql`
  mutation createCustomReport($customReport: CreateCustomReportInput!) {
    createCustomReport(createCustomReportInput: $customReport) {
      reportTitle
      _id
    }
  }
`;

const EDIT_CUSTOM_REPORT = gql`
  mutation editCustomReport($customReport: EditCustomReportInput!) {
    editCustomReport(editCustomReportInput: $customReport) {
      _id
    }
  }
`;

const DELETE_CUSTOM_REPORT = gql`
  mutation deleteCustomReport($id: String!) {
    deleteCustomReport(id: $id) {
      _id
      customReportStatus
    }
  }
`;

export { CREATE_CUSTOM_REPORT, EDIT_CUSTOM_REPORT, DELETE_CUSTOM_REPORT };
