import { gql } from '@apollo/client';

const PROCESS_EXTERNAL_REPORT_DATA = gql`
  mutation processExternalReportData(
    $externalReport: ExternalReportDataInput!
  ) {
    processExternalReportData(processExternalReportDataInput: $externalReport) {
      ok
    }
  }
`;

export { PROCESS_EXTERNAL_REPORT_DATA };
