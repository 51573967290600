import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../utils/history';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import MoreIcon from '@material-ui/icons/MoreVert';
import { ExpandMore } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import { Link } from 'react-router-dom';
import { isPermittedToAccess } from '../../utils/permissions';
import { useGraphLazyQuery } from '../../core/hooks';
import {
  FETCH_EXTERNAL_WIDGETS,
  LOOKUP_BRANDS,
  LOOKUP_PROVIDERS,
  LOOKUP_PUBLISHERS,
  LOOKUP_USERS,
  LOOKUP_WEBSITES,
  LOOKUP_WIDGETS,
} from '../../graphql/query';
import { QueryVariables } from '../../core/models';
import client from '../../core/http/apiClient';
import FullSearch from '../FullSearch';
import { useNoLoaderGraphQuery } from '../../core/hooks';
import {
  endImpersonation,
  logout,
  selectCurrentUser,
  selectOriginalUserID,
} from '../../redux/store/auth';
import {
  selectLookUpAdProviders,
  selectLookUpBrands,
  selectLookUpProviders,
  selectLookUpPublishers,
  selectLookUpWebsites,
  selectLookUpWidgets,
  setLookUpAdProviders,
  setLookUpBrands,
  setLookUpProviders,
  setLookUpPublishers,
  setLookUpWebsites,
  setLookUpWidgets,
  selectLookUpUsers,
  setLookUpUsers,
} from '../../redux/store/ui/lookUp';
import { addAndApplyFilter, clearFilters } from '../../redux/store/ui/filters';
import { getViewMode } from '../Filter/helpers/getTargets';
import { useLocation } from 'react-router-dom';
import { useStyles } from './PrimaryTopbar.styles';

const queryVariables = new QueryVariables();

const PrimaryTopBar = ({ pathname }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUser = useSelector(selectCurrentUser);
  const [selectorDetails, setSelectorDetails] = useState({});
  const lookUpBrands = useSelector(selectLookUpBrands);
  const lookUpWebsites = useSelector(selectLookUpWebsites);
  const lookUpProviders = useSelector(selectLookUpProviders);
  const lookUpWidgets = useSelector(selectLookUpWidgets);
  const lookUpAdProviders = useSelector(selectLookUpAdProviders);
  const lookUpPublishers = useSelector(selectLookUpPublishers);
  const lookUpAdPublishers = useSelector(selectLookUpPublishers);
  const lookUpUsers = useSelector(selectLookUpUsers);
  const originalUserId = useSelector(selectOriginalUserID);

  const [getWebsites] = useNoLoaderGraphQuery(LOOKUP_WEBSITES, (data) => {
    const { items } = data.websites;
    dispatch(setLookUpWebsites(items));
  });

  const [getProviders] = useNoLoaderGraphQuery(LOOKUP_PROVIDERS, (data) => {
    const { items } = data.providers;
    dispatch(setLookUpProviders(items));
  });

  const [getUsers] = useNoLoaderGraphQuery(LOOKUP_USERS, (data) => {
    const { items } = data.users;
    dispatch(setLookUpUsers(items));
  });

  const [getWidgets] = useNoLoaderGraphQuery(LOOKUP_WIDGETS, (data) => {
    const { items } = data.widgets;
    dispatch(setLookUpWidgets(items));
  });

  const [getAdProvider] = useGraphLazyQuery(FETCH_EXTERNAL_WIDGETS, (data) => {
    const items = data?.externalwidgets?.items;
    dispatch(setLookUpAdProviders(items));
  });

  const [getPublishers] = useNoLoaderGraphQuery(LOOKUP_PUBLISHERS, (data) => {
    const { items } = data.publishers;
    dispatch(setLookUpPublishers(items));
  });

  const [getBrands] = useNoLoaderGraphQuery(LOOKUP_BRANDS, (data) => {
    const { items } = data.brands;
    dispatch(setLookUpBrands(items));
  });

  const pagesWithTopSelector = {
    '/dashboard': {
      roles: {
        admin: {
          title: 'Brands',
          query: lookUpBrands?.length === 0 ? getBrands : () => {},
        },
        publisher: {
          title: 'Websites',
          query: lookUpWebsites?.length === 0 ? getWebsites : () => {},
        },
        manager: {
          title: 'Publishers',
          query: lookUpAdPublishers?.length === 0 ? getPublishers : () => {},
        },
        'brand manager': {
          title: 'Publishers',
          query: lookUpAdPublishers?.length === 0 ? getPublishers : () => {},
        },
      },
    },
    '/brands': {
      title: 'users',
      query: lookUpBrands?.length === 0 ? getBrands : () => {},
    },
    '/users': {
      title: 'users',
      query: lookUpUsers?.length === 0 ? getUsers : () => {},
    },
    '/websites': {
      title: 'publishers',
      query:
        lookUpWebsites?.length === 0
          ? (variables) => {
              getWebsites(variables);
              getPublishers(variables);
            }
          : () => {},
    },
    '/publishers': {
      title: 'brands',
      query:
        lookUpPublishers?.length === 0
          ? (variables) => {
              getPublishers(variables);
              getBrands(variables);
            }
          : () => {},
    },
    '/widgets': {
      title: 'widgets',
      query:
        lookUpWidgets?.length === 0
          ? (variables) => {
              getWidgets(variables);
              getWebsites(variables);
            }
          : () => {},
    },
    '/widgets/view': {
      title: 'widgets',
      query:
        lookUpWidgets?.length === 0
          ? (variables) => {
              getWidgets(variables);
            }
          : () => {},
    },
    '/providers': {
      title: 'providers',
      query: lookUpProviders?.length === 0 ? getProviders : () => {},
    },
    '/reports-by-provider/': {
      title: 'providers',
      query:
        lookUpProviders?.length === 0
          ? (variables) => {
              getProviders(variables);
              getWebsites(variables);
              getPublishers(variables);
              getBrands(variables);
            }
          : () => {},
    },
    '/reports-by-widget': {
      title: 'widgets',
      query:
        lookUpWidgets?.length === 0
          ? (variables) => {
              getWidgets(variables);
              getWebsites(variables);
            }
          : () => {},
    },
    '/reports-by-website': {
      title: 'websites',
      query:
        lookUpWebsites?.length === 0
          ? (variables) => {
              getWebsites(variables);
              getPublishers(variables);
            }
          : () => {},
    },
    '/custom-reports/view': {
      title: 'custom-report-view',
      query:
        lookUpWebsites?.length === 0
          ? (variables) => {
              getWebsites(variables);
              getPublishers(variables);
              getWidgets(variables);
              getBrands(variables);
              getProviders(variables);
            }
          : () => {},
    },
    '/reports-by-country': {
      title: 'Countries',
      query: lookUpWebsites?.length === 0 ? getWebsites : () => {},
    },
    '/reports-by-ad-provider': {
      title: 'Ad Providers',
      query: lookUpAdProviders?.length === 0 ? getAdProvider : () => {},
    },
    '/earnings': {
      title: 'Earnings',
      query:
        lookUpWebsites?.length === 0
          ? (variables) => {
              getWebsites(variables);
              getPublishers(variables);
              getBrands(variables);
            }
          : () => {},
    },
    '/setup': {
      roles: {
        admin: {
          title: 'Brands',
          query: lookUpBrands?.length === 0 ? getBrands : () => {},
          optionLabel: 'brandName',
          optionValue: '_id',
        },
      },
    },
  };

  const getSelectorDetails = (roleName) =>
    pagesWithTopSelector[pathname]?.hasOwnProperty('roles')
      ? pagesWithTopSelector[pathname].roles[roleName]
      : pagesWithTopSelector[pathname];

  // apply filter when page changes
  useEffect(() => {
    dispatch(clearFilters());
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.forEach((value, key) => {
      if (
        key !== 'from' &&
        key !== 'to' &&
        !location?.pathname.includes('add')
      ) {
        dispatch(
          addAndApplyFilter({
            location,
            viewMode: getViewMode(location),
            key,
            value,
          }),
        );
      }
    });
  }, [pathname]);

  useEffect(() => {
    setSelectorDetails(selectorDetails);
  }, [selectorDetails]);

  useEffect(() => {
    if (!currentUser._id) history.replace('/login');
    const selectorDetails =
      getSelectorDetails(currentUser?.role?.roleName) ||
      pathname.includes('/custom-reports/view')
        ? pagesWithTopSelector['/custom-reports/view']
        : pathname.includes('/widgets/view')
        ? pagesWithTopSelector['/widgets/view']
        : null;
    setSelectorDetails(selectorDetails);
    queryVariables.setLimit(4000);
    if (selectorDetails) {
      selectorDetails.query({ variables: queryVariables });
    }
  }, [currentUser, pathname]);

  const theme = useTheme();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [profileMenuEl, setProfileMenuEl] = useState(null);
  const profileMenuOpen = Boolean(profileMenuEl);

  const profileMenuClick = (event) => {
    setProfileMenuEl(event.currentTarget);
  };

  const profileMenuClose = () => {
    setProfileMenuEl(null);
  };

  const logoutClicked = () => {
    dispatch(logout(currentUser));
    profileMenuClose();
  };

  const endImpersonationClicked = () => {
    dispatch(endImpersonation(currentUser));
    profileMenuClose();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
    </Menu>
  );
  const [avatarImage, setAvatarImage] = useState('');

  const getS3DownloadURL = async (fileName) => {
    const response = await client.get(`/upload/getViewURL?key=${fileName}`);
    return response.data;
  };

  const setProfileImage = async (profileImage) => {
    let data = await getS3DownloadURL(profileImage);
    setAvatarImage(data.url);
  };

  useEffect(() => {
    if (currentUser.avatar) {
      setProfileImage(currentUser.avatar);
    }
  }, [currentUser.avatar]);

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={logoutClicked}>Logout</MenuItem>
      {isPermittedToAccess('/profile', currentUser) && (
        <MenuItem style={{ color: '#000000DD' }} onClick={profileMenuClose}>
          <Link
            to="/profile"
            style={{
              textDecoration: 'none',
              display: 'block',
              color: '#000000DD !important',
            }}
          >
            Profile
          </Link>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Box
            display="flex"
            flexDirection="row"
            flexGrow={1}
            justifyContent="space-between"
            alignContent="center"
          >
            <Box
              display="flex"
              flexDirection="row"
              // flexGrow={1}
              justifyContent="flex-start"
              alignItems="center"
            >
              <img
                className={classes.logo}
                src={window.location.origin + '/logo-top.svg'}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              ml={5}
              mr={2}
            ></Box>
            <Box
              display="flex"
              flexGrow={1}
              ml={10}
              mr={5}
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.fullSearch}
            >
              {isPermittedToAccess('FullSearch', currentUser) && <FullSearch />}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              //flexGrow={1}
              justifyContent="flex-end"
              alignItems="center"
            >
              <div className={classes.sectionDesktop}>
                <Button
                  className={classes.btnProfile}
                  color="inherit"
                  ref={profileMenuEl}
                  aria-controls={profileMenuOpen ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={profileMenuClick}
                >
                  <Avatar
                    alt={`${currentUser.firstName} ${currentUser.lastName}`}
                    src={avatarImage}
                    className={classes.small}
                  />
                  <span className={classes.userName}>
                    &nbsp;{`${currentUser.firstName} ${currentUser.lastName}`}{' '}
                  </span>
                  <ExpandMore />
                </Button>
                <Menu
                  id="fade-menu"
                  anchorEl={profileMenuEl}
                  keepMounted
                  open={profileMenuOpen}
                  onClose={profileMenuClose}
                  TransitionComponent={Fade}
                >
                  {isPermittedToAccess('/profile', currentUser) && (
                    <MenuItem onClick={profileMenuClose}>
                      <Link
                        to="/profile"
                        style={{
                          textDecoration: 'none',
                          display: 'block',
                          color: '#000000DD',
                        }}
                      >
                        Profile
                      </Link>
                    </MenuItem>
                  )}
                  {originalUserId && (
                    <MenuItem onClick={endImpersonationClicked}>
                      End impersonation
                    </MenuItem>
                  )}
                  <MenuItem onClick={logoutClicked}>Logout</MenuItem>
                </Menu>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
};

export default PrimaryTopBar;
