/* eslint-disable no-console */
import handleError from './errorHandler';
import authService from '../../services/auth.service';

/**
 * @description modify request header with authorization toekn
 * @param {*} request {HttpRequest}
 * @return {null}
 */
const tokenInterceptor = (request) => {
  const token = authService.getToken();
  if (token) {
    const authorization = `Bearer ${authService.getToken()}`;
    request.headers.Authorization = authorization;
  }

  return request;
};

/**
 * @description get response
 * @param {*} response {HttpResponse}
 * @return {null}
 */
const successInterceptor = (response) => {
  console.log(`Success Interceptor:: ${response}`);
};

/**
 * @description get error
 * @param {*} response {HttpResponse}
 * @return {null}
 */
const errorInterceptor = (error) => {
  handleError(error);
};

export { tokenInterceptor, successInterceptor, errorInterceptor };
