import { gql } from '@apollo/client';

const FETCH_WIDGETS = gql`
  query fetchWidgets($filter: QueryFilter!) {
    widgets(queryFilter: $filter) {
      items {
        _id
        updatedAt
        widgetName
        widgetId
        widgetType
        widgetStatus
        isEnabled
        lastSync
        adGroups {
          _id
        }
        website {
          _id
          displayName
          websiteDomain
        }
      }
      page
      totalCount
    }
  }
`;

const FETCH_WIDGETS_BY_USER = gql`
  query widgetsByUser($userId: String!, $filter: QueryFilter!) {
    widgetsByUser(userId: $userId, queryFilter: $filter) {
      items {
        _id
        widgetName
        widgetId
        widgetType
        widgetStatus
        isEnabled
        lastSync
        adGroups {
          _id
        }
        website {
          _id
          displayName
          websiteDomain
        }
      }
      page
      totalCount
    }
  }
`;

const FETCH_WIDGET_BY_ID = gql`
  query fetchwidget($id: String!) {
    widget(id: $id) {
      _id
      widgetName
      widgetType
      widgetStatus
      isEnabled
      rowNumber
      colNumber
      adGroups {
        _id
      }
      website {
        _id
        displayName
      }
      statusHistory {
        status
        createdAt
      }
    }
  }
`;

const FETCH_WIDGET_EXTERNALS = gql`
  query fetchWidgetExternals($filter: QueryFilter!) {
    widgetsExternal(queryFilter: $filter) {
      items {
        externalWidgets {
          externalWidgetId
          externalWidgetName
          geo
          device
          weight
        }
      }
      page
      totalCount
    }
  }
`;

const FETCH_WIDGET_AD_GROUPS = gql`
  query fetchAdGroups($id: String!) {
    adGroups(id: $id) {
      _id
      name
      priority
      type
      weight
      isEnabled
      configurations {
        externalWidgetName
        externalWidgetId
        geo
        device
        weight
      }
    }
  }
`;

const FETCH_AD_GROUPS = gql`
  query fetchAdGroups($userId: String!) {
    findAdGroups(userId: $userId) {
      _id
      widgetName
      adGroups {
        _id
        name
      }
    }
  }
`;

const WIDGET_AUTO_COMPLETE_VALUE = gql`
  query fetchWidget($id: String!) {
    autoCompleteValue: widget(id: $id) {
      _id
      name: widgetName
    }
  }
`;

export {
  FETCH_WIDGET_BY_ID,
  FETCH_WIDGETS,
  FETCH_WIDGETS_BY_USER,
  FETCH_WIDGET_EXTERNALS,
  FETCH_WIDGET_AD_GROUPS,
  FETCH_AD_GROUPS,
  WIDGET_AUTO_COMPLETE_VALUE,
};
