import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  input: {},
  autoComplete: {},
  group: {
    margin: '10px',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '16px',
  },
  option: {
    width: '100%',
    fontSize: '14px',
    paddingLeft: '5px',
  },
  icon: {
    color: '#848899',
  },
}));
