export const selectFilter = (state) => state.ui.filters;

export const selectIsFilterPanelOpen = (state) =>
  state.ui.filters.isFilterPanelOpen;

export const selectFilterTarget = (state) => state.ui.filters.target;

export const selectFilterTargetType = (state) => state.ui.filters.targetType;

export const selectFilterFor = (viewMode) => (state) =>
  state.ui.filters[viewMode];
