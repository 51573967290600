import { gql } from '@apollo/client';

const FETCH_AD_FORMATS = gql`
  query listAdFormats($providerId: String!) {
    listAdFormats(providerId: $providerId) {
      providerId
      providerName
      adFormatName
      regex
      parameters {
        parameterName
        parameterLabel
        parameterType
        parameterValue
      }
    }
  }
`;

export { FETCH_AD_FORMATS };
