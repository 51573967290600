import { gql } from '@apollo/client';

const FETCH_WEBSITES = gql`
  query fetchWebsites($filter: QueryFilter!) {
    websites(queryFilter: $filter) {
      items {
        _id
        updatedAt
        websiteDomain
        displayName
        websiteStatus
        websiteCategory
        websiteLanguage
        fetchReportingDataFromAPI
        webshot
        clicks
        impressions
        websiteRevenue
        lastSync
        publisher {
          _id
          publisherName
        }
        widgets {
          _id
        }
        externalWidgets {
          _id
          widgetStatus
          isEnabled
        }
        isEnabled
      }
      page
      totalCount
    }
  }
`;

const FETCH_WEBSITES_BY_USER = gql`
  query websitesByUser($userId: String!, $filter: QueryFilter!) {
    websitesByUser(userId: $userId, queryFilter: $filter) {
      items {
        _id
        websiteDomain
        displayName
        websiteStatus
        websiteCategory
        websiteLanguage
        fetchReportingDataFromAPI
        webshot
        clicks
        impressions
        websiteRevenue
        lastSync
        publisher {
          _id
          publisherName
        }
        widgets {
          _id
        }
        externalWidgets {
          _id
          widgetStatus
          isEnabled
        }
        isEnabled
      }
      page
      totalCount
    }
  }
`;

const WEBSITE_AUTO_COMPLETE_VALUE = gql`
  query fetchWebsite($id: String!) {
    autoCompleteValue: website(id: $id) {
      _id
      name: displayName
    }
  }
`;

const FETCH_WEBSITE_BY_ID = gql`
  query fetchWebsite($id: String!) {
    website(id: $id) {
      _id
      websiteDomain
      displayName
      websiteStatus
      websiteCategory
      websiteLanguage
      fetchReportingDataFromAPI
      isEnabled
      publisher {
        _id
        publisherName
      }
      users {
        _id
        firstName
        lastName
        role {
          _id
          roleName
        }
      }
      statusHistory {
        status
        createdAt
      }
    }
  }
`;

export {
  FETCH_WEBSITES,
  FETCH_WEBSITES_BY_USER,
  FETCH_WEBSITE_BY_ID,
  WEBSITE_AUTO_COMPLETE_VALUE,
};
