import React, { useEffect, useState } from 'react';
import numeral from 'numeral';
import { useStyles } from './NumberDisplayTileWidget.styles';
import { Card, CardContent } from '@material-ui/core';
import {
  NUMBER_HUMANIZE_FORMAT,
  SMALL_NUMBER_HUMANIZE_FORMAT,
} from '../../constants/enum';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

export const dashboardToolTips = {
  'Total Revenue': 'Net revenue generated from paid ads',
  Revenue: 'Net revenue generated from paid ads',
  Impressions: 'Total page views with paid ads',
  Clicks: 'Total clicks on paid content',
  CTR: 'Number of paid clicks divided by paid page views',
  eCPC: 'Revenue per thousand paid clicks',
  eCPM: 'Revenue per thousand page views',
};

const NumberDisplayTileWidget = ({
  label,
  value,
  index,
  focused,
  forceMinimalPrecision,
  type,
}) => {
  const [widgetValue, setWidgetValue] = useState(0);
  const classes = useStyles();
  const suffixes = { currency: '$' };
  const prefixes = { percentage: '%' };
  useEffect(() => {
    setWidgetValue(label === 'CTR' ? value * 100 : value);
  }, [value]);
  return (
    <Card raised={false} elevation={0} className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div>
          <img
            src={
              process.env.PUBLIC_URL +
              `/icons/calories-icon-${
                label === 'Total Revenue' ? 'Revenue' : label
              }.svg`
            }
            className={classes.widgetIcon}
          />
          <h1
            className={
              (focused ? classes.focused : '') + ' ' + classes.cardVaLue
            }
          >
            {suffixes[type] ? suffixes[type] : ''}
            {numeral(widgetValue || 0).format(
              forceMinimalPrecision || widgetValue >= 1
                ? NUMBER_HUMANIZE_FORMAT
                : SMALL_NUMBER_HUMANIZE_FORMAT,
            )}
            {prefixes[type] ? prefixes[type] : ''}
          </h1>
          <div
            className={
              (focused ? classes.focused : '') + ' ' + classes.cardLabel
            }
          >
            {label}
          </div>
        </div>
        <Tooltip
          title={
            <div style={{ fontSize: '14px' }}>{dashboardToolTips[label]}</div>
          }
        >
          <IconButton style={{ padding: '0px' }}>
            <InfoIcon />
          </IconButton>
        </Tooltip>

        {/*        <div className={classes.changeDetails}>
          <span
            className={
              variation > 0
                ? classes.rising
                : variation === 0
                ? classes.stable
                : classes.falling
            }
          >
            {' '}
            {variation > 0 ? (
              <ArrowDropUpIcon className={classes.changeIcon} />
            ) : variation < 0 ? (
              <ArrowDropDownIcon className={classes.changeIcon} />
            ) : (
              <RemoveIcon className={classes.changeIcon} />
            )}{' '}
            <span>{Math.abs(variation)}%</span>
          </span>{' '}
          <span> Since last month</span>
        </div>*/}
      </CardContent>
    </Card>
  );
};

export default NumberDisplayTileWidget;
