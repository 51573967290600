import { gql } from '@apollo/client';

const FETCH_SETTINGS = gql`
  query showSettings {
    showSettings {
      announcments {
        announcment
      }
      reportGlobalSettings {
        fetchInterval
        fetchDaySpan
      }
      adsText
      receiverEmail
    }
  }
`;

const FETCH_ADS_TXT = gql`
  query showAdsTxt {
    showAdsTxt {
      adsText
    }
  }
`;

const FETCH_ANNOUNCMENTS = gql`
  query shwowAnnouncments {
    shwowAnnouncments {
      announcment
    }
  }
`;

const SHOW_ANNOUNCMENTS = gql`
  query shouldViewAnnouncment {
    shouldViewAnnouncment
  }
`;

export { FETCH_SETTINGS, FETCH_ANNOUNCMENTS, SHOW_ANNOUNCMENTS, FETCH_ADS_TXT };
