import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStyles } from './DateRangePicker.styles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { DateRangePicker } from 'react-date-range';
import { useSelector } from 'react-redux';
import { selectSelectedCustomReport } from '../../redux/store/ui/common';
import { getDateRange } from './DatePickerHelpers';

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const DateRangePickerComponent = ({ dateRangeUpdated, startDate, endDate }) => {
  const selectedReport = useSelector(selectSelectedCustomReport);
  const dateFormat = 'DD MMM YYYY';
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const pickerRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const selectedReportDateRanges = getDateRange(selectedReport?.dateFilter);
  const initialStartDate = query.get('from')
    ? new Date(query.get('from'))
    : selectedReport
    ? selectedReportDateRanges.startDate
    : moment().subtract(2, 'd')._d;
  const initialEndDate = query.get('to')
    ? new Date(query.get('to'))
    : selectedReport
    ? selectedReportDateRanges.endDate
    : new Date();

  const [selectionRange, setSelectionRange] = useState({
    startDate: initialStartDate,
    endDate: initialEndDate,
    key: 'selection',
  });

  const applySelection = () => {
    if (dateRangeUpdated) {
      dateRangeUpdated({
        startDate: selectionRange.startDate,
        endDate: selectionRange.endDate,
      });
      const params = new URLSearchParams(location.search);
      params.set('from', moment(selectionRange.startDate).format(dateFormat));
      params.set('to', moment(selectionRange.endDate).format(dateFormat));
      history.push({ search: params.toString() });
    }
    togglePicker();
  };

  const resetSelection = () => {
    if (startDate && endDate) {
      setSelectionRange({
        startDate,
        endDate,
        key: 'selection',
      });
    } else {
      setSelectionRange({
        startDate: selectedReport
          ? selectedReportDateRanges.startDate
          : moment().startOf('month')._d,
        endDate: selectedReport ? selectedReportDateRanges.endDate : new Date(),
        key: 'selection',
      });
    }
    dateRangeUpdated({
      startDate: selectedReport
        ? selectedReportDateRanges.startDate
        : moment().startOf('month')._d,
      endDate: selectedReport ? selectedReportDateRanges.endDate : new Date(),
    });
    togglePicker();
  };

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    dateRangeUpdated({
      startDate: selectionRange.startDate,
      endDate: selectionRange.endDate,
    });
  }, []);

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [pickerRef]);

  useEffect(() => {
    setSelectionRange({
      startDate: initialStartDate,
      endDate: initialEndDate,
      key: 'selection',
    });
    dateRangeUpdated({
      startDate: query.get('from')
        ? new Date(query.get('from'))
        : selectedReport
        ? selectedReportDateRanges.startDate
        : initialStartDate,
      endDate: query.get('to')
        ? new Date(query.get('to'))
        : selectedReport
        ? selectedReportDateRanges.endDate
        : initialEndDate,
    });
  }, [startDate, endDate, selectedReport]);

  return (
    <div
      ref={pickerRef}
      className={classes.datePickerRoot}
      onAbort={() => {
        // console.log('abort');
      }}
      onBlur={() => {
        // console.log('blur');
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        className={classes.dateDisplay}
        onClick={() => {
          togglePicker();
        }}
      >
        <img
          src={process.env.PUBLIC_URL + `/icons/calendar.svg`}
          className={classes.calendarIcon}
        />
        {selectionRange.startDate &&
          ` ${moment(selectionRange.startDate).format(dateFormat)} - ${moment(
            selectionRange.endDate,
          ).format(dateFormat)}`}
      </Box>
      {showPicker && (
        <Box className={classes.datePicker}>
          <Grid container>
            <Grid item xs={12}>
              <DateRangePicker
                onChange={(item) => {
                  item.selection.endDate =
                    item.selection.endDate > new Date()
                      ? new Date()
                      : item.selection.endDate;
                  item.selection.startDate =
                    item.selection.startDate > new Date()
                      ? new Date()
                      : item.selection.startDate;
                  setSelectionRange(item.selection);
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                maxDate={new Date()}
                ranges={[selectionRange]}
                direction="horizontal"
                editableDateInputs
              />
            </Grid>

            <Grid container className={classes.actionBar}>
              <Grid item xs={9} />
              <Grid item xs={3}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Button onClick={resetSelection}>Reset</Button>
                  <Button
                    className={classes.applyButton}
                    color="primary"
                    onClick={applySelection}
                  >
                    Apply
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default DateRangePickerComponent;
