export const LOGIN = 'LOGIN';
export const IMPERSONATE = 'IMPERSONATE';
export const END_IMPERSONATION = 'END_IMPERSONATION';
export const CREATE_SESSION = 'CREATE_SESSION';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_PENDING = 'LOGOUT_PENDING';
export const LOGOUT_FULFILLED = 'LOGOUT_FULFILLED';
export const LOGOUT_REJECTED = 'LOGOUT_REJECTED';

export const CLEAR_USER = 'CLEAR_USER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_PENDING = 'CHANGE_PASSWORD_PENDING';
export const CHANGE_PASSWORD_FULFILLED = 'CHANGE_PASSWORD_FULFILLED';
export const CHANGE_PASSWORD_REJECTED = 'CHANGE_PASSWORD_REJECTED';

export const MENU_TOGGLE = 'MENU_TOGGLE';

export const SHOW_SNAKBAR = 'SHOW_SNAKBAR';
export const HIDE_SNAKBAR = 'HIDE_SNAKBAR';

export const SHOW_REQUEST_SPINNER = 'SHOW_REQUEST_SPINNER';
export const HIDE_REQUEST_SPINNER = 'HIDE_REQUEST_SPINNER';

export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_ROLES_PENDING = 'FETCH_ROLES_PENDING';
export const FETCH_ROLES_FULFILLED = 'FETCH_ROLES_FULFILLED';
export const FETCH_ROLES_REJECTED = 'FETCH_ROLES_REJECTED';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_PENDING = 'UPDATE_PROFILE_PENDING';
export const UPDATE_PROFILE_FULFILLED = 'UPDATE_PROFILE_FULFILLED';
export const UPDATE_PROFILE_REJECTED = 'UPDATE_PROFILE_REJECTED';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_PENDING = 'CREATE_USER_PENDING';
export const CREATE_USER_FULFILLED = 'CREATE_USER_FULFILLED';
export const CREATE_USER_REJECTED = 'CREATE_USER_REJECTED';

export const SHOW_SELECT_USER_MODAL = 'SHOW_SELECT_USER_MODAL';
export const USER_SELECTED = 'USER_SELECTED';

export const SHOW_PROVIDER_MODAL = 'SHOW_PROVIDER_MODAL';
export const HIDE_PROVIDER_MODAL = 'HIDE_PROVIDER_MODAL';
export const WIDGET_PROVIDER_ADDED = 'WIDGET_PROVIDER_ADDED';

export const SHOW_PUBLISHER_USER_MODAL = 'SHOW_PUBLISHER_USER_MODAL';
export const HIDE_PUBLISHER_USER_MODAL = 'HIDE_PUBLISHER_USER_MODAL';
export const PUBLISHER_USER_ADDED = 'PUBLISHER_USER_ADDED';

export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_SEARCH_PARAM = 'SET_SEARCH_PARAM';

export const SET_DIRECT_PARENT = 'SET_DIRECT_PARENT';
export const SET_LAST_NODE = 'SET_LAST_NODE';

export const SET_CREATE_MODAL_IS_OPENED = 'SET_CREATE_MODAL_IS_OPENED';
export const SET_EDIT_MODAL_IS_OPENED = 'SET_EDIT_MODAL_IS_OPENED';
export const SET_PROPERTY_ID = 'SET_PROPERTY_ID';
export const SET_CREATED_USER = 'SET_CREATED_USER';

export const SET_TOP_SELECTOR_VALUE = 'SET_TOP_SELECTOR_VALUE';
export const SET_HIGHLIGHT_TOP_SELECTOR = 'SET_HIGHLIGHT_TOP_SELECTOR';
export const SET_DATE_RANGE_VALUE = 'SET_DATE_RANGE__VALUE';
export const SET_FILTER_NAME = 'SET_FILTER_NAME';

export const SET_WEBSITES_ITEMS = 'SET_WEBSITES_ITEMS';
export const APPEND_WEBSITES_ITEMS = 'APPEND_WEBSITES_ITEMS';
export const SET_WEBSITES_PAGE = 'SET_WEBSITES_PAGE';
export const SET_WEBSITES_TOTAL_COUNT = 'SET_WEBSITES_TOTAL_COUNT';
export const CLEAR_WEBSITES = 'CLEAR_WEBSITES';

export const SET_WIDGETS_ITEMS = 'SET_WIDGETS_ITEMS';
export const APPEND_WIDGETS_ITEMS = 'APPEND_WIDGETS_ITEMS';
export const SET_WIDGETS_PAGE = 'SET_WIDGETS_PAGE';
export const SET_WIDGETS_TOTAL_COUNT = 'SET_WIDGETS_TOTAL_COUNT';
export const CLEAT_WIDGETS = 'CLEAT_WIDGETS';

export const SET_PROVIDERS_ITEMS = 'SET_PROVIDERS_ITEMS';
export const APPEND_PROVIDERS_ITEMS = 'APPEND_PROVIDERS_ITEMS';
export const SET_PROVIDERS_PAGE = 'SET_PROVIDERS_PAGE';
export const SET_PROVIDERS_TOTAL_COUNT = 'SET_PROVIDERS_TOTAL_COUNT';
export const CLEAR_PROVIDERS = 'CLEAR_PROVIDERS';

export const SET_PUBLISHERS_ITEMS = 'SET_PUBLISHERS_ITEMS';
export const APPEND_PUBLISHERS_ITEMS = 'APPEND_PUBLISHERS_ITEMS';
export const SET_PUBLISHERS_PAGE = 'SET_PUBLISHERS_PAGE';
export const SET_PUBLISHERS_TOTAL_COUNT = 'SET_PUBLISHERS_TOTAL_COUNT';
export const CLEAR_PUBLISHERS = 'CLEAR_PUBLISHERS';

export const SET_DEDUCTIONS_ITEMS = 'SET_DEDUCTIONS_ITEMS';
export const APPEND_DEDUCTIONS_ITEMS = 'APPEND_DEDUCTIONS_ITEMS';
export const SET_DEDUCTIONS_PAGE = 'SET_DEDUCTIONS_PAGE';
export const SET_DEDUCTIONS_TOTAL_COUNT = 'SET_DEDUCTIONS_TOTAL_COUNT';
export const CLEAR_DEDUCTIONS = 'CLEAR_DEDUCTIONS';

export const SET_BRANDS_ITEMS = 'SET_BRANDS_ITEMS';
export const APPEND_BRANDS_ITEMS = 'APPEND_BRANDS_ITEMS';
export const SET_BRANDS_PAGE = 'SET_BRANDS_PAGE';
export const SET_BRANDS_TOTAL_COUNT = 'SET_BRANDS_TOTAL_COUNT';
export const CLEAR_BRANDS = 'CLEAR_BRANDS';

export const SET_USERS_ITEMS = 'SET_USERS_ITEMS';
export const APPEND_USERS_ITEMS = 'APPEND_USERS_ITEMS';
export const SET_USERS_PAGE = 'SET_USERS_PAGE';
export const SET_USERS_TOTAL_COUNT = 'SET_USERS_TOTAL_COUNT';
export const CLEAR_USERS = 'CLEAR_USERS';

export const SET_WEBSITES_LOOKUP_ITEMS = 'SET_WEBSITES_LOOKUP_ITEMS';
export const APPEND_WEBSITES_LOOKUP_ITEMS = 'APPEND_WEBSITES_LOOKUP_ITEMS';

export const SET_WIDGETS_LOOKUP_ITEMS = 'SET_WIDGETS_LOOKUP_ITEMS';
export const APPEND_WIDGETS_LOOKUP_ITEMS = 'APPEND_WIDGETS_LOOKUP_ITEMS';

export const SET_PROVIDERS_LOOKUP_ITEMS = 'SET_PROVIDERS_LOOKUP_ITEMS';
export const APPEND_PROVIDERS_LOOKUP_ITEMS = 'APPEND_PROVIDERS_LOOKUP_ITEMS';

export const SET_PUBLISHERS_LOOKUP_ITEMS = 'SET_PUBLISHERS_LOOKUP_ITEMS';
export const APPEND_PUBLISHERS_LOOKUP_ITEMS = 'APPEND_PUBLISHERS_LOOKUP_ITEMS';

export const SET_BRANDS_LOOKUP_ITEMS = 'SET_BRANDS_LOOKUP_ITEMS';
export const APPEND_BRANDS_LOOKUP_ITEMS = 'APPEND_BRANDS_LOOKUP_ITEMS';

export const SET_USERS_LOOKUP_ITEMS = 'SET_USERS_LOOKUP_ITEMS';
export const APPEND_USERS_LOOKUP_ITEMS = 'APPEND_USERS_LOOKUP_ITEMS';

export const CLEAR_LOOK_UPS = 'CLEAR_LOOK_UPS';

export const SET_AD_PROVIDERS_LOOKUP_ITEMS = 'SET_AD_PROVIDERS_LOOKUP_ITEMS';

export const SET_WEBSITES_VIEW_MODE = 'SET_WEBSITES_VIEW_MODE';
export const SET_WIDGETS_VIEW_MODE = 'SET_WIDGETS_VIEW_MODE';
export const SET_SELECTED_CUSTOM_REPORT = 'SET_SELECTED_CUSTOM_REPORT';
export const SET_CUSTOM_REPORT_TABLE_CHART_PAGE =
  'SET_CUSTOM_REPORT_TABLE_CHART_PAGE';

export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const SET_TARGET = 'SET_TARGET';
export const OPEN_FILTER_PANEL = 'OPEN_FILTER_PANEL';
export const CLOSE_FILTER_PANEL = 'CLOSE_FILTER_PANEL';
export const SET_APPLIED_FILTERS_HEIGHT = 'SET_APPLIED_FILTERS_HEIGHT';

export const SET_CUSTOM_REPORTS_ITEMS = 'SET_CUSTOM_REPORTS_ITEMS';
export const SET_CUSTOM_REPORTS_TOTAL_COUNT = 'SET_CUSTOM_REPORTS_TOTAL_COUNT';
export const SET_CUSTOM_REPORTS_PAGE = 'SET_CUSTOM_REPORTS_PAGE';
export const APPEND_CUSTOM_REPORTS_ITEMS = 'APPEND_CUSTOM_REPORTS_ITEMS';
export const CLEAR_CUSTOM_REPORTS = 'CLEAR_CUSTOM_REPORTS';
