import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { store } from './redux/config';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './components/ErrorBoundary';

const container = document.getElementById('root');
const root = createRoot(container);

Sentry.init({
  dsn:
    process.env.REACT_APP_ENV === 'production' ||
    process.env.REACT_APP_ENV === 'staging'
      ? 'https://4c98236fe0924df1bfb864b0f0e365da@o498138.ingest.sentry.io/5575300'
      : '',
  release: `ketshwa-ui-v${process.env.REACT_APP_VERSION}`,
  environment:
    process.env.REACT_APP_ENV === 'production' ? 'production' : 'staging',
});

root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>,
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
