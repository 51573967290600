import { gql } from '@apollo/client';

const CREATE_USER = gql`
  mutation createUser($user: CreateUserInput!) {
    addUser(addUserPostData: $user) {
      _id
    }
  }
`;

const UPDATE_USER = gql`
  mutation modifyUser($user: UpdateUserInput!) {
    updateUser(updateUserPostData: $user) {
      _id
      firstName
      lastName
      phone
      email
      isEnabled
      avatar
    }
  }
`;

const UPDATE_PASSWORD = gql`
  mutation modifyPassword($credentials: ChangePasswordInput!) {
    changePassword(changePasswordInput: $credentials) {
      ok
    }
  }
`;

const ADMIN_UPDATE_PASSWORD = gql`
  mutation modifyPassword($credentials: AdminChangePasswordInput!) {
    adminChangePassword(adminChangePasswordInput: $credentials) {
      ok
    }
  }
`;

const UPDATE_USER_BULK_ENABLE_DISABLE = gql`
  mutation updateBulkEnableDisable($user: EnableDisableBulkInput!) {
    enableDisableUsers(enableDisableBulkInput: $user) {
      ok
      Ids
    }
  }
`;

const SEND_FORGOT_PASSWORD_EMAIL = gql`
  mutation sendForgotPasswordEmail($email: String!) {
    forgotPasswordEmail(email: $email) {
      token
      expire
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation changePassword($credentials: ResetPasswordInput!) {
    resetPassword(resetPasswordInput: $credentials) {
      ok
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUser($id: String!) {
    deleteUser(id: $id) {
      _id
      userStatus
    }
  }
`;

export {
  CREATE_USER,
  UPDATE_USER,
  UPDATE_PASSWORD,
  CHANGE_PASSWORD,
  UPDATE_USER_BULK_ENABLE_DISABLE,
  SEND_FORGOT_PASSWORD_EMAIL,
  ADMIN_UPDATE_PASSWORD,
  DELETE_USER,
};
