export const selectLookUpBrands = (state) => state.ui.lookUp.brands;

export const selectLookUpWebsites = (state) => state.ui.lookUp.websites;

export const selectLookUpProviders = (state) => state.ui.lookUp.providers;

export const selectLookUpWidgets = (state) => state.ui.lookUp.widgets;

export const selectLookUpAdProviders = (state) => state.ui.lookUp.adProviders;

export const selectLookUpPublishers = (state) => state.ui.lookUp.publishers;

export const selectLookUpUsers = (state) => state.ui.lookUp.users;
