import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  filterPanel: {
    maxHeight: 'calc(100vh - 232px)',
    background: '#f7f7f7',
    boxShadow: '0px 0px 2px 2px rgb(132 136 153 / 25%)',
    borderRadius: '5px',
  },
}));
