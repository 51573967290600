import React from 'react';
import { useGraphMutation } from '../../core/hooks';
import { useStyles } from './ResetPassword.styles';
import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ValidationMessage from '../../validation/message/ValidationMessage';
import {
  ResetPasswordModel,
  ResetPasswordSchema,
} from '../../validation/schema/auth.schema';
import { CHANGE_PASSWORD } from '../../graphql/mutation';
import { history, queryParamFilter } from '../../utils/history';

const ResetPassword = ({ location, match }) => {
  const classes = useStyles();
  const { params } = queryParamFilter(location);
  const [resetPassword] = useGraphMutation(CHANGE_PASSWORD, (data) => {
    history.push('/login');
  });

  const resetPasswordFormik = useFormik({
    initialValues: { ...ResetPasswordModel, token: params.token || '' },
    validationSchema: ResetPasswordSchema,
    onSubmit: (values) => {
      const payload = { ...values };
      delete payload.repassword;
      resetPassword({ variables: { credentials: payload } });
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {location.pathname === '/set-password'
            ? 'Set Password'
            : 'Reset Password'}
        </Typography>
        <form
          className={classes.form}
          onSubmit={resetPasswordFormik.handleSubmit}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            autoFocus
            type="password"
            name="password"
            {...resetPasswordFormik.getFieldProps('password')}
          />
          <ValidationMessage
            formik={resetPasswordFormik}
            className="message"
            name="password"
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="repassword"
            label="Re-Password"
            type="password"
            name="repassword"
            {...resetPasswordFormik.getFieldProps('repassword')}
          />
          <ValidationMessage formik={resetPasswordFormik} name="repassword" />
          <Button
            type="submit"
            fullWidth
            color="primary"
            className={classes.submit}
            disabled={!resetPasswordFormik.isValid}
          >
            {location.pathname === '/set-password' ? 'Set' : 'Reset'}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/login" variant="body2">
                Login another account
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default ResetPassword;
