import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';
import { dashboardToolTips } from '../../pages/Dashboard/NumberDisplayTileWidget';
import { useStyles } from './GridXHead.styles';

const StyledBTableSortLabel = withStyles((theme) => ({
  root: {
    color: 'white',
    '&:hover': {
      color: 'white',
    },
    '&$active': {
      color: 'white',
    },
  },
  active: {},
  icon: {
    color: 'inherit !important',
  },
}))(TableSortLabel);

const GridXHead = ({
  headCells,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  showTooltip = true,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const classes = useStyles();

  return (
    <TableHead className={classes.tableHead}>
      <TableRow className={classes.tableRow}>
        {onSelectAllClick && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
        )}
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableCell}
            style={index === headCells.length - 1 ? { width: '200px' } : {}}
          >
            <StyledBTableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className={classes.tableCell}
            >
              {headCell.label}
              {dashboardToolTips[headCell.label] && showTooltip && (
                <Tooltip
                  title={
                    <div style={{ fontSize: '14px' }}>
                      {dashboardToolTips[headCell.label]}
                    </div>
                  }
                >
                  <IconButton style={{ padding: '0px', marginLeft: '2px' }}>
                    <InfoIcon style={{ width: '16px', height: '16px' }} />
                  </IconButton>
                </Tooltip>
              )}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </StyledBTableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default GridXHead;
