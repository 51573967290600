import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  padded: {
    padding: theme.spacing(2),
  },
  button: {
    margin: '1rem 0.5rem 0 0',
  },
  textArea: {
    height: theme.spacing(0),
    position: 'absolute',
    top: 0,
    zIndex: -9,
  },
  copyButton: {
    fontWeight: 400,
    height: '38px',
    width: '100px',
    borderRadius: '8px',
    fontSize: '14px',
    fontFamily: 'Poppins, sans-serif',
    padding: 0,
    margin: '15px',
  },
  cancelButton: {
    color: '#74798C',
  },
}));
