import React from 'react';
import { TextField, Typography, Badge } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { useStyles } from './SelectBox.styles';

const Selectbox = ({
  id,
  options,
  optionLabel,
  optionValue,
  label,
  value,
  onChange,
  onBlur,
  multiple = false,
  noOptionsText = 'No options',
  ...rest
}) => {
  const classes = useStyles();
  const enabledOptions =
    options && options[0]?.isEnabled !== undefined
      ? options?.filter((option) => option)
      : options;
  const SelecboxChange = (e, value) => {
    return onChange(
      rest.name,
      multiple && value ? value : value ? value[optionValue] : '',
    );
  };
  const SelecboxBlur = () => (rest.onBlur ? onBlur(rest.name, true) : null);
  const getOptions = () => {
    return typeof enabledOptions !== 'undefined' && enabledOptions;
  };
  let selectedOption;
  if (enabledOptions && enabledOptions.length > 0 && value && optionValue) {
    selectedOption = enabledOptions?.filter(
      (option) => option[optionValue] === value,
    )[0];
  }
  return (
    <div>
      {getOptions() && !selectedOption && (
        <Autocomplete
          id={`Selectbox-${rest.name}`}
          options={enabledOptions}
          value={value}
          getOptionLabel={(option) => {
            if (label === 'Owner User' && option['firstName'])
              return `${option['firstName']} ${option['lastName']}`;
            if (option[optionLabel]) return option[optionLabel];
            return '';
          }}
          renderOption={(option) => (
            <div
              id={`Selectbox-option-${option[optionLabel]}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '90%',
              }}
            >
              <Typography
                id={`Selectbox-Typography-${option[optionLabel]}`}
                variant="body1"
                gutterBottom
                key={option._id}
                value={optionValue ? option[optionValue] : option}
                color={
                  option.isEnabled !== undefined && !option.isEnabled
                    ? 'textSecondary'
                    : 'initial'
                }
              >
                {label === 'Owner User'
                  ? `${option['firstName']} ${option['lastName']}`
                  : option[optionLabel]}
              </Typography>
              {options[0]?.isEnabled !== undefined && (
                <Badge
                  style={{ marginLeft: '20px' }}
                  color={
                    option.isEnabled !== undefined && !option.isEnabled
                      ? 'error'
                      : 'primary'
                  }
                  badgeContent={
                    option.isEnabled !== undefined && !option.isEnabled
                      ? '  Disabled'
                      : '  Enabled'
                  }
                />
              )}
            </div>
          )}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          multiple={multiple}
          onBlur={SelecboxBlur}
          onChange={SelecboxChange}
          noOptionsText={noOptionsText}
          popupIcon={
            <img
              src={process.env.PUBLIC_URL + `/icons/arrow-down.svg`}
              className={classes.downArrow}
            />
          }
          {...rest}
        />
      )}
      {getOptions() && selectedOption && (
        <Autocomplete
          id={`combo-box-demo-${rest.name}`}
          options={enabledOptions}
          getOptionLabel={(option) => {
            if (label === 'Owner User' && option['firstName'])
              return `${option['firstName']} ${option['lastName']}`;
            if (option[optionLabel]) return option[optionLabel];
            return '';
          }}
          value={selectedOption}
          renderOption={(option) => (
            <div
              id={`Selectbox-option-${option[optionLabel]}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '90%',
              }}
            >
              <Typography
                id={`Selectbox-Typography-${option[optionLabel]}`}
                variant="body1"
                gutterBottom
                key={option._id}
                value={optionValue ? option[optionValue] : option}
                color={
                  option.isEnabled !== undefined && !option.isEnabled
                    ? 'textSecondary'
                    : 'initial'
                }
              >
                {label === 'Owner User'
                  ? `${option['firstName']} ${option['lastName']}`
                  : option[optionLabel]}
              </Typography>
              {options[0]?.isEnabled !== undefined && (
                <Badge
                  style={{ marginLeft: '20px' }}
                  color={
                    option.isEnabled !== undefined && !option.isEnabled
                      ? 'error'
                      : 'primary'
                  }
                  badgeContent={
                    option.isEnabled !== undefined && !option.isEnabled
                      ? '  Disabled'
                      : '  Enabled'
                  }
                />
              )}
            </div>
          )}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          multiple={multiple}
          onBlur={SelecboxBlur}
          onChange={SelecboxChange}
          noOptionsText={noOptionsText}
          popupIcon={
            <img
              src={process.env.PUBLIC_URL + `/icons/arrow-down.svg`}
              className={classes.downArrow}
            />
          }
          {...rest}
        />
      )}
    </div>
  );
};

Selectbox.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array,
  noOptionsText: PropTypes.string,
};

export default Selectbox;
