import React from 'react';
import { useStyles } from './GraphContainer.styles';
import { Card, CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const GraphContainer = ({ widgetName, children }) => {
  const classes = useStyles();

  // const handleRefreshClick = () => {
  //   refreshClicked ? refreshClicked() : {};
  // };
  //
  // const handleChartTypeChange = (val) => {
  //   chartTypeChanged ? chartTypeChanged(val) : {};
  // };
  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContainer}>
        <Grid container>
          <Grid item xs={10}>
            <h1 className={classes.chartTitle}>{widgetName}</h1>
            {/*            <span className={classes.chartNote}>
              Last updated 5 minutes ago
            </span>*/}
          </Grid>
          {/*          <Grid item xs={2} align="right">
            <CachedIcon
              className={classes.refreshIcon}
              onClick={handleRefreshClick}
            />
          </Grid>*/}
        </Grid>
        <div>{children}</div>
      </CardContent>
    </Card>
  );
};

export default GraphContainer;
