import { createBrowserHistory } from 'history';
import * as QueryString from 'query-string';
import {
  FILTER_OPERATION_EQUALS,
  FILTER_OPERATION_IN,
  FILTER_FIELD_TYPE_ID,
} from '../constants/enum';
import { AggregationFilter } from '../core/models';

export const history = createBrowserHistory();
export const parseQueryParam = (query) => QueryString.parse(query?.search);

export const queryParamFilter = (query) => {
  const params = QueryString.parse(query?.search);
  let filter = [];
  for (const key in params) {
    if (
      [
        'publisherId',
        'websiteId',
        'brandId',
        'userId',
        'widgetId',
        'providerId',
      ].includes(key)
    ) {
      const element = params[key];
      filter.push(
        new AggregationFilter().deserialize({
          fieldType: key,
          fieldValue: element,
          operator: FILTER_OPERATION_EQUALS,
        }),
      );
    }
  }

  return { params, filter, qstring: query?.search };
};

export const urlParamFilter = (match) => {
  const params = match?.params;
  return params?.id;
};
