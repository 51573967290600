export const initialState = {
  currentUser: {
    _id: null,
    phone: null,
    email: null,
    firstName: null,
    lastName: null,
    role: [],
    managedBy: null,
    isDeleted: false,
    avatar: '',
    brandId: null,
  },
  originalUser: {
    _id: null,
    phone: null,
    email: null,
    firstName: null,
    lastName: null,
    role: [],
    managedBy: null,
    isDeleted: false,
    avatar: '',
    brandId: null,
  },
  error: {},
  loading: false,
};
