import { gql } from '@apollo/client';

const CREATE_EXTERNAL_WIDGET = gql`
  mutation createExternalWidget($widget: AddExternalWidgetInput!) {
    addExternalWidget(addExternalWidgetInput: $widget) {
      _id
    }
  }
`;

const UPDATE_EXTERNAL_WIDGET = gql`
  mutation modifyExternalWidget($widget: EditExternalWidgetInput!) {
    editExternalWidget(editExternalWidgetInput: $widget) {
      _id
    }
  }
`;

const UPDATE_EXTERNAL_WIDGET_BULK_ENABLE_DISABLE = gql`
  mutation updateBulkEnableDisable($widget: EnableDisableBulkInput!) {
    enableDisableExternalWidgets(enableDisableBulkInput: $widget) {
      ok
      Ids
    }
  }
`;

export {
  CREATE_EXTERNAL_WIDGET,
  UPDATE_EXTERNAL_WIDGET,
  UPDATE_EXTERNAL_WIDGET_BULK_ENABLE_DISABLE,
};
