import {
  LOGIN_PENDING,
  LOGIN_FULFILLED,
  LOGIN_REJECTED,
  UPDATE_PROFILE_FULFILLED,
  CHANGE_PASSWORD_FULFILLED,
  LOGOUT_PENDING,
  LOGOUT_FULFILLED,
  LOGOUT_REJECTED,
  CLEAR_USER,
} from '../../../constants/actionTypes';
import authService from '../../../services/auth.service';
import { initialState } from './InitialState';

const currentUser = authService.getSession();
const impersonatedUser = authService.getImpersonatedUserSession();

if (currentUser && impersonatedUser) {
  initialState.currentUser = { ...impersonatedUser };
  initialState.originalUser = { ...currentUser };
} else {
  if (currentUser) {
    initialState.currentUser = { ...currentUser };
  }
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_PENDING:
    case LOGOUT_PENDING:
      return { ...state, loading: true };
    case LOGIN_FULFILLED:
    case UPDATE_PROFILE_FULFILLED:
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.payload },
        loading: false,
      };
    case LOGIN_REJECTED:
    case LOGOUT_REJECTED:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_PASSWORD_FULFILLED:
    case LOGOUT_FULFILLED:
    case CLEAR_USER:
      return {
        ...state,
        currentUser: {
          _id: null,
          phone: null,
          email: null,
          firstName: null,
          lastName: null,
          role: [],
          managedBy: null,
          isDeleted: false,
          avatar: '',
          brandId: null,
        },
        originalUser: {
          _id: null,
          phone: null,
          email: null,
          firstName: null,
          lastName: null,
          role: [],
          managedBy: null,
          isDeleted: false,
          avatar: '',
          brandId: null,
        },
        error: {},
        loading: false,
      };
    default:
      return state;
  }
};
