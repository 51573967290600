import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

export const useStyles = makeStyles((theme) => ({
  cardLabel: {
    padding: '0px',
    textSize: '16px',
    color: '#74798C',
    marginLeft: '45px',
  },
  cardVaLue: {
    color: '#282D30',
    textAlign: 'left',
    fontSize: '16px',
    margin: '0px',
    marginLeft: '45px',
  },
  rising: {
    color: green.A400,
    position: 'relative',
    paddingLeft: '23px',
  },
  falling: {
    color: red.A400,
    position: 'relative',
    paddingLeft: '23px',
  },
  stable: {
    color: grey.A200,
    position: 'relative',
    paddingLeft: '23px',
  },
  changeDetails: {
    height: '28px',
    lineHeight: '35px',

    position: 'relative',
  },
  changeIcon: {
    position: 'absolute',
    left: '0px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  focused: {},
  moneyIcon: {
    position: 'absolute',
    right: '2%',
    top: '6px',
    fontSize: '64px',
    zIndex: '999',
  },
  card: {
    background: '#F9F9F9',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: `${theme.spacing(0.75)}px !important`,
    paddingRight: `${theme.spacing(0.75)}px !important`,
    paddingTop: `${theme.spacing(1.65)}px !important`,
    paddingBottom: `${theme.spacing(1.65)}px !important`,
  },
  widgetIcon: {
    float: 'left',
    width: '40px',
  },
}));
