import * as yup from 'yup';

export const UserAuthSchema = yup.object({
  email: yup.string().required().min(3),
  password: yup.string().required().min(3),
});

export const ResetPasswordSchema = yup.object({
  password: yup
    .string()
    .required()
    .matches(
      process.env.REACT_APP_ENABLE_STRONG_PASSWORD === 'true'
        ? /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
        : '',
      'Must Contain 8 Characters, One Uppercase, Lowercase, Number and special case character',
    ),
  repassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], 'password must be matched'),
});

export const ChangePasswordSchema = yup.object({
  currentPassword: yup.string().required(),
  password: yup
    .string()
    .required()
    .min(3)
    .matches(
      process.env.REACT_APP_ENABLE_STRONG_PASSWORD === 'true'
        ? /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
        : '',
      'Must Contain 8 Characters, One Uppercase, Lowercase, Number and special case character',
    ),
  repassword: yup
    .string()
    .required()
    .min(3)
    .oneOf([yup.ref('password'), null], 'password must be matched'),
});

export const UserAuthModel = {
  email: '',
  password: '',
};

export const ResetPasswordModel = {
  password: '',
  repassword: '',
};

export const ChangePasswordModel = {
  _id: '',
  currentPassword: '',
  password: '',
  repassword: '',
};
