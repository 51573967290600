import {
  SHOW_REQUEST_SPINNER,
  HIDE_REQUEST_SPINNER,
} from '../../../../constants/actionTypes';
import { initialState } from './InitialState';

export const spinnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_REQUEST_SPINNER:
      return { ...state, loading: true };
    case HIDE_REQUEST_SPINNER:
      return { ...state, loading: false };
    default:
      return state;
  }
};
