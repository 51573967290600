import { gql } from '@apollo/client';

const FETCH_STATUS_HISTORY = gql`
  query statusHistory($id: String!, $entType: String!) {
    statusHistory(id: $id, entType: $entType) {
      status
      createdAt
      user {
        _id
        email
      }
    }
  }
`;

export { FETCH_STATUS_HISTORY };
