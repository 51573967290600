import { gql } from '@apollo/client';

const FETCH_EXTERNAL_REPORTS = gql`
  query fetchExternalReports($filter: QueryFilter!) {
    externalreports(queryFilter: $filter) {
      items {
        _id
        uploadedReportPath
        startDate
        endDate
        createdAt
        reportStatus
        provider {
          _id
          providerName
        }
      }
      page
      totalCount
    }
  }
`;
export { FETCH_EXTERNAL_REPORTS };
