//anything defined here
//takes priority above routes permissions
export const RoutesRoles = {
  '/providers': 'admin',
  '/providers/add': 'admin',
  '/providers/edit': 'admin',
  '/providers/view': 'admin',
  '/deductions': 'admin',
  '/deductions/add': 'admin',
  '/deductions/edit': 'admin',
  '/deductions/view': 'admin',
};

export const RoutsPermissions = {
  '/users': 'ListUsers',
  '/users/add': 'CreateUser',
  '/users/edit': 'CreateUser',
  '/users/view': 'ViewUser',

  '/profile': 'GetProfile',

  '/report': '',

  '/websites': 'ListWebsites',
  '/websites/add': 'AddWebsite',
  '/websites/edit': 'EditWebsite',
  '/websites/list': 'ListWebsites',
  '/websites/view': 'ViewWebsite',

  '/widgets': 'ListWidgets',
  '/widgets/add': 'AddWidget',
  '/widgets/edit': 'EditWidget',
  '/widgets/list': 'ListWidgets',
  '/widgets/view': 'ViewWidget',

  '/brands': 'ListBrands',
  '/brands/add': 'AddBrand',
  '/brands/edit': 'EditBrand',
  '/brands/view': 'ViewBrand',

  '/publishers': 'ListPublishers',
  '/publishers/add': 'AddPublisher',
  '/publishers/edit': 'UpdatePublisher',
  '/publishers/view': 'ViewPublisher',

  '/providers': 'ListProviders',
  '/providers/add': 'AddProvider',
  '/providers/edit': 'AddProvider',
  '/providers/view': 'ViewProvider',

  '/ad-providers': 'ManageAdProviders',
  '/ad-providers/add': 'ManageAdProviders',
  '/ad-providers/edit': 'ManageAdProviders',
  '/ad-providers/view': 'ViewAdProviders',

  '/ad-groups': 'ManageAdGroups',
  '/ad-groups/add': 'ManageAdGroups',
  '/ad-groups/edit': 'ManageAdGroups',
  '/ad-groups/view': 'ViewAdGroups',

  '/external-reports': 'ListExternalReports',
  '/external-reports/add': 'UploadExternalReport',

  '/dashboard': 'ViewReports',
  '/reports-by-provider/': 'ViewReports',
  '/reports-by-country': 'ViewReports',
  '/reports-by-widget': 'ViewReports',
  '/reports-by-website': 'ViewReports',
  '/reports-by-ad-provider': ['ViewReports', 'ManageAdGroups'],
  '/custom-reports': 'ManageCustomReports',
  '/settings': 'UpdateGlobalSettings',
  '/setup': 'SetupNewAccount',

  '/ad-formats': 'ListAdFormat',
  '/ad-formats/add': 'AddAdFormat',
  '/ad-formats/edit': 'ListAdFormat',

  AddUserToWebsite: 'AddUserToWebsite',
  UpdateProfile: 'UpdateProfile',
  AddUserToPublisher: 'AddUserToPublisher',
  AllowManagePublisherUsers: 'AllowManagePublisherUsers',
  ApproveWebsite: 'ApproveWebsite',
  ApproveUser: 'ApproveUser',
  ListPublishers: 'ListPublishers',
  ViewReports: 'ViewReports',
  AllowManageLicenseFee: 'AllowManageLicenseFee',
  AllowViewLicenseFee: 'AllowViewLicenseFee',
  AllowSyncWidget: 'AllowSyncWidget',
  FullSearch: 'FullSearch',
  AllowImpersonateUser: 'AllowImpersonateUser',
  AddPaymentTerms: 'AddPaymentTerms',
  ViewPaymentTerms: 'ViewPaymentTerms',
  ChangeDealType: 'ChangeDealType',
  ViewDealType: 'ViewDealType',
  DeleteBrand: 'DeleteBrand',
  DeletePublisher: 'DeletePublisher',
  DeleteWebsite: 'DeleteWebsite',
  DeleteWidget: 'DeleteWidget',
  DeleteUser: 'DeleteUser',
  DeleteCustomReport: 'DeleteCustomReport',
};
