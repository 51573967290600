import {
  SET_DEDUCTIONS_ITEMS,
  SET_DEDUCTIONS_TOTAL_COUNT,
  SET_DEDUCTIONS_PAGE,
  APPEND_DEDUCTIONS_ITEMS,
  CLEAR_DEDUCTIONS,
} from '../../../../constants/actionTypes';

export const setDeductions = (data) => async (dispatch) => {
  dispatch({
    type: SET_DEDUCTIONS_ITEMS,
    payload: data,
  });
};

export const appendDeductions = (data) => async (dispatch) => {
  dispatch({
    type: APPEND_DEDUCTIONS_ITEMS,
    payload: data,
  });
};

export const setDeductionsPage = (page) => ({
  type: SET_DEDUCTIONS_PAGE,
  payload: page,
});

export const clearDeductions = () => ({
  type: CLEAR_DEDUCTIONS,
});
