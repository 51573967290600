import {
  SET_PUBLISHERS_ITEMS,
  SET_PUBLISHERS_TOTAL_COUNT,
  SET_PUBLISHERS_PAGE,
  APPEND_PUBLISHERS_ITEMS,
  CLEAR_PUBLISHERS,
} from '../../../../constants/actionTypes';

export const setPublishers = (data) => async (dispatch) => {
  dispatch({
    type: SET_PUBLISHERS_ITEMS,
    payload: data?.publishers?.items,
  });
  dispatch({
    type: SET_PUBLISHERS_TOTAL_COUNT,
    payload: data?.publishers?.totalCount,
  });
};

export const appendPublishers = (data) => async (dispatch) => {
  dispatch({
    type: APPEND_PUBLISHERS_ITEMS,
    payload: data?.publishers?.items,
  });
  dispatch({
    type: SET_PUBLISHERS_TOTAL_COUNT,
    payload: data?.publishers?.totalCount,
  });
};

export const setPublishersPage = (page) => ({
  type: SET_PUBLISHERS_PAGE,
  payload: page,
});

export const clearPublishers = () => ({
  type: CLEAR_PUBLISHERS,
});
