import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';

const StyledTblcell = withStyles((theme) => ({
  root: {
    borderBottom: 0,
    padding: '16px',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    '& p': {
      fontFamily: 'Poppins, sans-serif',
      fontSize: 13,
      wordWrap: 'break-word',
    },
  },
}))(TableCell);

export const StyledTableCell = ({ children, ...rest }) => {
  return <StyledTblcell>{children}</StyledTblcell>;
};
