import { RoutsPermissions, RoutesRoles } from '../routes/RoutesPermessions';

const AUTHORIZATION_TYPE = {
  ROLE: 'role',
  PERMISSION: 'permission',
};

export const isPermittedToAccess = (
  path,
  currentUser,
  authorizationType = AUTHORIZATION_TYPE.PERMISSION,
) => {
  if (!currentUser || !path) return false;
  const userRole = currentUser?.role.roleName;
  const pathRole = RoutesRoles[path.split('/:')[0]];
  const userPermissions = currentUser?.role?.permissions;
  const pathPermissions = RoutsPermissions[path.split('/:')[0]];
  let hasPermissionToAccess = true;

  if (authorizationType === AUTHORIZATION_TYPE.ROLE) {
    if (!!userRole && !!pathRole) {
      hasPermissionToAccess = userRole === pathRole;
    }
  } else if (authorizationType === AUTHORIZATION_TYPE.PERMISSION) {
    if (userPermissions && pathPermissions?.length > 0) {
      if (typeof pathPermissions === 'object' && pathPermissions?.length > 0) {
        pathPermissions.map((permissionName) => {
          hasPermissionToAccess =
            hasPermissionToAccess &&
            userPermissions.filter(
              (permission) => permission.permissionName === permissionName,
            ).length > 0;
        });
      } else {
        hasPermissionToAccess =
          userPermissions.filter(
            (permission) => permission.permissionName === pathPermissions,
          ).length > 0;
      }
    }
  }
  return hasPermissionToAccess;
};
