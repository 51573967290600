import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  valueSelector: {
    padding: '15px',
  },
  footer: {},
  button: {
    fontWeight: 400,
    height: '38px',
    width: '100%',
    borderRadius: '8px',
    fontSize: '17px',
    fontFamily: 'Poppins, sans-serif',
    padding: 0,
    marginTop: '10px',
    color: '#fff',
  },
}));
