import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  flexContainer: {},
  root: {
    background: '#fff',
    fontSize: '14px',
    color: '#A0A5BA',
    textTransform: 'none',
    minWidth: '150px',
    marginRight: '5px',
    '&:focus': {
      color: '#2BACE4',
      background: '#2BACE433',
    },
  },
  selected: {
    color: '#2BACE4',
    background: '#2BACE433',
    borderRadius: '5px',
  },
}));
