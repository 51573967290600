import {
  SET_WIDGETS_ITEMS,
  SET_WIDGETS_TOTAL_COUNT,
  SET_WIDGETS_PAGE,
  APPEND_WIDGETS_ITEMS,
  CLEAT_WIDGETS,
} from '../../../../constants/actionTypes';

export const setWidgets = (data) => async (dispatch) => {
  dispatch({
    type: SET_WIDGETS_ITEMS,
    payload: data?.widgets?.items,
  });
  dispatch({
    type: SET_WIDGETS_TOTAL_COUNT,
    payload: data?.widgets?.totalCount,
  });
};

export const appendWidgets = (data) => async (dispatch) => {
  dispatch({
    type: APPEND_WIDGETS_ITEMS,
    payload: data?.widgets?.items,
  });
  dispatch({
    type: SET_WIDGETS_TOTAL_COUNT,
    payload: data?.widgets?.totalCount,
  });
};

export const setWidgetsPage = (page) => ({
  type: SET_WIDGETS_PAGE,
  payload: page,
});

export const clearWidgets = () => ({
  type: CLEAT_WIDGETS,
});
