import {
  LOGIN,
  LOGOUT,
  CHANGE_PASSWORD,
  CLEAR_USER,
  IMPERSONATE,
  END_IMPERSONATION,
  UPDATE_PROFILE_FULFILLED,
} from '../../../constants/actionTypes';
import authService from '../../../services/auth.service';
import { clearBrands } from '../appData/brands';
import { clearProviders } from '../appData/providers';
import { clearPublishers } from '../appData/publishers';
import { clearUsers } from '../appData/users';
import { clearWebsites } from '../appData/websites';
import { clearWidgets } from '../appData/widgets';
import { clearLookUps } from '../ui/lookUp';

export const login = (request) => async (dispatch) => {
  dispatch({
    type: LOGIN,
    payload: authService.login(request),
  });
  dispatch(clearBrands());
  dispatch(clearProviders());
  dispatch(clearProviders());
  dispatch(clearPublishers());
  dispatch(clearUsers());
  dispatch(clearUsers());
  dispatch(clearWebsites());
  dispatch(clearWidgets());

  dispatch(clearLookUps());
};
export const impersonate = (request) => async (dispatch) => {
  dispatch({
    type: IMPERSONATE,
    payload: authService.impersonateUser(request),
  });
};

export const endImpersonation = (request) => async (dispatch) => {
  dispatch({
    type: END_IMPERSONATION,
    payload: authService.endImpersonation(request),
  });
};

export const logout = (request) => async (dispatch) => {
  dispatch({
    type: LOGOUT,
    payload: authService.logout(request),
  });
};

export const resetPassword = () => {
  return {
    type: CHANGE_PASSWORD,
  };
};

export const clearUser = () => {
  return {
    type: CLEAR_USER,
  };
};

export const updateAvatar = (payload) => {
  return {
    type: UPDATE_PROFILE_FULFILLED,
    payload,
  };
};

export const updatePaymentMethods = (payload) => {
  return {
    type: UPDATE_PROFILE_FULFILLED,
    payload,
  };
};
