import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TableRow } from '@material-ui/core';

const StyledTblRow = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#d8f3dc !important',
    },
  },
}))(TableRow);

export const StyledTableRow = ({ children, index }) => {
  return (
    <StyledTblRow
      hover
      tabIndex={-1}
      style={
        index % 2 === 0
          ? { backgroundColor: '#f8f9fa' }
          : { backgroundColor: 'white' }
      }
    >
      {children}
    </StyledTblRow>
  );
};
