import React, { useEffect, useState } from 'react';
import { useStyles } from './DonutChartWidget.styles';
import {
  Chart,
  Legend,
  PieSeries,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import GraphContainer from './GraphContainer';
import GraphTabs from './GraphTabs';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { EventTracker, Palette } from '@devexpress/dx-react-chart';
import numeral from 'numeral';
import { NUMBER_HUMANIZE_FORMAT } from '../../constants/enum';
import { calculateMetric } from './ReportsCalculations';
import { Tooltip as MUIToolTip } from '@material-ui/core/';

const DonutChartWidget = ({
  widgetName,
  data,
  colors,
  icons,
  tabs,
  selectedTab,
  order = 'DESC',
}) => {
  const dataKeys = Object.keys(data[0] || {});
  dataKeys.splice(dataKeys.indexOf('Dimension'), 1);
  let [metric, setMetric] = useState(0);
  let columns = [];
  let dataColors = {};
  let sum = 0;
  data = data.sort((a, b) => {
    if (order === 'DESC') {
      return calculateMetric(a, tabs[selectedTab]) >
        calculateMetric(b, tabs[selectedTab])
        ? -1
        : 1;
    } else {
      return calculateMetric(a, tabs[selectedTab]) >
        calculateMetric(b, tabs[selectedTab])
        ? 1
        : -1;
    }
  });

  data.map((element, index) => {
    columns.push({
      arg: element['Dimension'],
      val: calculateMetric(element, tabs[selectedTab]),
    });
    dataColors[element['Dimension']] = colors[index];
    sum += parseFloat(calculateMetric(element, tabs[selectedTab]));
  });

  const legendRootStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxHeight: '160px',
    overflow: 'auto',
  };
  const LegendRoot = (props) => {
    return <Legend.Root {...props} style={legendRootStyle} />;
  };

  const legendItemStyle = {
    textTransform: 'uppercase',
    flexDirection: 'row',
    fontSize: '13px',
    width: '100%',
  };
  const LegendItem = (props) => {
    const { name, color } = props?.children[0]?.props;
    const val = parseFloat(
      columns.filter((element) => element.arg === name)[0]['val'],
    );
    const percentage = numeral((100 * val) / sum).format(
      NUMBER_HUMANIZE_FORMAT,
    );
    const viewableValue = numeral(val || 0).format(NUMBER_HUMANIZE_FORMAT);
    return percentage ? (
      <Legend.Item {...props} style={{ ...legendItemStyle, color }}>
        <FiberManualRecordIcon className={classes.icon} />
        <MUIToolTip
          title={<div style={{ fontSize: '15px' }}>{name}</div>}
          aria-label={name}
        >
          <div className={classes.label}>{name}</div>
        </MUIToolTip>
        <div className={classes.percentage}>
          <span className={classes.value}>{viewableValue}</span>
          <span className={classes.value}>{percentage}%</span>
        </div>
      </Legend.Item>
    ) : null;
  };

  const tooltipContentTitleStyle = {
    fontWeight: 'bold',
    paddingBottom: 0,
    fontSize: '15px',
  };

  const tooltipContentBodyStyle = {
    paddingTop: 0,
  };

  const TooltipContent = (props) => {
    const { targetItem, text, ...restProps } = props;
    return (
      <div>
        <div>
          <Tooltip.Content
            {...restProps}
            style={tooltipContentTitleStyle}
            text={columns?.find((item) => item.val.toString() === text)?.arg}
          />
        </div>
        <div>
          <Tooltip.Content
            {...restProps}
            style={tooltipContentBodyStyle}
            text={text}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {}, []);

  const handleTabChanged = (e, val) => {
    setMetric(val);
  };
  const classes = useStyles();
  return (
    <GraphContainer widgetName={widgetName}>
      {dataKeys.length > 1 && (
        <GraphTabs
          value={metric}
          tabs={dataKeys}
          handleChange={handleTabChanged}
          theme={classes}
        />
      )}
      <Chart data={columns} style={{ overflowY: 'scroll' }}>
        <Palette scheme={colors} />
        <PieSeries
          valueField="val"
          argumentField="arg"
          innerRadius={0.6}
          outerRadius={0.7}
        />
        <Legend
          position="bottom"
          itemComponent={LegendItem}
          rootComponent={LegendRoot}
        />
        <EventTracker />
        <Tooltip contentComponent={TooltipContent} />
      </Chart>
    </GraphContainer>
  );
};

export default DonutChartWidget;
