import { optionsForUsersRole } from '../options/optionsForUsers';
import { optionsForStatus } from '../options/genericOptions';
import { optionsForPaymentTypes } from '../options/optionsForProviders';
import { optionsForDevice } from '../options/optionsForDevice';

const optionMapper = {
  USERS: {
    roleName: optionsForUsersRole,
    userStatus: optionsForStatus,
  },
  BRANDS: {
    brandStatus: optionsForStatus,
  },
  PUBLISHERS: {
    publisherStatus: optionsForStatus,
  },
  WEBSITES: {
    websiteStatus: optionsForStatus,
  },
  WIDGETS: {
    widgetStatus: optionsForStatus,
  },
  PROVIDERS: {
    providerPaymentType: optionsForPaymentTypes,
    providerStatus: optionsForStatus,
  },
  AD_GROUPS: {
    device: optionsForDevice,
  },
};

const getOptionValues = (vm, target) => {
  return optionMapper[vm][target] || [];
};

export default getOptionValues;
