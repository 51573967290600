import React, { useState } from 'react';
import { TextField, Icon, Typography, Badge } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useGraphLazyQuery } from '../../core/hooks';
import { useStyles } from './AutoCompleteBox.styles';

const AutoCompleteBox = ({
  query,
  name,
  queryFilter,
  dependsOn,
  value,
  onChange,
  optionLabel,
  optionValue,
  createdUserByModal,
  extraFilterBy,
  ...rest
}) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);

  const [getAutoCompleteData] = useGraphLazyQuery(query, (data) => {
    const search = data.autoComplete.documents;
    setOptions(search);
  });

  const SelecboxChange = (e, value) => {
    if (value) return onChange(name, value);
    return '';
  };

  const handleInputChange = (event, _value) => {
    // if (_value?.length >= 3) {
    const autoComp = {
      autoCompleteQuery: {
        entityType: name,
        page: 1,
        filter: {
          filterType: queryFilter,
          filterBy: dependsOn
            ? extraFilterBy
              ? `parentId:${dependsOn}&&${extraFilterBy}`
              : `parentId:${dependsOn}`
            : '',
          entityValue: _value,
        },
      },
    };
    !!createdUserByModal
      ? getAutoCompleteData({
          variables: {
            userId: createdUserByModal._id,
            ...autoComp,
          },
        })
      : getAutoCompleteData({ variables: autoComp });
    // }
  };

  return (
    <>
      {typeof value === 'object' && (
        <Autocomplete
          className={classes.autoComplete}
          clearOnBlur={true}
          clearOnEscape={true}
          fullWidth
          noOptionsText={'No results'}
          autoComplete
          filterSelectedOptions
          id={`${name}`}
          value={value}
          getOptionLabel={(option) => {
            if (option) return `${option?.name}`;
            return '';
          }}
          renderOption={(option) => (
            <div
              id={`Selectbox-option-${option[optionLabel]}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '90%',
              }}
            >
              <Typography
                id={`Selectbox-Typography-${option[optionLabel]}`}
                variant="body1"
                gutterBottom
                key={option._id}
                value={optionValue ? option[optionValue] : option}
                color={
                  option.isEnabled !== undefined && !option.isEnabled
                    ? 'textSecondary'
                    : 'initial'
                }
              >
                {option[optionLabel]}
              </Typography>
              {options[0]?.isEnabled !== undefined && (
                <Badge
                  style={{ marginLeft: '20px' }}
                  color={
                    option.isEnabled !== undefined && !option.isEnabled
                      ? 'error'
                      : 'primary'
                  }
                  badgeContent={
                    option.isEnabled !== undefined && !option.isEnabled
                      ? '  Disabled'
                      : '  Enabled'
                  }
                />
              )}
            </div>
          )}
          options={options}
          popupIcon={<Icon className={classes.icon}>search</Icon>}
          onChange={SelecboxChange}
          onInputChange={handleInputChange}
          renderInput={(params) => (
            <TextField
              className={classes.input}
              {...params}
              fullWidth
              size="small"
              variant="outlined"
              placeholder={rest.placeholder}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                maxLength: 60,
              }}
            />
          )}
          {...rest}
        />
      )}
      {typeof value !== 'object' && (
        <Autocomplete
          className={classes.autoComplete}
          clearOnBlur={true}
          clearOnEscape={true}
          fullWidth
          noOptionsText={'No results'}
          autoComplete
          filterSelectedOptions
          id={`${name}`}
          getOptionLabel={(option) => {
            if (option) return `${option[optionLabel]}`;
            return '';
          }}
          renderOption={(option) => (
            <div
              id={`Selectbox-option-${option[optionLabel]}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '90%',
              }}
            >
              <Typography
                id={`Selectbox-Typography-${option[optionLabel]}`}
                variant="body1"
                gutterBottom
                key={option._id}
                value={optionValue ? option[optionValue] : option}
                color={
                  option.isEnabled !== undefined && !option.isEnabled
                    ? 'textSecondary'
                    : 'initial'
                }
              >
                {option[optionLabel]}
              </Typography>
              {options[0]?.isEnabled !== undefined && (
                <Badge
                  style={{ marginLeft: '20px' }}
                  color={
                    option.isEnabled !== undefined && !option.isEnabled
                      ? 'error'
                      : 'primary'
                  }
                  badgeContent={
                    option.isEnabled !== undefined && !option.isEnabled
                      ? '  Disabled'
                      : '  Enabled'
                  }
                />
              )}
            </div>
          )}
          options={options}
          popupIcon={<Icon className={classes.icon}>search</Icon>}
          onChange={SelecboxChange}
          onInputChange={handleInputChange}
          renderInput={(params) => (
            <TextField
              className={classes.input}
              {...params}
              fullWidth
              size="small"
              variant="outlined"
              placeholder={rest.placeholder}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                maxLength: 60,
              }}
            />
          )}
          {...rest}
        />
      )}
    </>
  );
};

export default AutoCompleteBox;
