import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TableContainer } from '@material-ui/core';

const StyledTblContainer = withStyles((theme) => ({
  root: {
    borderRadius: '10px 10px 0px 0px',
    minWidth: 750,
  },
}))(TableContainer);

export const StyledTableContainer = ({ children }) => {
  return <StyledTblContainer> {children} </StyledTblContainer>;
};
