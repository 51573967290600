export const PAGE_SIZE = 15;
export const MAX_FILE_SIZE = 5000000; // 5MB
export const PAGE_SIZE_OPTIONS = [1, 2, 5, 10, 25];
export const SELECT_USER_PAGE_SIZE_OPTIONS = [1, 2, 5, 10];

export const FILTER_OPERATION_EQUALS = 'equals';
export const FILTER_OPERATION_IN = 'in';

export const FILTER_FIELD_TYPE_ID = '_id';

export const FILTER_FIELD_TYPE_DISPLAYNAME = 'displayName';
export const FILTER_FIELD_TYPE_FIRSTNAME = 'firstName';
export const FILTER_FIELD_TYPE_EMAIL = 'email';
export const FILTER_FIELD_TYPE_WIDGETNAME = 'widgetName';
export const FILTER_FIELD_TYPE_WIDGET_ID = 'widgetId';
export const FILTER_FIELD_TYPE_EXTERNAL_WIDGET_ID = 'externalWidgetId';
export const FILTER_FIELD_TYPE_WEBSITE_ID = 'websiteId';
export const FILTER_FIELD_TYPE_WEBSITENAME = 'websiteName';
export const FILTER_FIELD_TYPE_WEBSITEID = 'websiteId';
export const FILTER_FIELD_TYPE_PUBLISHERNAME = 'publisherName';
export const FILTER_FIELD_TYPE_PUBLISHER_OWNER = 'publisherOwnerEmail';
export const FILTER_FIELD_TYPE_PUBLISHER_ID = 'publisherId';
export const FILTER_FIELD_TYPE_BRANDNAME = 'brandName';
export const FILTER_FIELD_TYPE_BRANDID = 'brandId';
export const FILTER_FIELD_TYPE_PROVIDERNAME = 'providerName';
export const FILTER_FIELD_TYPE_PROVIDERID = 'providerId';
export const FILTER_FIELD_TYPE_PROVIDER_SUPPORTS_MANUAL =
  'supportsManualReports';
export const FILTER_FIELD_TYPE_USER_IDS = 'userIds';
export const FILTER_FIELD_TYPE_USER_ID = 'userId';
export const FILTER_FIELD_TYPE_BRAND_ID = 'brandId';
export const FILTER_FIELD_TYPE_ROLE_NAME = 'role';
export const FILTER_FIELD_TYPE_COUNTRY = 'country';
export const FILTER_FIELD_NON_LINKED_USERS = 'nonLinkedUsers';

export const NUMBER_HUMANIZE_FORMAT = '0.[00]a';
export const EARNINGS_NUMBER_HUMANIZE_FORMAT = '0.[00]';
export const SMALL_NUMBER_HUMANIZE_FORMAT = '0.[00000]';

export const STATUS_PENDING = 'Pending';
export const STATUS_DISABLE = 'Disable';
export const STATUS_APPROVE = 'Approved';
export const STATUS_DELETED = 'Deleted';
export const COOKIE_NAME_PREFIX = 'ketshwa_';
export const DEVICES = {
  All: 'All',
  Tablet: 'Tablet',
  Mobile: 'Mobile',
  Desktop: 'Desktop',
  iPhone: 'iPhone',
  Android: 'Android',
  Unknown: 'Unknown',
  SmartTv: 'Smart TV',
};

export const DEVICES_OPTIONS = [
  { value: 'All', label: 'All' },
  { value: 'Tablet', label: 'Tablet' },
  { value: 'Mobile', label: 'Mobile' },
  { value: 'Desktop', label: 'Desktop' },
  { value: 'iPhone', label: 'iPhone' },
  { value: 'Android', label: 'Android' },
  { value: 'Unknown', label: 'Unknown' },
  { value: 'SmartTv', label: 'Smart TV' },
];

export const PAYMENT_METHODS = {
  PAYPAL: 'PayPal',
  PAYONEER: 'Payoneer',
  WIRE_TRANSER: 'WireTransfer',
  CRYPTO: 'Crypto',
};

export const MetricOrder = [
  { value: 'ASC', label: 'ASCENDING' },
  { value: 'DESC', label: 'DESCENDING' },
];

export const DEFAULT_METRIC_ORDER = 'ASC';

export const ChartType = {
  LineChart: 'Line Chart',
  BarChart: 'Bar Chart',
  PieChart: 'Pie Chart',
  TableChart: 'Table Chart',
  GeoChart: 'Geo Chart',
  // TextChart: 'Text Chart',
};
export const DEFAULT_Chart_Type = 'LineChart';
export const ChartLimitResult = {
  NoLimit: 'No Limit',
  TOP10: 'Top 10',
  Worst10: 'Worst 10',
  Top100: 'Top 100',
  Worst100: 'Worst 100',
};
export const DEFAULT_CHART_RESULT_LIMIT = 'TOP10';

export const MetricType = [
  { label: 'Clicks', value: 'Clicks' },
  { label: 'Impressions', value: 'Impressions' },
  { label: 'Revenue', value: 'Revenue' },
  { label: 'eCPC', value: 'eCPC' },
  { label: 'CTR', value: 'CTR' },
  { label: 'eCPM', value: 'eCPM' },
];
export const DEFAULT_OORDER_BY_METRIC = 'Clicks';

export const ChartDimension = [
  { value: 'Device', label: 'Device' },
  { value: 'Country', label: 'Country' },
  { value: 'Date', label: 'Date' },
  { value: 'Website', label: 'Website' },
  { value: 'Widget', label: 'Widget' },
  { value: 'Publisher', label: 'Publisher' },
  { value: 'Brand', label: 'Brand' },
  { value: 'Provider', label: 'Provider' },
  { value: 'AdProvider', label: 'AdProvider' },
];

export const PieChartDimension = [
  { value: 'Device', label: 'Device' },
  { value: 'Website', label: 'Website' },
  { value: 'Widget', label: 'Widget' },
  { value: 'Publisher', label: 'Publisher' },
  { value: 'Brand', label: 'Brand' },
  { value: 'Provider', label: 'Provider' },
  { value: 'AdProvider', label: 'AdProvider' },
];

export const BarChartDimension = [
  { value: 'Device', label: 'Device' },
  { value: 'Website', label: 'Website' },
  { value: 'Widget', label: 'Widget' },
  { value: 'Publisher', label: 'Publisher' },
  { value: 'Brand', label: 'Brand' },
  { value: 'Provider', label: 'Provider' },
  { value: 'AdProvider', label: 'AdProvider' },
];

export const DateFilter = {
  Today: 'Today',
  Yesterday: 'Yesterday',
  LastWeek: 'Last Week',
  LastMonth: 'Last Month',
  Last30Days: 'Last 30 Days',
};

export const ReportFiltersList = [
  { value: 'device', label: 'Device' },
  { value: 'country', label: 'Country' },
  { value: 'date', label: 'Date' },
  { value: 'website', label: 'Website' },
  { value: 'widget', label: 'Widget' },
  { value: 'publisher', label: 'Publisher' },
  { value: 'brand', label: 'Brand' },
  { value: 'provider', label: 'Provider' },
  { value: 'adProvider', label: 'AdProvider' },
];

export const ToolTipsIcons = [
  { value: 'users', label: 'users' },
  { value: 'widgets', label: 'widgets' },
  { value: 'publishers', label: 'publishers' },
  { value: 'websites', label: 'websites' },
];

export const DEFAULT_REPORT_DATE_FILTER = 'Today';
