export const selectDirectParent = (state) => state.ui.common.directParent;

export const selectLastNode = (state) => state.ui.common.lastNode;

export const selectDateRangeValue = (state) => state.ui.common.dateRangeValue;

export const selectIsCreateModalOpened = (state) =>
  state.ui.common.isCreateModalOpened;

export const selectIsEditModalOpened = (state) =>
  state.ui.common.isEditModalOpened;

export const selectPropertyId = (state) => state.ui.common.propertyId;

export const selectCreatedUser = (state) => state.ui.common.createdUser;

export const selectSearchParam = (state) => state.ui.common.searchParam;

export const selectHighlightTopSelector = (state) =>
  state.ui.common.highlightTopSelector;

export const selectWebsitesViewMode = (state) =>
  state.ui.common.websitesViewMode;

export const selectWidgetsViewMode = (state) => state.ui.common.widgetsViewMode;

export const selectSelectedCustomReport = (state) =>
  state.ui.common.selectedCustomReport;

export const selectCustomReportTableChartPage = (state) =>
  state.ui.common.customReportTableChartPage;
