import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TableBody } from '@material-ui/core';

const StyledTblBody = withStyles((theme) => ({
  root: {},
}))(TableBody);

export const StyledTableBody = ({ children }) => {
  return <StyledTblBody>{children}</StyledTblBody>;
};
