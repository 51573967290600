const targetsForWebsites = [
  { value: 'displayName', label: 'Display name', type: 'text' },
  { value: 'websiteCategory', label: 'Category', type: 'text' },
  { value: 'websiteDomain', label: 'Domain', type: 'text' },
  { value: 'websiteLanguage', label: 'Language', type: 'text' },
  { value: 'websiteStatus', label: 'Status', type: 'selection' },
  { value: 'publisherId', label: 'Publisher', type: 'text' },
  { value: 'isEnabled', label: 'Enable', type: 'boolean' },
];

export default targetsForWebsites;
