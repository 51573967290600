import React, { useEffect, useState } from 'react';
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  SplineSeries,
  Legend,
  ScatterSeries,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import moment from 'moment';
import { symbol, symbolCircle } from 'd3-shape';
import { EventTracker, Palette, ValueScale } from '@devexpress/dx-react-chart';
import GraphContainer from './GraphContainer';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { InputBase, withStyles } from '@material-ui/core';

const Point = (type, styles) => (props) => {
  const { arg, val, color } = props;
  return (
    <path
      fill={color}
      transform={`translate(${arg} ${val})`}
      d={symbol()
        .size([10 ** 2])
        .type(type)()}
      style={styles}
    />
  );
};
const CirclePoint = Point(symbolCircle, {
  stroke: 'white',
  strokeWidth: '1px',
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#f7f7f7',
    border: '1px solid #f7f7f7',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const SplineWithCirclePoint = (props) => {
  const { coordinates } = props;
  return (
    <React.Fragment>
      <SplineSeries.Path {...props} coordinates={coordinates} />
      <ScatterSeries.Path {...props} pointComponent={CirclePoint} />
    </React.Fragment>
  );
};

const LineChartWidget = ({
  widgetName,
  chartData,
  colors,
  tabs,
  tabMetrics,
}) => {
  const [lineChartData, setLineChartData] = useState([]);
  let data = [];
  const toDateTime = (milliSeconds) => {
    const seconds = Math.floor(milliSeconds / 1000);
    const date = new Date(1970, 0, 1);
    date.setSeconds(seconds);
    return date;
  };
  const dateFormat = 'M-D';
  const keys = lineChartData?.length ? Object.keys(lineChartData[0]) : [];
  keys.splice(0, 3);
  let [metric1, setMetric1] = useState(0);
  let [metric2, setMetric2] = useState(1);
  let widgets = {};
  lineChartData.map((element, index) => {
    const dateObj = moment(element.reportDate)._isValid
      ? moment(element.reportDate)
      : moment(toDateTime(element.reportDate));
    let obj = {
      argument: dateObj.format(dateFormat),
    };
    obj[tabMetrics[metric1]] = parseFloat(element[tabMetrics[metric1]]);
    if (element[tabMetrics[metric2]]) {
      obj[tabMetrics[metric2]] = parseFloat(element[tabMetrics[metric2]]);
    }
    data.push(obj);
  });
  let accumaltedData = data.reduce((accData, element) => {
    if (element.argument in accData) {
      accData[element.argument][tabMetrics[metric1]] +=
        element[tabMetrics[metric1]];
      accData[element.argument][tabMetrics[metric2]] +=
        element[tabMetrics[metric2]];
    } else {
      accData[element.argument] = { argument: element.argument };
      accData[element.argument][tabMetrics[metric1]] =
        element[tabMetrics[metric1]];
      accData[element.argument][tabMetrics[metric2]] =
        element[tabMetrics[metric2]];
    }
    return accData;
  }, {});
  accumaltedData = Object.values(accumaltedData);

  const handleMetric1Change = (e, obj) => {
    setMetric1(obj.props.value);
  };
  const handleMetric2Change = (e, obj) => {
    setMetric2(obj.props.value);
  };

  const legendRootStyle = {
    display: 'flex',
    margin: '0px',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
  };
  const LegendRoot = (props) => {
    return <Legend.Root {...props} style={legendRootStyle} />;
  };

  const legendItemStyle = {
    textTransform: 'uppercase',
    flexDirection: 'row',
    marginLeft: '-2px',
    marginRight: '-2px',
    fontSize: '20px',
  };
  const LegendItem = (props) => {
    return <Legend.Item {...props} style={legendItemStyle} />;
  };
  const LabelComponent = (props) => {
    return <ArgumentAxis.Label {...props} style={legendItemStyle} />;
  };
  const makeLabel = (symbol, color) => ({ text, style, ...restProps }) => (
    <ValueAxis.Label
      text={`${text} ${symbol}`}
      style={{
        fill: color,
        ...style,
      }}
      {...restProps}
    />
  );
  const renderSplines = () => {
    let splines = [];
    if (data.length) {
      Object.keys(data[0]).map((dkey, index) => {
        if (dkey !== 'argument') {
          splines.push(
            <SplineSeries
              key={`wspline-${dkey}-${index}`}
              name={dkey}
              valueField={dkey}
              scaleName={`${dkey}-scale`}
              argumentField="argument"
              seriesComponent={SplineWithCirclePoint}
            />,
          );
        }
      });
    }
    return splines;
  };
  const renderScales = () => {
    let scales = [];
    if (data.length) {
      Object.keys(data[0]).map((dkey, index) => {
        if (dkey !== 'argument') {
          scales.push(<ValueScale name={dkey + '-scale'} />);
        }
      });
    }
    return scales;
  };
  const renderValueAxises = () => {
    let axises = [];
    if (data.length) {
      Object.keys(data[0]).map((dkey, index) => {
        if (dkey !== 'argument') {
          axises.push(
            <ValueAxis
              scaleName={dkey + '-scale'}
              position={index % 2 ? 'right' : 'left'}
              showGrid={index % 2}
              labelComponent={makeLabel('', '#333')}
            />,
          );
        }
      });
    }
    return axises;
  };

  const tooltipContentBodyStyle = {
    paddingTop: 0,
  };

  const TooltipContent = (props) => {
    const { targetItem, text, ...restProps } = props;
    return (
      <div>
        <div>
          <Tooltip.Content
            {...restProps}
            style={tooltipContentBodyStyle}
            text={text}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    setLineChartData([]);
    setTimeout(() => setLineChartData(chartData), 300);
  }, [chartData]);

  return (
    <GraphContainer widgetName={widgetName}>
      <div style={{ float: 'right' }}>
        <Select
          value={metric1}
          onChange={handleMetric1Change}
          input={<BootstrapInput />}
        >
          {tabs.map(
            (tab, index) =>
              index !== metric2 && (
                <MenuItem value={index} key={`${tab}-m1-${index}`}>
                  {tab}
                </MenuItem>
              ),
          )}
        </Select>
        {tabs?.length > 1 && (
          <Select
            value={metric2}
            style={{ marginLeft: '10px' }}
            input={<BootstrapInput />}
            onChange={handleMetric2Change}
          >
            {tabs.map(
              (tab, index) =>
                index !== metric1 && (
                  <MenuItem value={index} key={`${tab}-m2-${index}`}>
                    {tab}
                  </MenuItem>
                ),
            )}
          </Select>
        )}
      </div>
      <div style={{ clear: 'both' }}></div>
      {lineChartData?.length && (
        <Chart data={accumaltedData} height="452">
          <Palette scheme={colors} />

          <ArgumentAxis LabelComponent={LabelComponent} />
          {renderScales()}
          {renderValueAxises()}
          {renderSplines()}
          <Legend
            position="bottom"
            itemComponent={LegendItem}
            rootComponent={LegendRoot}
          />
          <EventTracker />
          <Tooltip contentComponent={TooltipContent} />
        </Chart>
      )}
    </GraphContainer>
  );
};

export default LineChartWidget;
