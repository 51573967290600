import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeAndApplyFilter } from '../../redux/store/ui/filters';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import { useLocation } from 'react-router-dom';
import {
  selectLookUpBrands,
  selectLookUpProviders,
  selectLookUpPublishers,
  selectLookUpWebsites,
  selectLookUpWidgets,
} from '../../redux/store/ui/lookUp';
import getTargets from './helpers/getTargets';
import { Countries } from '../../utils';

const REMOVE_ICON = window.location.origin + '/icons/remove.svg';

const getFilterDisplayLabel = (label) => {
  switch (label) {
    case 'brandId':
      return 'Brand';
    case 'publisherId':
      return 'Publisher';
    case 'websiteId':
      return 'Website';
    case 'providerId':
      return 'Provider';
    default:
      return label;
  }
};

const getFilterDisplayValue = (
  viewMode,
  label,
  value,
  brands,
  publishers,
  websites,
  providers,
  widgets,
) => {
  switch (label) {
    case 'brandId':
      return brands.find((item) => item._id === value)?.brandName || value;
    case 'publisherId':
      return (
        publishers.find((item) => item._id === value)?.publisherName || value
      );
    case 'websiteId':
      return [
        'REPORTS_BY_PROVIDER',
        'REPORTS_BY_COUNTRY',
        'REPORTS_BY_WIDGET',
      ].includes(viewMode)
        ? websites.find((item) => item._id === value)?.websiteDomain
        : websites.find((item) => item._id === value)?.displayName || value;
    case 'providerId':
      return (
        providers.find((item) => item._id === value)?.providerName || value
      );
    case 'widgetId':
      return widgets.find((item) => item._id === value)?.widgetName || value;
    case 'country':
      return Countries.find((country) => country.code === value)?.name || value;
    default:
      return value;
  }
};

const useStyles = makeStyles((theme) => ({
  container: {
    boxSizing: 'border-box',
    display: 'inline-flex',
    marginBottom: '4px',
    padding: '4px 0',
  },
  appliedFilter: {
    boxSizing: 'border-box',
    background: '#fff',
    color: '#313131',
    height: '24px',
    padding: '0 4px 0 8px',
    fontSize: '14px',
    lineHeight: '14px',
    display: 'inline-flex',
    alignItems: 'center',
    border: '1px solid #c9c9c9',
    borderRadius: '12px',
    margin: '4px',
  },
  remove: { marginLeft: '4px', cursor: 'pointer', marginTop: '1px' },
  badge: { position: 'relative', top: '-5px', left: '12px' },
  value: { fontWeight: '500', marginLeft: '2px' },
}));

const AppliedFiltersTag = ({ viewMode, label, value }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleOnClickRemove = ({ key, value }) => (e) => {
    e.preventDefault();
    dispatch(removeAndApplyFilter({ location, viewMode, key, value }));
  };

  const filterLabel =
    getTargets(viewMode)?.find((item) => item?.value === label)?.label ||
    getFilterDisplayLabel(label);

  const lookUpBrands = useSelector(selectLookUpBrands);
  const lookUpPublishers = useSelector(selectLookUpPublishers);
  const lookUpWebsites = useSelector(selectLookUpWebsites);
  const lookUpProviders = useSelector(selectLookUpProviders);
  const lookUpWidgets = useSelector(selectLookUpWidgets);

  return (
    <div className={classes.container}>
      <Badge
        showZero
        color="primary"
        badgeContent={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ marginTop: '3px !important', marginLeft: '3px !important' }}
      >
        <p className={classes.appliedFilter}>
          <span className="target">{filterLabel}</span>
          <span className="separator">:</span>
          <span className={classes.value}>
            {getFilterDisplayValue(
              viewMode,
              label,
              value,
              lookUpBrands,
              lookUpPublishers,
              lookUpWebsites,
              lookUpProviders,
              lookUpWidgets,
            )}
          </span>
          <span
            className={classes.remove}
            onClick={handleOnClickRemove({ key: label, value })}
          >
            <img src={REMOVE_ICON} alt="x" />
          </span>
        </p>
      </Badge>
    </div>
  );
};
export default AppliedFiltersTag;
