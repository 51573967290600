import React from 'react';
import { useSelector } from 'react-redux';
import { selectFilterTarget } from '../../redux/store/ui/filters';
import TargetSelector from './TargetSelector';
import ValueSelector from './ValueSelector';
import { getViewMode } from './helpers/getTargets';
import { useLocation } from 'react-router-dom';
import { useStyles } from './FilterPanel.styles';

const FilterPanel = () => {
  const classes = useStyles();
  const target = useSelector(selectFilterTarget);
  const viewMode = getViewMode(useLocation());

  return (
    <>
      <div className={classes.filterPanel}>
        {!target && <TargetSelector viewMode={viewMode} />}
        {target && <ValueSelector viewMode={viewMode} />}
      </div>
    </>
  );
};

export default FilterPanel;
