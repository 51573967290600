import React from 'react';
import './styles/theme.scss';
import './App.scss';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/client';
import { client } from './graphql';
import AppRoute from './routes/AppRoute';
import Notify from './composition/Notify';

const theme = createTheme({
  palette: {
    primary: {
      light: '#2CB374',
      main: '#2CB374',
      dark: '#2CB374',
      contrastText: '#000',
    },
    secondary: {
      main: '#2BACE4',
      contrastText: '#FFF',
    },
  },
  typography: {
    fontSize: 13,
  },
  mixins: {
    toolbar: {
      minHeight: 80,
    },
  },
});

const App = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <AppRoute />
        <Notify />
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
