export const Languages = [
  { code: 'AB', name: 'Abkhaz', nativeName: 'аҧсуа' },
  { code: 'AA', name: 'Afar', nativeName: 'Afaraf' },
  { code: 'AF', name: 'Afrikaans', nativeName: 'Afrikaans' },
  { code: 'AK', name: 'Akan', nativeName: 'Akan' },
  { code: 'SQ', name: 'Albanian', nativeName: 'Shqip' },
  { code: 'AM', name: 'Amharic', nativeName: 'አማርኛ' },
  { code: 'AR', name: 'Arabic', nativeName: 'العربية' },
  { code: 'AN', name: 'Aragonese', nativeName: 'Aragonés' },
  { code: 'HY', name: 'Armenian', nativeName: 'Հայերեն' },
  { code: 'AS', name: 'Assamese', nativeName: 'অসমীয়া' },
  { code: 'AV', name: 'Avaric', nativeName: 'авар мацӀ, магӀарул мацӀ' },
  { code: 'AE', name: 'Avestan', nativeName: 'avesta' },
  { code: 'AY', name: 'Aymara', nativeName: 'aymar aru' },
  { code: 'AZ', name: 'Azerbaijani', nativeName: 'azərbaycan dili' },
  { code: 'BM', name: 'Bambara', nativeName: 'bamanankan' },
  { code: 'BA', name: 'Bashkir', nativeName: 'башҡорт теле' },
  { code: 'EU', name: 'Basque', nativeName: 'euskara, euskera' },
  { code: 'BE', name: 'Belarusian', nativeName: 'Беларуская' },
  { code: 'BN', name: 'Bengali', nativeName: 'বাংলা' },
  { code: 'BH', name: 'Bihari', nativeName: 'भोजपुरी' },
  { code: 'BI', name: 'Bislama', nativeName: 'Bislama' },
  { code: 'BS', name: 'Bosnian', nativeName: 'bosanski jezik' },
  { code: 'BR', name: 'Breton', nativeName: 'brezhoneg' },
  { code: 'BG', name: 'Bulgarian', nativeName: 'български език' },
  { code: 'MY', name: 'Burmese', nativeName: 'ဗမာစာ' },
  { code: 'CA', name: 'Catalan, Valencian', nativeName: 'Català' },
  { code: 'CH', name: 'Chamorro', nativeName: 'Chamoru' },
  { code: 'CE', name: 'Chechen', nativeName: 'нохчийн мотт' },
  {
    code: 'NY',
    name: 'Chichewa, Chewa, Nyanja',
    nativeName: 'chiCheŵa, chinyanja',
  },
  { code: 'ZH', name: 'Chinese', nativeName: '中文 (Zhōngwén), 汉语, 漢語' },
  { code: 'CV', name: 'Chuvash', nativeName: 'чӑваш чӗлхи' },
  { code: 'KW', name: 'Cornish', nativeName: 'Kernewek' },
  { code: 'CO', name: 'Corsican', nativeName: 'corsu, lingua corsa' },
  { code: 'CR', name: 'Cree', nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ' },
  { code: 'HR', name: 'Croatian', nativeName: 'hrvatski' },
  { code: 'CS', name: 'Czech', nativeName: 'česky, čeština' },
  { code: 'DA', name: 'Danish', nativeName: 'dansk' },
  { code: 'DV', name: 'Divehi, Dhivehi, Maldivian', nativeName: 'ދިވެހި' },
  { code: 'NL', name: 'Dutch', nativeName: 'Nederlands, Vlaams' },
  { code: 'EN', name: 'English', nativeName: 'English' },
  { code: 'EO', name: 'Esperanto', nativeName: 'Esperanto' },
  { code: 'ET', name: 'Estonian', nativeName: 'eesti, eesti keel' },
  { code: 'EE', name: 'Ewe', nativeName: 'Eʋegbe' },
  { code: 'FO', name: 'Faroese', nativeName: 'føroyskt' },
  { code: 'FJ', name: 'Fijian', nativeName: 'vosa Vakaviti' },
  { code: 'FI', name: 'Finnish', nativeName: 'suomi, suomen kieli' },
  { code: 'FR', name: 'French', nativeName: 'français, langue française' },
  {
    code: 'FF',
    name: 'Fula, Fulah, Pulaar, Pular',
    nativeName: 'Fulfulde, Pulaar, Pular',
  },
  { code: 'GL', name: 'Galician', nativeName: 'Galego' },
  { code: 'KA', name: 'Georgian', nativeName: 'ქართული' },
  { code: 'de', name: 'German', nativeName: 'Deutsch' },
  { code: 'EL', name: 'Greek, Modern', nativeName: 'Ελληνικά' },
  { code: 'GN', name: 'Guaraní', nativeName: 'Avañeẽ' },
  { code: 'GU', name: 'Gujarati', nativeName: 'ગુજરાતી' },
  { code: 'HT', name: 'Haitian, Haitian Creole', nativeName: 'Kreyòl ayisyen' },
  { code: 'HA', name: 'Hausa', nativeName: 'Hausa, هَوُسَ' },
  { code: 'HE', name: 'Hebrew (modern)', nativeName: 'עברית' },
  { code: 'HZ', name: 'Herero', nativeName: 'Otjiherero' },
  { code: 'HI', name: 'Hindi', nativeName: 'हिन्दी, हिंदी' },
  { code: 'HO', name: 'Hiri Motu', nativeName: 'Hiri Motu' },
  { code: 'HU', name: 'Hungarian', nativeName: 'Magyar' },
  { code: 'IA', name: 'Interlingua', nativeName: 'Interlingua' },
  { code: 'ID', name: 'Indonesian', nativeName: 'Bahasa Indonesia' },
  {
    code: 'IE',
    name: 'Interlingue',
    nativeName: 'Originally called Occidental; then Interlingue after WWII',
  },
  { code: 'GA', name: 'Irish', nativeName: 'Gaeilge' },
  { code: 'IG', name: 'Igbo', nativeName: 'Asụsụ Igbo' },
  { code: 'IK', name: 'Inupiaq', nativeName: 'Iñupiaq, Iñupiatun' },
  { code: 'IO', name: 'Ido', nativeName: 'Ido' },
  { code: 'IS', name: 'Icelandic', nativeName: 'Íslenska' },
  { code: 'IT', name: 'Italian', nativeName: 'Italiano' },
  { code: 'IU', name: 'Inuktitut', nativeName: 'ᐃᓄᒃᑎᑐᑦ' },
  { code: 'JA', name: 'Japanese', nativeName: '日本語 (にほんご／にっぽんご)' },
  { code: 'JV', name: 'Javanese', nativeName: 'basa Jawa' },
  {
    code: 'KL',
    name: 'Kalaallisut, Greenlandic',
    nativeName: 'kalaallisut, kalaallit oqaasii',
  },
  { code: 'KN', name: 'Kannada', nativeName: 'ಕನ್ನಡ' },
  { code: 'KR', name: 'Kanuri', nativeName: 'Kanuri' },
  { code: 'KS', name: 'Kashmiri', nativeName: 'कश्मीरी, كشميري‎' },
  { code: 'KK', name: 'Kazakh', nativeName: 'Қазақ тілі' },
  { code: 'KM', name: 'Khmer', nativeName: 'ភាសាខ្មែរ' },
  { code: 'KI', name: 'Kikuyu, Gikuyu', nativeName: 'Gĩkũyũ' },
  { code: 'RW', name: 'Kinyarwanda', nativeName: 'Ikinyarwanda' },
  { code: 'KY', name: 'Kirghiz, Kyrgyz', nativeName: 'кыргыз тили' },
  { code: 'KV', name: 'Komi', nativeName: 'коми кыв' },
  { code: 'KG', name: 'Kongo', nativeName: 'KiKongo' },
  {
    code: 'KO',
    name: 'Korean',
    nativeName: '한국어 (韓國語), 조선말 (朝鮮語)',
  },
  { code: 'KU', name: 'Kurdish', nativeName: 'Kurdî, كوردی‎' },
  { code: 'KJ', name: 'Kwanyama, Kuanyama', nativeName: 'Kuanyama' },
  { code: 'LA', name: 'Latin', nativeName: 'latine, lingua latina' },
  {
    code: 'LB',
    name: 'Luxembourgish, Letzeburgesch',
    nativeName: 'Lëtzebuergesch',
  },
  { code: 'LG', name: 'Luganda', nativeName: 'Luganda' },
  {
    code: 'LI',
    name: 'Limburgish, Limburgan, Limburger',
    nativeName: 'Limburgs',
  },
  { code: 'LN', name: 'Lingala', nativeName: 'Lingála' },
  { code: 'LO', name: 'Lao', nativeName: 'ພາສາລາວ' },
  { code: 'LT', name: 'Lithuanian', nativeName: 'lietuvių kalba' },
  { code: 'LU', name: 'Luba-Katanga', nativeName: '' },
  { code: 'LV', name: 'Latvian', nativeName: 'latviešu valoda' },
  { code: 'GV', name: 'Manx', nativeName: 'Gaelg, Gailck' },
  { code: 'MK', name: 'Macedonian', nativeName: 'македонски јазик' },
  { code: 'MG', name: 'Malagasy', nativeName: 'Malagasy fiteny' },
  { code: 'MS', name: 'Malay', nativeName: 'bahasa Melayu, بهاس ملايو‎' },
  { code: 'ML', name: 'Malayalam', nativeName: 'മലയാളം' },
  { code: 'MT', name: 'Maltese', nativeName: 'Malti' },
  { code: 'MI', name: 'Māori', nativeName: 'te reo Māori' },
  { code: 'MR', name: 'Marathi (Marāṭhī)', nativeName: 'मराठी' },
  { code: 'MH', name: 'Marshallese', nativeName: 'Kajin M̧ajeļ' },
  { code: 'MN', name: 'Mongolian', nativeName: 'монгол' },
  { code: 'NA', name: 'Nauru', nativeName: 'Ekakairũ Naoero' },
  { code: 'NV', name: 'Navajo, Navaho', nativeName: 'Diné bizaad, Dinékʼehǰí' },
  { code: 'NB', name: 'Norwegian Bokmål', nativeName: 'Norsk bokmål' },
  { code: 'ND', name: 'North Ndebele', nativeName: 'isiNdebele' },
  { code: 'NE', name: 'Nepali', nativeName: 'नेपाली' },
  { code: 'NG', name: 'Ndonga', nativeName: 'Owambo' },
  { code: 'NN', name: 'Norwegian Nynorsk', nativeName: 'Norsk nynorsk' },
  { code: 'NO', name: 'Norwegian', nativeName: 'Norsk' },
  { code: 'II', name: 'Nuosu', nativeName: 'ꆈꌠ꒿ Nuosuhxop' },
  { code: 'NR', name: 'South Ndebele', nativeName: 'isiNdebele' },
  { code: 'OC', name: 'Occitan', nativeName: 'Occitan' },
  { code: 'OJ', name: 'Ojibwe, Ojibwa', nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ' },
  {
    code: 'CU',
    name:
      'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    nativeName: 'ѩзыкъ словѣньскъ',
  },
  { code: 'OM', name: 'Oromo', nativeName: 'Afaan Oromoo' },
  { code: 'OR', name: 'Oriya', nativeName: 'ଓଡ଼ିଆ' },
  { code: 'OS', name: 'Ossetian, Ossetic', nativeName: 'ирон æвзаг' },
  { code: 'PA', name: 'Panjabi, Punjabi', nativeName: 'ਪੰਜਾਬੀ, پنجابی‎' },
  { code: 'PI', name: 'Pāli', nativeName: 'पाऴि' },
  { code: 'FA', name: 'Persian', nativeName: 'فارسی' },
  { code: 'PL', name: 'Polish', nativeName: 'polski' },
  { code: 'PS', name: 'Pashto, Pushto', nativeName: 'پښتو' },
  { code: 'PT', name: 'Portuguese', nativeName: 'Português' },
  { code: 'QU', name: 'Quechua', nativeName: 'Runa Simi, Kichwa' },
  { code: 'RM', name: 'Romansh', nativeName: 'rumantsch grischun' },
  { code: 'RN', name: 'Kirundi', nativeName: 'kiRundi' },
  { code: 'RO', name: 'Romanian, Moldavian, Moldovan', nativeName: 'română' },
  { code: 'RU', name: 'Russian', nativeName: 'русский язык' },
  { code: 'SA', name: 'Sanskrit (Saṁskṛta)', nativeName: 'संस्कृतम्' },
  { code: 'SC', name: 'Sardinian', nativeName: 'sardu' },
  { code: 'SD', name: 'Sindhi', nativeName: 'सिन्धी, سنڌي، سندھی‎' },
  { code: 'SE', name: 'Northern Sami', nativeName: 'Davvisámegiella' },
  { code: 'SM', name: 'Samoan', nativeName: 'gagana faa Samoa' },
  { code: 'SG', name: 'Sango', nativeName: 'yângâ tî sängö' },
  { code: 'SR', name: 'Serbian', nativeName: 'српски језик' },
  { code: 'GD', name: 'Scottish Gaelic, Gaelic', nativeName: 'Gàidhlig' },
  { code: 'SN', name: 'Shona', nativeName: 'chiShona' },
  { code: 'SI', name: 'Sinhala, Sinhalese', nativeName: 'සිංහල' },
  { code: 'SK', name: 'Slovak', nativeName: 'slovenčina' },
  { code: 'SL', name: 'Slovene', nativeName: 'slovenščina' },
  { code: 'SO', name: 'Somali', nativeName: 'Soomaaliga, af Soomaali' },
  { code: 'ST', name: 'Southern Sotho', nativeName: 'Sesotho' },
  { code: 'ES', name: 'Spanish, Castilian', nativeName: 'español, castellano' },
  { code: 'SU', name: 'Sundanese', nativeName: 'Basa Sunda' },
  { code: 'SW', name: 'Swahili', nativeName: 'Kiswahili' },
  { code: 'SS', name: 'Swati', nativeName: 'SiSwati' },
  { code: 'SV', name: 'Swedish', nativeName: 'svenska' },
  { code: 'TA', name: 'Tamil', nativeName: 'தமிழ்' },
  { code: 'TE', name: 'Telugu', nativeName: 'తెలుగు' },
  { code: 'TG', name: 'Tajik', nativeName: 'тоҷикӣ, toğikī, تاجیکی‎' },
  { code: 'TH', name: 'Thai', nativeName: 'ไทย' },
  { code: 'TI', name: 'Tigrinya', nativeName: 'ትግርኛ' },
  {
    code: 'BO',
    name: 'Tibetan Standard, Tibetan, Central',
    nativeName: 'བོད་ཡིག',
  },
  { code: 'TK', name: 'Turkmen', nativeName: 'Türkmen, Түркмен' },
  { code: 'TL', name: 'Tagalog', nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔' },
  { code: 'TN', name: 'Tswana', nativeName: 'Setswana' },
  { code: 'TO', name: 'Tonga (Tonga Islands)', nativeName: 'faka Tonga' },
  { code: 'TR', name: 'Turkish', nativeName: 'Türkçe' },
  { code: 'TS', name: 'Tsonga', nativeName: 'Xitsonga' },
  { code: 'TT', name: 'Tatar', nativeName: 'татарча, tatarça, تاتارچا‎' },
  { code: 'TW', name: 'Twi', nativeName: 'Twi' },
  { code: 'TY', name: 'Tahitian', nativeName: 'Reo Tahiti' },
  { code: 'UG', name: 'Uighur, Uyghur', nativeName: 'Uyƣurqə, ئۇيغۇرچە‎' },
  { code: 'UK', name: 'Ukrainian', nativeName: 'українська' },
  { code: 'UR', name: 'Urdu', nativeName: 'اردو' },
  { code: 'UZ', name: 'Uzbek', nativeName: 'zbek, Ўзбек, أۇزبېك‎' },
  { code: 'VE', name: 'Venda', nativeName: 'Tshivenḓa' },
  { code: 'VI', name: 'Vietnamese', nativeName: 'Tiếng Việt' },
  { code: 'VO', name: 'Volapük', nativeName: 'Volapük' },
  { code: 'WA', name: 'Walloon', nativeName: 'Walon' },
  { code: 'CY', name: 'Welsh', nativeName: 'Cymraeg' },
  { code: 'WO', name: 'Wolof', nativeName: 'Wollof' },
  { code: 'FY', name: 'Western Frisian', nativeName: 'Frysk' },
  { code: 'XH', name: 'Xhosa', nativeName: 'isiXhosa' },
  { code: 'YI', name: 'Yiddish', nativeName: 'ייִדיש' },
  { code: 'YO', name: 'Yoruba', nativeName: 'Yorùbá' },
  { code: 'ZA', name: 'Zhuang, Chuang', nativeName: 'Saɯ cueŋƅ, Saw cuengh' },
];
