import React from 'react';
import { Typography, Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useStyles } from './EmptyMessage.styles';

const EmptyMessage = ({ title, to, customMsg }) => {
  const classes = useStyles();
  const defaultMsg = `There are no ${title} yet, `;
  return (
    <Typography variant="overline" gutterBottom className={classes.root}>
      <Icon>info</Icon>&nbsp;{customMsg ? customMsg : defaultMsg}
      <Link className={classes.link} to={to}>
        Click here
      </Link>{' '}
      to add some.
    </Typography>
  );
};

export default EmptyMessage;
