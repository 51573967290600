import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  hideRequestSpinner,
  showRequestSpinner,
} from '../../redux/store/ui/spinner';
import { showSnakbar } from '../../redux/store/ui/snakbar';

const useGraphMutation = (props, update, customMsg, hideSnackBar) => {
  const dispatch = useDispatch();
  const [callBack, { loading, error, data }] = useMutation(props, {
    update: (cache, { data }) => (update ? update(data) : {}),
    onError: (error) => {},
  });

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        dispatch(showRequestSpinner());
      }, 0);
    }
    if (error) {
      setTimeout(() => {
        dispatch(hideRequestSpinner());
        dispatch(
          showSnakbar({
            severity: 'error',
            message: error?.message || 'Error occurred save/update record(s)',
          }),
        );
      }, 0);
    }
    if (data) {
      setTimeout(() => {
        dispatch(hideRequestSpinner());
        !hideSnackBar &&
          dispatch(
            showSnakbar({
              severity: 'success',
              message: customMsg
                ? customMsg
                : 'Successfully save/update record(s)',
            }),
          );
      }, 0);
    }
  }, [loading, error, data]);

  return [callBack, { loading, error, data }];
};

export default useGraphMutation;
