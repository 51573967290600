const targetsForUsers = [
  { value: 'firstName', label: 'First name', type: 'text' },
  { value: 'lastName', label: 'Last name', type: 'text' },
  { value: 'email', label: 'Email', type: 'text' },
  { value: 'isEnabled', label: 'Enable', type: 'boolean' },
  { value: 'userStatus', label: 'Status', type: 'selection' },
  { value: 'roleName', label: 'Role', type: 'selection' },
];

export default targetsForUsers;
