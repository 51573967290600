import React, { useEffect } from 'react';
import { useStyles } from './Deductions.styles';
import PaperContainer from '../../composition/PaperContainer';
import GridXHead from '../../components/GridX/GridXHead';
import { queryParamFilter } from '../../utils/history';
import { headCells } from './Deductions.helper';
import { useGraphLazyQuery } from '../../core/hooks';
import { QueryVariables } from '../../core/models/QueryFilter.model';
import { useDispatch, useSelector } from 'react-redux';
import EmptyMessage from '../../components/EmptyMessage';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import {
  selectDeductions,
  selectDeductionsPage,
  setDeductions,
  setDeductionsPage,
} from '../../redux/store/appData/deductions';
import { selectSpinnerLoading } from '../../redux/store/ui/spinner';
import {
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
  StyledTableCell,
} from '../../components/Table';
import { FETCH_DEDUCTIONS } from '../../graphql/query/deductions.query';
import { TableBody, TableCell, Typography } from '@material-ui/core';

const queryVariables = new QueryVariables();

const Deductions = ({ location }) => {
  const dispatch = useDispatch();
  const { qstring } = queryParamFilter(location);
  const classes = useStyles();
  const requestLoading = useSelector(selectSpinnerLoading);
  const deductions = useSelector(selectDeductions);
  const page = useSelector(selectDeductionsPage);

  const [getDeductions] = useGraphLazyQuery(FETCH_DEDUCTIONS, (data) => {
    dispatch(setDeductions(data.getDeductions));
  });

  const loadMoreDeductions = () => {
    dispatch(setDeductionsPage(page + 1));
    queryVariables.setOffset(page + 1);
    getDeductions({ variables: queryVariables });
  };

  useEffect(() => {
    if (deductions?.length === 0) {
      getDeductions();
    }
  }, []);

  return (
    <div id="Publishers-Page" className={classes.root}>
      <InfiniteScroll
        dataLength={deductions.length}
        next={loadMoreDeductions}
        hasMore={false}
        loader={
          <div className={classes.spinnerWrapper}>
            <CircularProgress
              style={{ width: '30px', height: '30px', margin: '10px' }}
            />
          </div>
        }
        style={{ overflow: 'hidden' }}
      >
        <div
          key="publishers-animation"
          className={
            deductions.length ? 'animate__animated animate__fadeIn' : ''
          }
        >
          <PaperContainer rounded>
            <StyledTableContainer>
              <StyledTable
                aria-labelledby="tableTitle"
                size={'medium'}
                aria-label="enhanced table"
              >
                <GridXHead
                  headCells={headCells}
                  classes={classes}
                  rowCount={deductions.length}
                  showTooltip={false}
                />
                <TableBody>
                  {deductions.map((row, index) => (
                    <React.Fragment key={row._id}>
                      <StyledTableRow index={index}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          <Typography>
                            {row?.externalWidgetInfo?.widgetName}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          <Typography>
                            {row?.websiteInfo?.websiteDomain}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          <Typography>
                            {row?.providerInfo?.providerName}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          <Typography>{row?.revenue}</Typography>
                        </StyledTableCell>
                        <TableCell className={classes.tableCell} />
                      </StyledTableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
            {!deductions.length && !requestLoading && (
              <StyledTableRow>
                <StyledTableCell colSpan={7}>
                  <EmptyMessage
                    title="Deductions"
                    to={`/deductions/add${qstring}`}
                  />
                </StyledTableCell>
              </StyledTableRow>
            )}
          </PaperContainer>
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default Deductions;
