import {
  SET_PROVIDERS_ITEMS,
  SET_PROVIDERS_TOTAL_COUNT,
  SET_PROVIDERS_PAGE,
  APPEND_PROVIDERS_ITEMS,
  CLEAR_PROVIDERS,
} from '../../../../constants/actionTypes';

export const setProviders = (data) => async (dispatch) => {
  dispatch({
    type: SET_PROVIDERS_ITEMS,
    payload: data?.providers?.items,
  });
  dispatch({
    type: SET_PROVIDERS_TOTAL_COUNT,
    payload: data?.providers?.totalCount,
  });
};

export const appendProviders = (data) => async (dispatch) => {
  dispatch({
    type: APPEND_PROVIDERS_ITEMS,
    payload: data?.providers?.items,
  });
  dispatch({
    type: SET_PROVIDERS_TOTAL_COUNT,
    payload: data?.providers?.totalCount,
  });
};

export const setProvidersPage = (page) => ({
  type: SET_PROVIDERS_PAGE,
  payload: page,
});

export const clearProviders = () => ({
  type: CLEAR_PROVIDERS,
});
