import { history } from '../../../utils';
export const setQueries = (location, key, value) => {
  const params = new URLSearchParams(location.search);
  params.set(key, value);
  history.push({ search: params.toString() });
};

export const removeQuery = (location, key) => {
  const params = new URLSearchParams(location.search);
  if (params.has(key)) {
    params.delete(key);
    history.push({ search: params.toString() });
  }
};
