import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  label: {
    paddingLeft: '25px',
    maxWidth: 'calc(100% - 140px)',
    width: 'auto',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  percentage: {
    float: 'right',
    position: 'absolute',
    right: '15px',
  },
  value: {
    width: '70px',
    textAlign: 'right',
    display: 'inline-block',
  },
  root: {
    width: '100%',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '16px',
    transform: 'translateY(-50%)',
  },
}));
