import { Countries } from '../../utils';
import numeral from 'numeral';
import { NUMBER_HUMANIZE_FORMAT } from '../../constants/enum';

const metrics = {
  revenue: { label: 'Total Revenue', type: 'currency' },
  impressions: { label: 'Impressions', type: 'number' },
  clicks: { label: 'Clicks', type: 'number' },
  ctr: { label: 'CTR', type: 'percentage' },
  eCPC: { label: 'eCPC', type: 'currency' },
  eCPM: { label: 'eCPM', type: 'currency' },
};

const getMetricType = (metricType) => {
  switch (metricType) {
    case 'eCPC':
    case 'ecpc':
      return 'eCPC';
    case 'eCPM':
    case 'ecpm':
      return 'eCPM';
    default:
      return metricType.toLowerCase();
  }
};
export const refactorLineChartData = (chart, selectedReport) => {
  return chart.data
    .map((item) => ({
      reportDate: item.totals[0].value,
      ...Object.assign(
        {},
        ...selectedReport.metrics.map((metric) => ({
          [getMetricType(metric.metricType)]: item.totals?.find(
            (total) => total.key === getMetricType(metric.metricType),
          )?.value,
        })),
      ),
    }))
    .sort((a, b) => {
      return a.reportDate > b.reportDate ? 1 : -1;
    });
};

export const refactorPieChartData = (chart, selectedReport) => {
  return chart.data.map((item) => ({
    Dimension:
      item.breakdownFields[0].key === 'country'
        ? Countries?.find(
            (country) => country.code === item.breakdownFields[0].value,
          )?.name || item.breakdownFields[0].value
        : item.breakdownFields[0].value,
    ...Object.assign(
      {},
      ...selectedReport.metrics.map((metric) => ({
        [getMetricType(metric.metricType)]: item.totals?.find(
          (total) => total.key === getMetricType(metric.metricType),
        )?.value,
      })),
    ),
  }));
};

export const refactorBarChartData = (chart, selectedReport) => {
  return chart.data.map((item) => ({
    Dimension:
      item.breakdownFields[0].key === 'country'
        ? Countries?.find(
            (country) => country.code === item.breakdownFields[0].value,
          )?.name || item.breakdownFields[0].value
        : item.breakdownFields[0].value,
    ...Object.assign(
      {},
      ...selectedReport.metrics.map((metric) => ({
        [getMetricType(metric.metricType)]: item.totals?.find(
          (total) => total.key === getMetricType(metric.metricType),
        )?.value,
      })),
    ),
  }));
};

export const refactorGeoChartData = (chart, selectedReport) => {
  return chart.data.map((item) => ({
    country: item.breakdownFields[0]?.value,
    ...Object.assign(
      {},
      ...selectedReport.metrics.map((metric) => ({
        [getMetricType(metric.metricType)]: item.totals?.find(
          (total) => total.key === getMetricType(metric.metricType),
        )?.value,
      })),
    ),
  }));
};

const getBreakdownNameFromKey = (breakdown) => {
  switch (breakdown) {
    case 'date':
      return 'reportDate';
    case 'brand':
      return 'brandName';
    case 'widget':
      return 'widgetName';
    case 'publisher':
      return 'publisherName';
    case 'website':
      return 'displayName';
    case 'provider':
      return 'providerName';
    default:
      return breakdown;
  }
};

const getHeadNameFromKey = (breakdown) => {
  switch (breakdown) {
    case 'reportDate':
      return 'Date';
    case 'brandName':
      return 'Brand';
    case 'widgetName':
      return 'Widget';
    case 'publisherName':
      return 'Publisher';
    case 'displayName':
      return 'Website';
    case 'providerName':
      return 'Provider';
    case 'device':
      return 'Device';
    case 'date':
      return 'Date';
    case 'brand':
      return 'Brand';
    case 'widget':
      return 'Widget';
    case 'publisher':
      return 'Publisher';
    case 'website':
      return 'Website';
    case 'provider':
      return 'Provider';
    case 'country':
      return 'Country';
    default:
      return breakdown;
  }
};

export const refactorTableChartData = (chart, selectedReport) => {
  const breakdown1 = selectedReport.charts
    ?.find((chart) => chart.chartType === 'TableChart')
    ?.breakdown1?.toLowerCase();
  const chartBreakdown1 = getBreakdownNameFromKey(breakdown1);
  const breakdown2 = selectedReport.charts
    ?.find((chart) => chart.chartType === 'TableChart')
    ?.breakdown2?.toLowerCase();
  const chartBreakdown2 = getBreakdownNameFromKey(breakdown2);
  return chart.data.map((item) => ({
    DimensionHead:
      getHeadNameFromKey(breakdown1) ||
      getHeadNameFromKey(item.breakdownFields[0].key),
    Dimension:
      item.breakdownFields[0].key === 'country'
        ? Countries?.find(
            (country) => country.code === item.breakdownFields[0].value,
          )?.name || 'Other'
        : item.breakdownFields[0].value,
    ...Object.assign(
      {},
      ...chart.dimensions.map((dim) => ({
        [dim]: item.breakdownFields?.find(
          (item) => getHeadNameFromKey(item.key) === dim,
        )?.value,
      })),
      ...selectedReport.metrics.map((metric) => ({
        [getMetricType(metric.metricType)]: item.totals?.find(
          (total) =>
            getMetricType(total.key) === getMetricType(metric.metricType),
        )?.value,
        breakdown: item.breakdown?.map((breakItem1) => ({
          DimensionHead:
            getHeadNameFromKey(breakdown2) ||
            getHeadNameFromKey(
              breakItem1.breakdownFields?.find(
                (item) => item.key !== chartBreakdown1,
              )?.key,
            ),
          Dimension:
            breakItem1.breakdownFields?.find((item) =>
              chartBreakdown2
                ? item.key === chartBreakdown2
                : item.key !== chartBreakdown1,
            )?.key === 'country'
              ? Countries?.find(
                  (country) =>
                    country.code ===
                    breakItem1.breakdownFields?.find((item) =>
                      chartBreakdown2
                        ? item.key === chartBreakdown2
                        : item.key !== chartBreakdown1,
                    ).value,
                )?.name ||
                breakItem1.breakdownFields?.find((item) =>
                  chartBreakdown2
                    ? item.key === chartBreakdown2
                    : item.key !== chartBreakdown1,
                ).value
              : breakItem1.breakdownFields?.find((item) =>
                  chartBreakdown2
                    ? item.key === chartBreakdown2
                    : item.key !== chartBreakdown1,
                ).value,
          ...Object.assign(
            {},
            ...selectedReport.metrics.map((metric) => ({
              [getMetricType(metric.metricType)]: breakItem1.totals?.find(
                (total) =>
                  getMetricType(total.key) === getMetricType(metric.metricType),
              )?.value,
              breakdown: breakItem1.breakdown.map((breakItem2) => ({
                DimensionHead: getHeadNameFromKey(
                  breakItem2.breakdownFields?.find(
                    (item) =>
                      item.key !== chartBreakdown1 &&
                      item.key !== chartBreakdown2,
                  )?.key,
                ),
                Dimension:
                  breakItem2.breakdownFields?.find(
                    (item) =>
                      item.key !== chartBreakdown1 &&
                      item.key !== chartBreakdown2,
                  )?.key === 'country'
                    ? Countries?.find(
                        (country) =>
                          country.code ===
                          breakItem2.breakdownFields?.find(
                            (item) =>
                              item.key !== chartBreakdown1 &&
                              item.key !== chartBreakdown2,
                          ).value,
                      )?.name ||
                      breakItem2.breakdownFields?.find(
                        (item) =>
                          item.key !== chartBreakdown1 &&
                          item.key !== chartBreakdown2,
                      ).value
                    : breakItem2.breakdownFields?.find(
                        (item) =>
                          item.key !== chartBreakdown1 &&
                          item.key !== chartBreakdown2,
                      ).value,
                ...Object.assign(
                  {},
                  ...chart.dimensions
                    .filter(
                      (item) =>
                        item !== getHeadNameFromKey(breakdown1) &&
                        item !== getHeadNameFromKey(breakdown2),
                    )
                    .map((dim) => ({
                      [dim]: breakItem2.breakdownFields?.find(
                        (item) => getHeadNameFromKey(item.key) === dim,
                      )?.value,
                    })),
                  ...selectedReport.metrics.map((metric) => ({
                    [getMetricType(metric.metricType)]: breakItem2.totals?.find(
                      (total) =>
                        getMetricType(total.key) ===
                        getMetricType(metric.metricType),
                    )?.value,
                  })),
                ),
              })),
            })),
          ),
          ...Object.assign(
            {},
            ...chart.dimensions
              .filter(
                (item) =>
                  item !== getHeadNameFromKey(breakdown1) &&
                  item !== getHeadNameFromKey(breakdown2),
              )
              .map((dim) => ({
                [dim]: breakItem1.breakdownFields?.find(
                  (item) => getHeadNameFromKey(item.key) === dim,
                )?.value,
              })),
            ...selectedReport.metrics.map((metric) => ({
              [getMetricType(metric.metricType)]: breakItem1.totals?.find(
                (total) =>
                  getMetricType(total.key) === getMetricType(metric.metricType),
              )?.value,
            })),
          ),
        })),
      })),
    ),
  }));
};

export const parseCustomReportData = (
  data,
  selectedReport,
  setLineChart,
  setPieChart,
  setBarChart,
  setGeoChart,
  setNumberWidgets,
  setTableChart,
  setAllTimesRev,
) => {
  data.charts.charts.forEach((chart) => {
    if (chart.chartType === 'LineChart') {
      setLineChart({
        ...chart,
        data: refactorLineChartData(chart, selectedReport),
        tabs: selectedReport.metrics.map((metric) => metric.metricType),
        tabMetrics: selectedReport.metrics.map((metric) =>
          metric.metricType.toLowerCase(),
        ),
      });
    } else if (chart.chartType === 'PieChart') {
      setPieChart({
        ...chart,
        data: refactorPieChartData(chart, selectedReport),
        tabs: selectedReport.metrics.map((metric) =>
          metric.metricType.toLowerCase(),
        ),
      });
    } else if (chart.chartType === 'BarChart') {
      setBarChart({
        ...chart,
        data: refactorBarChartData(chart, selectedReport),
        tabs: selectedReport.metrics.map((metric) =>
          metric.metricType.toLowerCase(),
        ),
      });
    } else if (chart.chartType === 'GeoChart') {
      setGeoChart({
        ...chart,
        data: refactorGeoChartData(chart, selectedReport),
        tabs: selectedReport.metrics.map((metric) =>
          metric.metricType.toLowerCase(),
        ),
      });
    } else if (chart.chartType === 'TableChart') {
      const selectedReportChartData = selectedReport?.charts?.find(
        (chart) => chart.chartType === 'TableChart',
      );
      const breakdown1 = selectedReportChartData?.breakdown1;
      const breakdown2 = selectedReportChartData?.breakdown2;
      setTableChart({
        ...chart,
        data: refactorTableChartData(chart, selectedReport),
        tabs: selectedReport.metrics.map((metric) =>
          metric.metricType.toLowerCase(),
        ),
        dimensions: chart.dimensions.filter(
          (dim) => dim !== breakdown1 && dim !== breakdown2,
        ),
        count: chart.count,
      });
    }
    let numberWidgetsArray = [];

    data.charts.totals.values.map((total) => {
      numberWidgetsArray.push({
        label: metrics[total.metricName]?.label,
        value: total.metricValue,
        type: metrics[total.metricName]?.type,
        metric: total.metricName,
      });
    });
    setNumberWidgets(numberWidgetsArray);
    const allTimeRev = data.charts.allTimeTotals.values.find(
      (total) => total.metricName === 'revenue',
    )?.metricValue;
    if (allTimeRev) {
      setAllTimesRev(numeral(allTimeRev).format(NUMBER_HUMANIZE_FORMAT));
    }
  });
};
