import React from 'react';
import { useGraphMutation } from '../../core/hooks';
import { useStyles } from './ForgotPassword.styles';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Button, Grid, Link, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { SEND_FORGOT_PASSWORD_EMAIL } from '../../graphql/mutation';
import {
  ForgotPasswordModel,
  ForgotPasswordSchema,
} from '../../validation/schema/user.schema';
import { history } from '../../utils/history';

const ForgotPassword = () => {
  const classes = useStyles();
  const [sendForgotPasswordEmail] = useGraphMutation(
    SEND_FORGOT_PASSWORD_EMAIL,
    (data) => {
      if (data.forgotPasswordEmail.token)
        history.push(`/reset-password?token=${data.forgotPasswordEmail.token}`);
    },
    'An email with password reset instructions has been sent to your email address.',
  );

  const forgotPasswordFormik = useFormik({
    initialValues: { ...ForgotPasswordModel },
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values) => {
      sendForgotPasswordEmail({ variables: { email: values.email } });
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <form
          className={classes.form}
          onSubmit={forgotPasswordFormik.handleSubmit}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            autoComplete="email"
            autoFocus
            type="email"
            name="email"
            {...forgotPasswordFormik.getFieldProps('email')}
          />
          <Button
            type="submit"
            fullWidth
            color="primary"
            className={classes.submit}
            disabled={!forgotPasswordFormik.isValid}
          >
            Send
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/login" variant="body2">
                Login another account
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default ForgotPassword;
