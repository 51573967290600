import { gql } from '@apollo/client';

const CREATE_PUBLISHER = gql`
  mutation createPublisher($publisher: AddPublisherInput!) {
    addPublisher(addPublisherInput: $publisher) {
      _id
    }
  }
`;

const ADD_USER_TO_PUBLISHER = gql`
  mutation addUserToPublisher($user: AddUserToPublisherInput!) {
    addUserToPublisher(addUserToPublisherInput: $user) {
      _id
    }
  }
`;

const ADD_MANAGER_TO_PUBLISHER = gql`
  mutation addManagerToPublisher($user: AddUserToPublisherInput!) {
    addManagerToPublisher(addManagerToPublisherInput: $user) {
      _id
    }
  }
`;

const UPDATE_PUBLISHER = gql`
  mutation modifyPublisher($publisher: EditPublisherInput!) {
    updatePublisher(updatePublisherInput: $publisher) {
      _id
    }
  }
`;

const UPDATE_PUBLISHER_BULK_ENABLE_DISABLE = gql`
  mutation updateBulkEnableDisable($publisher: EnableDisableBulkInput!) {
    enableDisablePublishers(enableDisableBulkInput: $publisher) {
      ok
      Ids
    }
  }
`;

const DELETE_PUBLISHER = gql`
  mutation deletePublisher($id: String!) {
    deletePublisher(id: $id) {
      _id
      publisherStatus
    }
  }
`;

export {
  CREATE_PUBLISHER,
  UPDATE_PUBLISHER,
  ADD_MANAGER_TO_PUBLISHER,
  ADD_USER_TO_PUBLISHER,
  UPDATE_PUBLISHER_BULK_ENABLE_DISABLE,
  DELETE_PUBLISHER,
};
