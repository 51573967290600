export const headCells = [
  {
    id: 'widgetName',
    align: 'left',
    disablePadding: true,
    label: 'Widget',
  },
  {
    id: 'websiteDomain',
    align: 'left',
    disablePadding: true,
    label: 'Website Domain',
  },
  {
    id: 'providerName',
    align: 'left',
    disablePadding: true,
    label: 'Provider',
  },
  {
    id: 'revenue',
    align: 'left',
    disablePadding: false,
    label: 'Revenue',
  },
  { id: 'action', align: 'center', disablePadding: false, label: '' },
];
