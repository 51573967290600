const targetsForViewCustomReport = [
  { value: 'brandId', label: 'Brand', type: 'text' },
  { value: 'websiteId', label: 'Website', type: 'text' },
  { value: 'publisherId', label: 'Publisher', type: 'text' },
  { value: 'widgetId', label: 'Widget', type: 'text' },
  { value: 'providerId', label: 'Provider', type: 'text' },
  { value: 'country', label: 'Country', type: 'text' },
];

export default targetsForViewCustomReport;
