import React from 'react';
import { Tooltip, Icon, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const TooltipButton = ({ children, title, icon, ...rest }) => {
  const colorize = (theme, icon) => {
    let color = '';
    switch (icon) {
      case 'edit':
        color = theme.palette.primary.main;
        break;
      case 'visibility':
        color = theme.palette.secondary.main;
        break;
      case 'delete':
        color = '#D44000';
        break;
    }
    return color;
  };
  const useStyles = makeStyles((theme) => ({
    iconContainer: {
      '&:hover': {
        color: colorize(theme, icon),
      },
    },
  }));
  const classes = useStyles();
  return (
    <Tooltip title={<div style={{ fontSize: '15px' }}>{title}</div>} arrow>
      {icon ? (
        <IconButton {...rest}>
          <Icon className={classes.iconContainer}>{icon}</Icon>
        </IconButton>
      ) : (
        <Button {...rest}>{children}</Button>
      )}
    </Tooltip>
  );
};

export default TooltipButton;
