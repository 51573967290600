export const Countries = [
  { name: 'Arab World', group: 'Regions' },
  { name: 'Asia', group: 'Regions' },
  { name: 'LATAM (Latin America)', group: 'Regions' },
  { name: 'MENA (Middle East North Africa)', group: 'Regions' },
  { name: 'GCC (Gulf Cooperation Countries)', group: 'Regions' },
  { name: 'Americas', group: 'Regions' },
  { name: 'Central America', group: 'Regions' },
  { name: 'North America', group: 'Regions' },
  { name: 'Central & Eastern Europe', group: 'Regions' },
  { name: 'Europe', group: 'Regions' },
  { name: 'Western Europe', group: 'Regions' },
  { name: 'Southern Europe', group: 'Regions' },
  { name: 'All', code: 'ALL', group: 'Countries' },
  { name: 'Algeria', code: 'DZ', group: 'Countries' },
  { name: 'Angola', code: 'AO', group: 'Countries' },
  { name: 'Benin', code: 'BJ', group: 'Countries' },
  { name: 'Botswana', code: 'BW', group: 'Countries' },
  { name: 'British Indian Ocean Territory', code: 'IO', group: 'Countries' },
  { name: 'Burkina Faso', code: 'BF', group: 'Countries' },
  { name: 'Burundi', code: 'BI', group: 'Countries' },
  { name: 'Cabo Verde', code: 'CV', group: 'Countries' },
  { name: 'Cameroon', code: 'CM', group: 'Countries' },
  { name: 'Central African Republic', code: 'CF', group: 'Countries' },
  { name: 'Chad', code: 'TD', group: 'Countries' },
  { name: 'Comoros', code: 'KM', group: 'Countries' },
  { name: 'Congo', code: 'CG', group: 'Countries' },
  { name: 'Congo, Democratic Republic of the', code: 'CD', group: 'Countries' },
  { name: "Côte d'Ivoire", code: 'CI', group: 'Countries' },
  { name: 'Djibouti', code: 'DJ', group: 'Countries' },
  { name: 'Egypt', code: 'EG', group: 'Countries' },
  { name: 'Equatorial Guinea', code: 'GQ', group: 'Countries' },
  { name: 'Eritrea', code: 'ER', group: 'Countries' },
  { name: 'Eswatini', code: 'SZ', group: 'Countries' },
  { name: 'Ethiopia', code: 'ET', group: 'Countries' },
  { name: 'French Southern Territories', code: 'TF', group: 'Countries' },
  { name: 'Gabon', code: 'GA', group: 'Countries' },
  { name: 'Gambia', code: 'GM', group: 'Countries' },
  { name: 'Ghana', code: 'GH', group: 'Countries' },
  { name: 'Guinea', code: 'GN', group: 'Countries' },
  { name: 'Guinea-Bissau', code: 'GW', group: 'Countries' },
  { name: 'Kenya', code: 'KE', group: 'Countries' },
  { name: 'Lesotho', code: 'LS', group: 'Countries' },
  { name: 'Liberia', code: 'LR', group: 'Countries' },
  { name: 'Libya', code: 'LY', group: 'Countries' },
  { name: 'Madagascar', code: 'MG', group: 'Countries' },
  { name: 'Malawi', code: 'MW', group: 'Countries' },
  { name: 'Mali', code: 'ML', group: 'Countries' },
  { name: 'Mauritania', code: 'MR', group: 'Countries' },
  { name: 'Mauritius', code: 'MU', group: 'Countries' },
  { name: 'Mayotte', code: 'YT', group: 'Countries' },
  { name: 'Morocco', code: 'MA', group: 'Countries' },
  { name: 'Mozambique', code: 'MZ', group: 'Countries' },
  { name: 'Namibia', code: 'NA', group: 'Countries' },
  { name: 'Niger', code: 'NE', group: 'Countries' },
  { name: 'Nigeria', code: 'NG', group: 'Countries' },
  { name: 'Réunion', code: 'RE', group: 'Countries' },
  { name: 'Rwanda', code: 'RW', group: 'Countries' },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    code: 'SH',
    group: 'Countries',
  },
  { name: 'Sao Tome and Principe', code: 'ST', group: 'Countries' },
  { name: 'Senegal', code: 'SN', group: 'Countries' },
  { name: 'Seychelles', code: 'SC', group: 'Countries' },
  { name: 'Sierra Leone', code: 'SL', group: 'Countries' },
  { name: 'Somalia', code: 'SO', group: 'Countries' },
  { name: 'South Africa', code: 'ZA', group: 'Countries' },
  { name: 'South Sudan', code: 'SS', group: 'Countries' },
  { name: 'Sudan', code: 'SD', group: 'Countries' },
  { name: 'Tanzania, United Republic of', code: 'TZ', group: 'Countries' },
  { name: 'Togo', code: 'TG', group: 'Countries' },
  { name: 'Tunisia', code: 'TN', group: 'Countries' },
  { name: 'Uganda', code: 'UG', group: 'Countries' },
  { name: 'Western Sahara', code: 'EH', group: 'Countries' },
  { name: 'Zambia', code: 'ZM', group: 'Countries' },
  { name: 'Zimbabwe', code: 'ZW', group: 'Countries' },
  { name: 'Anguilla', code: 'AI', group: 'Countries' },
  { name: 'Antigua and Barbuda', code: 'AG', group: 'Countries' },
  { name: 'Argentina', code: 'AR', group: 'Countries' },
  { name: 'Aruba', code: 'AW', group: 'Countries' },
  { name: 'Bahamas', code: 'BS', group: 'Countries' },
  { name: 'Barbados', code: 'BB', group: 'Countries' },
  { name: 'Belize', code: 'BZ', group: 'Countries' },
  { name: 'Bermuda', code: 'BM', group: 'Countries' },
  { name: 'Bolivia (Plurinational State of)', code: 'BO', group: 'Countries' },
  { name: 'Bonaire, Sint Eustatius and Saba', code: 'BQ', group: 'Countries' },
  { name: 'Bouvet Island', code: 'BV', group: 'Countries' },
  { name: 'Brazil', code: 'BR', group: 'Countries' },
  { name: 'Canada', code: 'CA', group: 'Countries' },
  { name: 'Cayman Islands', code: 'KY', group: 'Countries' },
  { name: 'Chile', code: 'CL', group: 'Countries' },
  { name: 'Colombia', code: 'CO', group: 'Countries' },
  { name: 'Costa Rica', code: 'CR', group: 'Countries' },
  { name: 'Cuba', code: 'CU', group: 'Countries' },
  { name: 'Curaçao', code: 'CW', group: 'Countries' },
  { name: 'Dominica', code: 'DM', group: 'Countries' },
  { name: 'Dominican Republic', code: 'DO', group: 'Countries' },
  { name: 'Ecuador', code: 'EC', group: 'Countries' },
  { name: 'El Salvador', code: 'SV', group: 'Countries' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK', group: 'Countries' },
  { name: 'French Guiana', code: 'GF', group: 'Countries' },
  { name: 'Greenland', code: 'GL', group: 'Countries' },
  { name: 'Grenada', code: 'GD', group: 'Countries' },
  { name: 'Guadeloupe', code: 'GP', group: 'Countries' },
  { name: 'Guatemala', code: 'GT', group: 'Countries' },
  { name: 'Guyana', code: 'GY', group: 'Countries' },
  { name: 'Haiti', code: 'HT', group: 'Countries' },
  { name: 'Honduras', code: 'HN', group: 'Countries' },
  { name: 'Jamaica', code: 'JM', group: 'Countries' },
  { name: 'Martinique', code: 'MQ', group: 'Countries' },
  { name: 'Mexico', code: 'MX', group: 'Countries' },
  { name: 'Montserrat', code: 'MS', group: 'Countries' },
  { name: 'Nicaragua', code: 'NI', group: 'Countries' },
  { name: 'Panama', code: 'PA', group: 'Countries' },
  { name: 'Paraguay', code: 'PY', group: 'Countries' },
  { name: 'Peru', code: 'PE', group: 'Countries' },
  { name: 'Puerto Rico', code: 'PR', group: 'Countries' },
  { name: 'Saint Barthélemy', code: 'BL', group: 'Countries' },
  { name: 'Saint Kitts and Nevis', code: 'KN', group: 'Countries' },
  { name: 'Saint Lucia', code: 'LC', group: 'Countries' },
  { name: 'Saint Martin (French part)', code: 'MF', group: 'Countries' },
  { name: 'Saint Pierre and Miquelon', code: 'PM', group: 'Countries' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC', group: 'Countries' },
  { name: 'Sint Maarten (Dutch part)', code: 'SX', group: 'Countries' },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
    group: 'Countries',
  },
  { name: 'Suriname', code: 'SR', group: 'Countries' },
  { name: 'Trinidad and Tobago', code: 'TT', group: 'Countries' },
  { name: 'Turks and Caicos Islands', code: 'TC', group: 'Countries' },
  { name: 'United States of America', code: 'US', group: 'Countries' },
  { name: 'Uruguay', code: 'UY', group: 'Countries' },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    code: 'VE',
    group: 'Countries',
  },
  { name: 'Virgin Islands (British)', code: 'VG', group: 'Countries' },
  { name: 'Virgin Islands (U.S.)', code: 'VI', group: 'Countries' },
  { name: 'Afghanistan', code: 'AF', group: 'Countries' },
  { name: 'Armenia', code: 'AM', group: 'Countries' },
  { name: 'Azerbaijan', code: 'AZ', group: 'Countries' },
  { name: 'Bahrain', code: 'BH', group: 'Countries' },
  { name: 'Bangladesh', code: 'BD', group: 'Countries' },
  { name: 'Bhutan', code: 'BT', group: 'Countries' },
  { name: 'Brunei Darussalam', code: 'BN', group: 'Countries' },
  { name: 'Cambodia', code: 'KH', group: 'Countries' },
  { name: 'China', code: 'CN', group: 'Countries' },
  { name: 'Cyprus', code: 'CY', group: 'Countries' },
  { name: 'Georgia', code: 'GE', group: 'Countries' },
  { name: 'Hong Kong', code: 'HK', group: 'Countries' },
  { name: 'India', code: 'IN', group: 'Countries' },
  { name: 'Indonesia', code: 'ID', group: 'Countries' },
  { name: 'Iran (Islamic Republic of)', code: 'IR', group: 'Countries' },
  { name: 'Iraq', code: 'IQ', group: 'Countries' },
  { name: 'Israel', code: 'IL', group: 'Countries' },
  { name: 'Japan', code: 'JP', group: 'Countries' },
  { name: 'Jordan', code: 'JO', group: 'Countries' },
  { name: 'Kazakhstan', code: 'KZ', group: 'Countries' },
  {
    name: "Korea (Democratic People's Republic of)",
    code: 'KP',
    group: 'Countries',
  },
  { name: 'Korea, Republic of', code: 'KR', group: 'Countries' },
  { name: 'Kuwait', code: 'KW', group: 'Countries' },
  { name: 'Kyrgyzstan', code: 'KG', group: 'Countries' },
  { name: "Lao People's Democratic Republic", code: 'LA', group: 'Countries' },
  { name: 'Lebanon', code: 'LB', group: 'Countries' },
  { name: 'Macao', code: 'MO', group: 'Countries' },
  { name: 'Malaysia', code: 'MY', group: 'Countries' },
  { name: 'Maldives', code: 'MV', group: 'Countries' },
  { name: 'Mongolia', code: 'MN', group: 'Countries' },
  { name: 'Myanmar', code: 'MM', group: 'Countries' },
  { name: 'Nepal', code: 'NP', group: 'Countries' },
  { name: 'Oman', code: 'OM', group: 'Countries' },
  { name: 'Pakistan', code: 'PK', group: 'Countries' },
  { name: 'Palestine, State of', code: 'PS', group: 'Countries' },
  { name: 'Philippines', code: 'PH', group: 'Countries' },
  { name: 'Qatar', code: 'QA', group: 'Countries' },
  { name: 'Saudi Arabia', code: 'SA', group: 'Countries' },
  { name: 'Singapore', code: 'SG', group: 'Countries' },
  { name: 'Sri Lanka', code: 'LK', group: 'Countries' },
  { name: 'Syrian Arab Republic', code: 'SY', group: 'Countries' },
  { name: 'Taiwan, Province of China', code: 'TW', group: 'Countries' },
  { name: 'Tajikistan', code: 'TJ', group: 'Countries' },
  { name: 'Thailand', code: 'TH', group: 'Countries' },
  { name: 'Timor-Leste', code: 'TL', group: 'Countries' },
  { name: 'Turkey', code: 'TR', group: 'Countries' },
  { name: 'Turkmenistan', code: 'TM', group: 'Countries' },
  { name: 'United Arab Emirates', code: 'AE', group: 'Countries' },
  { name: 'Uzbekistan', code: 'UZ', group: 'Countries' },
  { name: 'Viet Nam', code: 'VN', group: 'Countries' },
  { name: 'Yemen', code: 'YE', group: 'Countries' },
  { name: 'Åland Islands', code: 'AX', group: 'Countries' },
  { name: 'Albania', code: 'AL', group: 'Countries' },
  { name: 'Andorra', code: 'AD', group: 'Countries' },
  { name: 'Austria', code: 'AT', group: 'Countries' },
  { name: 'Belarus', code: 'BY', group: 'Countries' },
  { name: 'Belgium', code: 'BE', group: 'Countries' },
  { name: 'Bosnia and Herzegovina', code: 'BA', group: 'Countries' },
  { name: 'Bulgaria', code: 'BG', group: 'Countries' },
  { name: 'Croatia', code: 'HR', group: 'Countries' },
  { name: 'Czechia', code: 'CZ', group: 'Countries' },
  { name: 'Denmark', code: 'DK', group: 'Countries' },
  { name: 'Estonia', code: 'EE', group: 'Countries' },
  { name: 'Faroe Islands', code: 'FO', group: 'Countries' },
  { name: 'Finland', code: 'FI', group: 'Countries' },
  { name: 'France', code: 'FR', group: 'Countries' },
  { name: 'Germany', code: 'DE', group: 'Countries' },
  { name: 'Gibraltar', code: 'GI', group: 'Countries' },
  { name: 'Greece', code: 'GR', group: 'Countries' },
  { name: 'Guernsey', code: 'GG', group: 'Countries' },
  { name: 'Holy See', code: 'VA', group: 'Countries' },
  { name: 'Hungary', code: 'HU', group: 'Countries' },
  { name: 'Iceland', code: 'IS', group: 'Countries' },
  { name: 'Ireland', code: 'IE', group: 'Countries' },
  { name: 'Isle of Man', code: 'IM', group: 'Countries' },
  { name: 'Italy', code: 'IT', group: 'Countries' },
  { name: 'Jersey', code: 'JE', group: 'Countries' },
  { name: 'Latvia', code: 'LV', group: 'Countries' },
  { name: 'Liechtenstein', code: 'LI', group: 'Countries' },
  { name: 'Lithuania', code: 'LT', group: 'Countries' },
  { name: 'Luxembourg', code: 'LU', group: 'Countries' },
  { name: 'Malta', code: 'MT', group: 'Countries' },
  { name: 'Moldova, Republic of', code: 'MD', group: 'Countries' },
  { name: 'Monaco', code: 'MC', group: 'Countries' },
  { name: 'Montenegro', code: 'ME', group: 'Countries' },
  { name: 'Netherlands', code: 'NL', group: 'Countries' },
  { name: 'North Macedonia', code: 'MK', group: 'Countries' },
  { name: 'Norway', code: 'NO', group: 'Countries' },
  { name: 'Poland', code: 'PL', group: 'Countries' },
  { name: 'Portugal', code: 'PT', group: 'Countries' },
  { name: 'Romania', code: 'RO', group: 'Countries' },
  { name: 'Russian Federation', code: 'RU', group: 'Countries' },
  { name: 'San Marino', code: 'SM', group: 'Countries' },
  { name: 'Serbia', code: 'RS', group: 'Countries' },
  { name: 'Slovakia', code: 'SK', group: 'Countries' },
  { name: 'Slovenia', code: 'SI', group: 'Countries' },
  { name: 'Spain', code: 'ES', group: 'Countries' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ', group: 'Countries' },
  { name: 'Sweden', code: 'SE', group: 'Countries' },
  { name: 'Switzerland', code: 'CH', group: 'Countries' },
  { name: 'Ukraine', code: 'UA', group: 'Countries' },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    code: 'GB',
    group: 'Countries',
  },
  { name: 'American Samoa', code: 'AS', group: 'Countries' },
  { name: 'Australia', code: 'AU', group: 'Countries' },
  { name: 'Christmas Island', code: 'CX', group: 'Countries' },
  { name: 'Cocos (Keeling) Islands', code: 'CC', group: 'Countries' },
  { name: 'Cook Islands', code: 'CK', group: 'Countries' },
  { name: 'Fiji', code: 'FJ', group: 'Countries' },
  { name: 'French Polynesia', code: 'PF', group: 'Countries' },
  { name: 'Guam', code: 'GU', group: 'Countries' },
  { name: 'Heard Island and McDonald Islands', code: 'HM', group: 'Countries' },
  { name: 'Kiribati', code: 'KI', group: 'Countries' },
  { name: 'Marshall Islands', code: 'MH', group: 'Countries' },
  { name: 'Micronesia (Federated States of)', code: 'FM', group: 'Countries' },
  { name: 'Nauru', code: 'NR', group: 'Countries' },
  { name: 'New Caledonia', code: 'NC', group: 'Countries' },
  { name: 'New Zealand', code: 'NZ', group: 'Countries' },
  { name: 'Niue', code: 'NU', group: 'Countries' },
  { name: 'Norfolk Island', code: 'NF', group: 'Countries' },
  { name: 'Northern Mariana Islands', code: 'MP', group: 'Countries' },
  { name: 'Palau', code: 'PW', group: 'Countries' },
  { name: 'Papua New Guinea', code: 'PG', group: 'Countries' },
  { name: 'Pitcairn', code: 'PN', group: 'Countries' },
  { name: 'Samoa', code: 'WS', group: 'Countries' },
  { name: 'Solomon Islands', code: 'SB', group: 'Countries' },
  { name: 'Tokelau', code: 'TK', group: 'Countries' },
  { name: 'Tonga', code: 'TO', group: 'Countries' },
  { name: 'Tuvalu', code: 'TV', group: 'Countries' },
  {
    name: 'United States Minor Outlying Islands',
    code: 'UM',
    group: 'Countries',
  },
  { name: 'Vanuatu', code: 'VU', group: 'Countries' },
  { name: 'Wallis and Futuna', code: 'WF', group: 'Countries' },
  { name: 'Wallis and Futuna', code: 'WF', group: 'Countries' },
  { name: 'Antarctica', code: 'AQ', group: 'Countries' },
];

const LATAM = [
  { name: 'Brazil', code: 'BR', group: 'Countries' },
  { name: 'Mexico', code: 'MX', group: 'Countries' },
  { name: 'Colombia', code: 'CO', group: 'Countries' },
  { name: 'Argentina', code: 'AR', group: 'Countries' },
  { name: 'Peru', code: 'PE', group: 'Countries' },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    code: 'VE',
    group: 'Countries',
  },
  { name: 'Chile', code: 'CL', group: 'Countries' },
  { name: 'Ecuador', code: 'EC', group: 'Countries' },
  { name: 'Guatemala', code: 'GT', group: 'Countries' },
  { name: 'Cuba', code: 'CU', group: 'Countries' },
  { name: 'Bolivia (Plurinational State of)', code: 'BO', group: 'Countries' },
  { name: 'Dominican Republic', code: 'DO', group: 'Countries' },
  { name: 'Honduras', code: 'HN', group: 'Countries' },
  { name: 'Paraguay', code: 'PY', group: 'Countries' },
  { name: 'Nicaragua', code: 'NI', group: 'Countries' },
  { name: 'El Salvador', code: 'SV', group: 'Countries' },
  { name: 'Costa Rica', code: 'CR', group: 'Countries' },
  { name: 'Panama', code: 'PA', group: 'Countries' },
  { name: 'Puerto Rico', code: 'PR', group: 'Countries' },
  { name: 'Uruguay', code: 'UY', group: 'Countries' },
  { name: 'French Guiana', code: 'GF', group: 'Countries' },
  { name: 'Saint Martin (French part)', code: 'MF', group: 'Countries' },
  { name: 'Saint Barthélemy', code: 'BL', group: 'Countries' },
];

const MENA = [
  { name: 'Algeria', code: 'DZ', group: 'Countries' },
  { name: 'Iraq', code: 'IQ', group: 'Countries' },
  { name: 'Lebanon', code: 'LB', group: 'Countries' },
  { name: 'Palestine, State of', code: 'PS', group: 'Countries' },
  { name: 'Tunisia', code: 'TN', group: 'Countries' },
  { name: 'Bahrain', code: 'BH', group: 'Countries' },
  { name: 'Libya', code: 'LY', group: 'Countries' },
  { name: 'Qatar', code: 'QA', group: 'Countries' },
  { name: 'Turkey', code: 'TR', group: 'Countries' },
  { name: 'Egypt', code: 'EG', group: 'Countries' },
  { name: 'Jordan', code: 'JO', group: 'Countries' },
  { name: 'Morocco', code: 'MA', group: 'Countries' },
  { name: 'Saudi Arabia', code: 'SA', group: 'Countries' },
  { name: 'United Arab Emirates', code: 'AE', group: 'Countries' },
  { name: 'Iran (Islamic Republic of)', code: 'IR', group: 'Countries' },
  { name: 'Kuwait', code: 'KW', group: 'Countries' },
  { name: 'Oman', code: 'OM', group: 'Countries' },
  { name: 'Syrian Arab Republic', code: 'SY', group: 'Countries' },
  { name: 'Yemen', code: 'YE', group: 'Countries' },
  { name: 'Sudan', code: 'SD', group: 'Countries' },
];

const GCC = [
  { name: 'Bahrain', code: 'BH', group: 'Countries' },
  { name: 'Kuwait', code: 'KW', group: 'Countries' },
  { name: 'Oman', code: 'OM', group: 'Countries' },
  { name: 'Qatar', code: 'QA', group: 'Countries' },
  { name: 'Saudi Arabia', code: 'SA', group: 'Countries' },
  { name: 'United Arab Emirates', code: 'AE', group: 'Countries' },
];

const CentralAmerica = [
  { name: 'Belize', code: 'BZ', group: 'Countries' },
  { name: 'Costa Rica', code: 'CR', group: 'Countries' },
  { name: 'El Salvador', code: 'SV', group: 'Countries' },
  { name: 'Guatemala', code: 'GT', group: 'Countries' },
  { name: 'Honduras', code: 'HN', group: 'Countries' },
  { name: 'Nicaragua', code: 'NI', group: 'Countries' },
  { name: 'Panama', code: 'PA', group: 'Countries' },
];

const NorthAmerica = [
  { name: 'United States of America', code: 'US', group: 'Countries' },
  { name: 'Mexico', code: 'MX', group: 'Countries' },
  { name: 'Canada', code: 'CA', group: 'Countries' },
  { name: 'Guatemala', code: 'GT', group: 'Countries' },
  { name: 'Cuba', code: 'CU', group: 'Countries' },
  { name: 'Haiti', code: 'HT', group: 'Countries' },
  { name: 'Dominican Republic', code: 'DO', group: 'Countries' },
  { name: 'Honduras', code: 'HN', group: 'Countries' },
  { name: 'El Salvador', code: 'SV', group: 'Countries' },
  { name: 'Nicaragua', code: 'NI', group: 'Countries' },
  { name: 'Costa Rica', code: 'CR', group: 'Countries' },
  { name: 'Panama', code: 'PA', group: 'Countries' },
  { name: 'Puerto Rico', code: 'PR', group: 'Countries' },
  { name: 'Jamaica', code: 'JM', group: 'Countries' },
  { name: 'Trinidad and Tobago', code: 'TT', group: 'Countries' },
  { name: 'Guadeloupe', code: 'GP', group: 'Countries' },
  { name: 'Martinique', code: 'MQ', group: 'Countries' },
  { name: 'Bahamas', code: 'BS', group: 'Countries' },
  { name: 'Belize', code: 'BZ', group: 'Countries' },
  { name: 'Barbados', code: 'BB', group: 'Countries' },
  { name: 'Saint Lucia', code: 'LC', group: 'Countries' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC', group: 'Countries' },
  { name: 'Grenada', code: 'GD', group: 'Countries' },
  { name: 'Antigua and Barbuda', code: 'AG', group: 'Countries' },
  { name: 'Dominica', code: 'DM', group: 'Countries' },
  { name: 'Bermuda', code: 'BM', group: 'Countries' },
  { name: 'Cayman Islands', code: 'KY', group: 'Countries' },
  { name: 'Greenland', code: 'GL', group: 'Countries' },
  { name: 'Saint Kitts and Nevis', code: 'KN', group: 'Countries' },
  { name: 'Sint Maarten (Dutch part)', code: 'SX', group: 'Countries' },
  { name: 'Turks and Caicos Islands', code: 'TC', group: 'Countries' },
  { name: 'Saint Martin (French part)', code: 'MF', group: 'Countries' },
  { name: 'Anguilla', code: 'AI', group: 'Countries' },
  { name: 'Saint Barthélemy', code: 'BL', group: 'Countries' },
  { name: 'Saint Pierre and Miquelon', code: 'PM', group: 'Countries' },
  { name: 'Montserrat', code: 'MS', group: 'Countries' },
];

const CentralEasternEurope = [
  { name: 'Estonia', code: 'EE', group: 'Countries' },
  { name: 'Latvia', code: 'LV', group: 'Countries' },
  { name: 'Lithuania', code: 'LT', group: 'Countries' },
  { name: 'Poland', code: 'PL', group: 'Countries' },
  { name: 'Germany', code: 'DE', group: 'Countries' },
  { name: 'Slovakia', code: 'SK', group: 'Countries' },
  { name: 'Hungary', code: 'HU', group: 'Countries' },
  { name: 'Romania', code: 'RO', group: 'Countries' },
  { name: 'Bulgaria', code: 'BG', group: 'Countries' },
  { name: 'Slovenia', code: 'SI', group: 'Countries' },
  { name: 'Croatia', code: 'HR', group: 'Countries' },
  { name: 'Albania', code: 'AL', group: 'Countries' },
  { name: 'Montenegro', code: 'ME', group: 'Countries' },
  { name: 'Serbia', code: 'RS', group: 'Countries' },
  { name: 'North Macedonia', code: 'MK', group: 'Countries' },
  { name: 'Bosnia and Herzegovina', code: 'BA', group: 'Countries' },
];

const WesternEurope = [
  { name: 'Austria', code: 'AT', group: 'Countries' },
  { name: 'Belgium', code: 'BE', group: 'Countries' },
  { name: 'France', code: 'FR', group: 'Countries' },
  { name: 'Germany', code: 'DE', group: 'Countries' },
  { name: 'Ireland', code: 'IE', group: 'Countries' },
  { name: 'Liechtenstein', code: 'LI', group: 'Countries' },
  { name: 'Luxembourg', code: 'LU', group: 'Countries' },
  { name: 'Monaco', code: 'MC', group: 'Countries' },
  { name: 'Netherlands', code: 'NL', group: 'Countries' },
  { name: 'Switzerland', code: 'CH', group: 'Countries' },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    code: 'GB',
    group: 'Countries',
  },
];

const SouthernEurope = [
  { name: 'Albania', code: 'AL', group: 'Countries' },
  { name: 'Bosnia and Herzegovina', code: 'BA', group: 'Countries' },
  { name: 'Croatia', code: 'HR', group: 'Countries' },
  { name: 'Greece', code: 'GR', group: 'Countries' },
  { name: 'Italy', code: 'IT', group: 'Countries' },
  { name: 'Malta', code: 'MT', group: 'Countries' },
  { name: 'Montenegro', code: 'ME', group: 'Countries' },
  { name: 'North Macedonia', code: 'MK', group: 'Countries' },
  { name: 'Portugal', code: 'PT', group: 'Countries' },
  { name: 'Serbia', code: 'RS', group: 'Countries' },
  { name: 'Slovenia', code: 'SI', group: 'Countries' },
  { name: 'Spain', code: 'ES', group: 'Countries' },
  { name: 'Turkey', code: 'TR', group: 'Countries' },
];

const Asia = [
  { name: 'Russian Federation', code: 'RU', group: 'Countries' },
  { name: 'China', code: 'CN', group: 'Countries' },
  { name: 'India', code: 'IN', group: 'Countries' },
  { name: 'Kazakhstan', code: 'KZ', group: 'Countries' },
  { name: 'Saudi Arabia', code: 'SA', group: 'Countries' },
  { name: 'Iran (Islamic Republic of)', code: 'IR', group: 'Countries' },
  { name: 'Mongolia', code: 'MN', group: 'Countries' },
  { name: 'Indonesia', code: 'ID', group: 'Countries' },
  { name: 'Pakistan', code: 'PK', group: 'Countries' },
  { name: 'Turkey', code: 'TR', group: 'Countries' },
  { name: 'Myanmar', code: 'MM', group: 'Countries' },
  { name: 'Afghanistan', code: 'AF', group: 'Countries' },
  { name: 'Yemen', code: 'YE', group: 'Countries' },
  { name: 'Thailand', code: 'TH', group: 'Countries' },
  { name: 'Turkmenistan', code: 'TM', group: 'Countries' },
  { name: 'Uzbekistan', code: 'UZ', group: 'Countries' },
  { name: 'Iraq', code: 'IQ', group: 'Countries' },
  { name: 'Japan', code: 'JP', group: 'Countries' },
  { name: 'Viet Nam', code: 'VN', group: 'Countries' },
  { name: 'Malaysia', code: 'MY', group: 'Countries' },
  { name: 'Oman', code: 'OM', group: 'Countries' },
  { name: 'Philippines', code: 'PH', group: 'Countries' },
  { name: 'Kyrgyzstan', code: 'KG', group: 'Countries' },
  { name: 'Syrian Arab Republic', code: 'SY', group: 'Countries' },
  { name: 'Cambodia', code: 'KH', group: 'Countries' },
  { name: 'Bangladesh', code: 'BD', group: 'Countries' },
  { name: 'Nepal', code: 'NP', group: 'Countries' },
  { name: 'Tajikistan', code: 'TJ', group: 'Countries' },
  {
    name: "Korea (Democratic People's Republic of)",
    code: 'KP',
    group: 'Countries',
  },
  { name: 'Korea, Republic of', code: 'KR', group: 'Countries' },
  { name: 'Jordan', code: 'JO', group: 'Countries' },
  { name: 'Azerbaijan', code: 'AZ', group: 'Countries' },
  { name: 'United Arab Emirates', code: 'AE', group: 'Countries' },
  { name: 'Georgia', code: 'GE', group: 'Countries' },
  { name: 'Sri Lanka', code: 'LK', group: 'Countries' },
  { name: 'Bhutan', code: 'BT', group: 'Countries' },
  { name: 'Taiwan, Province of China', code: 'TW', group: 'Countries' },
  { name: 'Armenia', code: 'AM', group: 'Countries' },
  { name: 'Israel', code: 'IL', group: 'Countries' },
  { name: 'Kuwait', code: 'KW', group: 'Countries' },
  { name: 'Timor-Leste', code: 'TL', group: 'Countries' },
  { name: 'Qatar', code: 'QA', group: 'Countries' },
  { name: 'Lebanon', code: 'LB', group: 'Countries' },
  { name: 'Cyprus', code: 'CY', group: 'Countries' },
  { name: 'Palestine, State of', code: 'PS', group: 'Countries' },
  { name: 'Brunei Darussalam', code: 'BN', group: 'Countries' },
  { name: 'Bahrain', code: 'BH', group: 'Countries' },
  { name: 'Singapore', code: 'SG', group: 'Countries' },
  { name: 'Maldives', code: 'MV', group: 'Countries' },
];

const ArabWorld = [
  { name: 'Algeria', code: 'DZ', group: 'Countries' },
  { name: 'Bahrain', code: 'BH', group: 'Countries' },
  { name: 'Comoros', code: 'KM', group: 'Countries' },
  { name: 'Djibouti', code: 'DJ', group: 'Countries' },
  { name: 'Egypt', code: 'EG', group: 'Countries' },
  { name: 'Iraq', code: 'IQ', group: 'Countries' },
  { name: 'Jordan', code: 'JO', group: 'Countries' },
  { name: 'Kuwait', code: 'KW', group: 'Countries' },
  { name: 'Lebanon', code: 'LB', group: 'Countries' },
  { name: 'Libya', code: 'LY', group: 'Countries' },
  { name: 'Mauritania', code: 'MR', group: 'Countries' },
  { name: 'Morocco', code: 'MA', group: 'Countries' },
  { name: 'Oman', code: 'OM', group: 'Countries' },
  { name: 'Palestine, State of', code: 'PS', group: 'Countries' },
  { name: 'Qatar', code: 'QA', group: 'Countries' },
  { name: 'Saudi Arabia', code: 'SA', group: 'Countries' },
  { name: 'Somalia', code: 'SO', group: 'Countries' },
  { name: 'Sudan', code: 'SD', group: 'Countries' },
  { name: 'Syrian Arab Republic', code: 'SY', group: 'Countries' },
  { name: 'Tunisia', code: 'TN', group: 'Countries' },
  { name: 'United Arab Emirates', code: 'AE', group: 'Countries' },
  { name: 'Yemen', code: 'YE', group: 'Countries' },
];

const Europe = [
  { name: 'Austria', code: 'AT', group: 'Countries' },
  { name: 'Belgium', code: 'BE', group: 'Countries' },
  { name: 'Bulgaria', code: 'BG', group: 'Countries' },
  { name: 'Croatia', code: 'HR', group: 'Countries' },
  { name: 'Cyprus', code: 'CY', group: 'Countries' },
  { name: 'Czechia', code: 'CZ', group: 'Countries' },
  { name: 'Denmark', code: 'DK', group: 'Countries' },
  { name: 'Estonia', code: 'EE', group: 'Countries' },
  { name: 'Finland', code: 'FI', group: 'Countries' },
  { name: 'France', code: 'FR', group: 'Countries' },
  { name: 'Germany', code: 'DE', group: 'Countries' },
  { name: 'Greece', code: 'GR', group: 'Countries' },
  { name: 'Hungary', code: 'HU', group: 'Countries' },
  { name: 'Ireland', code: 'IE', group: 'Countries' },
  { name: 'Italy', code: 'IT', group: 'Countries' },
  { name: 'Latvia', code: 'LV', group: 'Countries' },
  { name: 'Lithuania', code: 'LT', group: 'Countries' },
  { name: 'Luxembourg', code: 'LU', group: 'Countries' },
  { name: 'Malta', code: 'MT', group: 'Countries' },
  { name: 'Netherlands', code: 'NL', group: 'Countries' },
  { name: 'Poland', code: 'PL', group: 'Countries' },
  { name: 'Portugal', code: 'PT', group: 'Countries' },
  { name: 'Romania', code: 'RO', group: 'Countries' },
  { name: 'Slovakia', code: 'SK', group: 'Countries' },
  { name: 'Slovenia', code: 'SI', group: 'Countries' },
  { name: 'Spain', code: 'ES', group: 'Countries' },
  { name: 'Sweden', code: 'SE', group: 'Countries' },
];

const Americas = [
  { name: 'United States of America', code: 'US', group: 'Countries' },
  { name: 'Brazil', code: 'BR', group: 'Countries' },
  { name: 'Mexico', code: 'MX', group: 'Countries' },
  { name: 'Colombia', code: 'CO', group: 'Countries' },
  { name: 'Argentina', code: 'AR', group: 'Countries' },
  { name: 'Canada', code: 'CA', group: 'Countries' },
  { name: 'Peru', code: 'PE', group: 'Countries' },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    code: 'VE',
    group: 'Countries',
  },
  { name: 'Chile', code: 'CL', group: 'Countries' },
  { name: 'Ecuador', code: 'EC', group: 'Countries' },
  { name: 'Guatemala', code: 'GT', group: 'Countries' },
  { name: 'Cuba', code: 'CU', group: 'Countries' },
  { name: 'Bolivia (Plurinational State of)', code: 'BO', group: 'Countries' },
  { name: 'Haiti', code: 'HT', group: 'Countries' },
  { name: 'Dominican Republic', code: 'DO', group: 'Countries' },
  { name: 'Paraguay', code: 'PY', group: 'Countries' },
  { name: 'Honduras', code: 'HN', group: 'Countries' },
  { name: 'El Salvador', code: 'SV', group: 'Countries' },
  { name: 'Nicaragua', code: 'NI', group: 'Countries' },
  { name: 'Costa Rica', code: 'CR', group: 'Countries' },
  { name: 'Panama', code: 'PA', group: 'Countries' },
  { name: 'Uruguay', code: 'UY', group: 'Countries' },
  { name: 'Puerto Rico', code: 'PR', group: 'Countries' },
  { name: 'Jamaica', code: 'JM', group: 'Countries' },
  { name: 'Trinidad and Tobago', code: 'TT', group: 'Countries' },
  { name: 'Guadeloupe', code: 'GP', group: 'Countries' },
  { name: 'Suriname', code: 'SR', group: 'Countries' },
  { name: 'Guyana', code: 'GY', group: 'Countries' },
  { name: 'Martinique', code: 'MQ', group: 'Countries' },
  { name: 'Bahamas', code: 'BS', group: 'Countries' },
  { name: 'Belize', code: 'BZ', group: 'Countries' },
  { name: 'Barbados', code: 'BB', group: 'Countries' },
  { name: 'Saint Lucia', code: 'LC', group: 'Countries' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC', group: 'Countries' },
  { name: 'Grenada', code: 'GD', group: 'Countries' },
  { name: 'Antigua and Barbuda', code: 'AG', group: 'Countries' },
  { name: 'Dominica', code: 'DM', group: 'Countries' },
  { name: 'Bermuda', code: 'BM', group: 'Countries' },
  { name: 'Cayman Islands', code: 'KY', group: 'Countries' },
  { name: 'Greenland', code: 'GL', group: 'Countries' },
  { name: 'Saint Kitts and Nevis', code: 'KN', group: 'Countries' },
  { name: 'Sint Maarten (Dutch part)', code: 'SX', group: 'Countries' },
  { name: 'Turks and Caicos Islands', code: 'TC', group: 'Countries' },
  { name: 'Saint Martin (French part)', code: 'MF', group: 'Countries' },
  { name: 'Anguilla', code: 'AI', group: 'Countries' },
  { name: 'Saint Barthélemy', code: 'BL', group: 'Countries' },
  { name: 'Saint Pierre and Miquelon', code: 'PM', group: 'Countries' },
  { name: 'Montserrat', code: 'MS', group: 'Countries' },
  { name: 'French Guiana', code: 'GF', group: 'Countries' },
  { name: 'Curaçao', code: 'CW', group: 'Countries' },
  { name: 'Aruba', code: 'AW', group: 'Countries' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK', group: 'Countries' },
];

export const getCountryListByRegion = (name) => {
  switch (name) {
    case 'LATAM (Latin America)':
      return LATAM;
    case 'MENA (Middle East North Africa)':
      return MENA;
    case 'GCC (Gulf Cooperation Countries)':
      return GCC;
    case 'Central America':
      return CentralAmerica;
    case 'North America':
      return NorthAmerica;
    case 'Central & Eastern Europe':
      return CentralEasternEurope;
    case 'Western Europe':
      return WesternEurope;
    case 'Southern Europe':
      return SouthernEurope;
    case 'Asia':
      return Asia;
    case 'Arab World':
      return ArabWorld;
    case 'Europe':
      return Europe;
    case 'Americas':
      return Americas;
    case 'all':
      let allCountries = Countries.filter(
        (country) => country.group === 'Countries' && country.name !== 'All',
      ).sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
      return allCountries;
    default:
      return null;
  }
};
