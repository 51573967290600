import React from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { useStyles } from './GraphTabs.styles';

const GraphTabs = ({ value, tabs, handleChange, theme, isShown }) => {
  const classes = useStyles(theme);
  const style = isShown ? { marginBottom: '20px' } : { display: 'none' };
  return (
    <Tabs
      value={value}
      indicatorColor=""
      textColor="#A0A5BA"
      onChange={handleChange}
      style={style}
    >
      {tabs.map((key, index) => (
        <Tab classes={classes} label={key} key={`${key}-${index}`} />
      ))}
    </Tabs>
  );
};
export default GraphTabs;
