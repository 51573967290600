import React, { useEffect, useRef, useState } from 'react';
import { Button, Dialog, Icon } from '@material-ui/core';
import DialogContent from '../../composition/Dialog/DialogContent';
import DialogTitle from '../../composition/Dialog/DialogTitle';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import DialogActions from '../../composition/Dialog/DialogActions';
import { useStyles } from './WidgetCode.styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const WidgetCode = ({ open, widget, onClosed, title }) => {
  const Iframe_OPTION = 2;
  const AMP_CODE_OPTION = 1;
  const REGULAR_CODE_OPTION = 0;
  const classes = useStyles();
  const [widgetCode, setWidgetCode] = useState('');
  const [value, setValue] = useState(0);
  const textArea = useRef();
  const [copied, setCopied] = useState(false);
  const copyCodeToClipboard = (e) => {
    textArea.current.textContent = widgetCode;
    textArea.current.focus();
    textArea.current.select();
    const cmd = copied ? 'cut' : 'copy';
    document.execCommand(cmd);
    setCopied(!copied);
  };
  const handleChange = (e, val) => {
    setWidgetCode(createWidgetCode(val));
    setValue(val);
    setCopied(false);
  };
  const createWidgetCode = (type) => {
    if (type == Iframe_OPTION) {
      return `<iframe 
src="//pub.ketshwa.com/ketshwa.html?wid=${widget._id}&wn=${widget.widgetId}"
scrolling="no" 
width="100%"  
sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-popups allow-popups allow-popups-to-escape-sandbox allow-scripts"  
height="600" 
frameBorder="0">
</iframe>`;
    }
    if (type == AMP_CODE_OPTION) {
      return `<amp-ad width="250" height="300" 
type="ketshwa" 
data-widgetid="${widget._id}"
data-externalid="${widget.widgetId}"></amp-ad>`;
    }
    return `<div id="widget_${widget._id}"></div>
<script>
!function(){var t="${widget._id}",e="widget_${widget._id}",a=document.createElement("script");a.type="text/javascript",a.setAttribute("data-cfasyn","false"),a.src="${process.env.REACT_APP_CLOUDFRONT}/m/p/${widget._id}/${widget.widgetId}.js",a.async=!0,document.body.appendChild(a),a.onload=function(){KetshwaSDK.showWidget(t,e)}}();
</script>`;
  };
  useEffect(() => {
    if (!open) {
      setCopied(false);
    }
    if (open) {
      const widgetCode = createWidgetCode(value);

      setWidgetCode(widgetCode);
    } else {
      setWidgetCode('');
    }
  }, [open]);
  return (
    <>
      <Dialog
        onClose={onClosed}
        fullWidth={true}
        maxWidth={'sm'}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent>
          <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab label="Regular Code" value={REGULAR_CODE_OPTION} />
            <Tab label="AMP" value={AMP_CODE_OPTION} />
            <Tab label="Iframe" value={Iframe_OPTION} />
          </Tabs>
          <SyntaxHighlighter
            language="javascript"
            style={docco}
            showLineNumbers={true}
          >
            {widgetCode}
          </SyntaxHighlighter>
          <textarea className={classes.textArea} ref={textArea} />
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelButton}
            onClick={onClosed}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={copied}
            onClick={copyCodeToClipboard}
            color="secondary"
            disableElevation
            variant="contained"
            className={classes.copyButton}
          >
            {!copied ? 'Copy' : 'Copied'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WidgetCode;
