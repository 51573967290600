import React, { useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import {
  selectFilter,
  selectFilterTargetType,
  selectFilterTarget,
  closeFilterPanel,
  addAndApplyFilter,
} from '../../redux/store/ui/filters';
import Selectbox from '../SelectBox';
import Button from '@material-ui/core/Button';
import BooleanSelection from './BooleanSelection';
import Selection from './Selection';
import getOptionValues from './helpers/getOptionValues';
import { useLocation } from 'react-router-dom';
import { Categories, Countries, Languages } from '../../utils';
import { WidgetTypes, DealTypes } from '../../utils/lookup';
import { useStyles } from './ValueSelector.styles';
import AutoCompleteBox from '../AutoCompleteBox';
import {
  getBrandAutoCompleteQuery,
  getPublisherAutoCompleteQuery,
  getWebsiteAutoCompleteQuery,
  getProviderAutoCompleteQuery,
  getWidgetAutoCompleteQuery,
  getUserAutoCompleteQuery,
} from '../../graphql/query/autocomplete.query';
import { optionsForDevice } from './options/optionsForDevice';

const getTargetLabel = (target, viewMode) => {
  switch (target) {
    case 'websiteId':
      return [
        'REPORTS_BY_PROVIDER',
        'REPORTS_BY_COUNTRY',
        'REPORTS_BY_WIDGET',
      ].includes(viewMode)
        ? 'websiteDomain'
        : 'displayName';
    case 'widgetId':
      return 'widgetName';
    case 'brandId':
      return 'brandName';
    case 'publisherId':
      return 'publisherName';
    case 'providerId':
      return 'providerName';
    case 'country':
      return 'name';
    case 'websiteCategory':
      return 'name';
    case 'websiteLanguage':
      return 'name';
    case 'widgetType':
      return 'widgetTypeName';
    case 'dealType':
      return 'dealTypeName';
    case 'device':
      return 'label';
    default:
      return target;
  }
};

const getTargetValue = (target) => {
  switch (target) {
    case 'websiteId':
      return '_id';
    case 'widgetId':
      return '_id';
    case 'brandId':
      return '_id';
    case 'publisherId':
      return '_id';
    case 'providerId':
      return '_id';
    case 'country':
      return 'code';
    case 'paymentType':
      return 'providerPaymentType';
    case 'websiteCategory':
      return 'value';
    case 'websiteLanguage':
      return 'code';
    case 'widgetType':
      return 'widgetTypeName';
    case 'dealType':
      return 'dealTypeName';
    case 'device':
      return 'value';
    default:
      return target;
  }
};

const ValueSelector = ({ viewMode }) => {
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const [isMoreThan, setIsMoreThan] = useState(true);
  const onChangeRadio = () => setIsMoreThan(!isMoreThan);
  const [fromDate, setFromDate] = useState(null);
  const [untilDate, setUntilDate] = useState(null);
  const target = useSelector(selectFilterTarget);
  const targetType = useSelector(selectFilterTargetType);
  const filter = useSelector(selectFilter);
  let body;

  const targetLabel = getTargetLabel(target, viewMode);
  const targetValue = getTargetValue(target);

  if (targetType === 'selection') {
    const optionValues = getOptionValues(viewMode, target);
    body = (
      <Selection
        options={optionValues}
        setValue={setValue}
        defaultValue={filter[viewMode][target]}
      />
    );
  } else if (targetType === 'text') {
    const getEntities = () => {
      switch (viewMode) {
        case 'DASHBOARD':
          return {
            query: getBrandAutoCompleteQuery(),
            queryFilter: 'brandName',
          };
        case 'USERS':
          if (target === 'firstName') {
            return {
              query: getUserAutoCompleteQuery(),
              queryFilter: 'firstName',
            };
          } else if (target === 'lastName') {
            return {
              query: getUserAutoCompleteQuery(),
              queryFilter: 'lastName',
            };
          } else if (target === 'email') {
            return {
              query: getUserAutoCompleteQuery(),
              queryFilter: 'email',
              optionLabel: 'description',
            };
          }
        case 'BRANDS':
          return {
            query: getBrandAutoCompleteQuery(),
            queryFilter: 'brandName',
          };
        case 'PUBLISHERS':
          return target === 'brandId'
            ? {
                query: getBrandAutoCompleteQuery(),
                queryFilter: 'brandName',
              }
            : {
                query: getPublisherAutoCompleteQuery(),
                queryFilter: 'publisherName',
              };
        case 'WEBSITES':
          if (target === 'publisherId') {
            return {
              query: getPublisherAutoCompleteQuery(),
              queryFilter: 'publisherName',
            };
          } else if (target === 'websiteCategory') {
            return [...Categories];
          } else if (target === 'websiteLanguage') {
            return [...Languages];
          } else if (target === 'websiteDomain') {
            return {
              query: getWebsiteAutoCompleteQuery(),
              queryFilter: 'displayName',
              optionLabel: 'description',
              optionValue: 'description',
            };
          } else {
            return {
              query: getWebsiteAutoCompleteQuery(),
              queryFilter: 'displayName',
            };
          }
        case 'WIDGETS':
          if (target === 'websiteId') {
            return {
              query: getWebsiteAutoCompleteQuery(),
              queryFilter: 'displayName',
            };
          } else if (target === 'widgetType') {
            return [...WidgetTypes];
          } else {
            return {
              query: getWidgetAutoCompleteQuery(),
              queryFilter: 'widgetName',
            };
          }
        case 'PROVIDERS':
          return target === 'dealType'
            ? [...DealTypes]
            : {
                query: getProviderAutoCompleteQuery(),
                queryFilter: 'providerName',
              };
        case 'SETUP':
          return {
            query: getBrandAutoCompleteQuery(),
            queryFilter: 'brandName',
          };
        case 'REPORTS_BY_PROVIDER':
          return target === 'providerId'
            ? {
                query: getProviderAutoCompleteQuery(),
                queryFilter: 'providerName',
              }
            : target === 'websiteId'
            ? {
                query: getWebsiteAutoCompleteQuery(),
                queryFilter: 'displayName',
                optionLabel: 'description',
              }
            : target === 'publisherId'
            ? {
                query: getPublisherAutoCompleteQuery(),
                queryFilter: 'publisherName',
              }
            : target === 'brandId'
            ? {
                query: getBrandAutoCompleteQuery(),
                queryFilter: 'brandName',
              }
            : [];
        case 'REPORTS_BY_COUNTRY':
          return target === 'websiteId'
            ? {
                query: getWebsiteAutoCompleteQuery(),
                queryFilter: 'displayName',
                optionLabel: 'description',
              }
            : [
                ...Countries.filter(
                  (item) => item.group === 'Countries' && item.name !== 'All',
                ),
                { name: 'Unknown', code: 'Unknown' },
              ];
        case 'REPORTS_BY_WIDGET':
          return target === 'widgetId'
            ? {
                query: getWidgetAutoCompleteQuery(),
                queryFilter: 'widgetName',
              }
            : target === 'websiteId'
            ? {
                query: getWebsiteAutoCompleteQuery(),
                queryFilter: 'displayName',
                optionLabel: 'description',
              }
            : [];
        case 'REPORTS_BY_WEBSITE':
          return target === 'websiteId'
            ? {
                query: getWebsiteAutoCompleteQuery(),
                queryFilter: 'displayName',
              }
            : target === 'publisherId'
            ? {
                query: getPublisherAutoCompleteQuery(),
                queryFilter: 'publisherName',
              }
            : [];
        case 'VIEW_CUSTOM_REPORT':
          return target === 'websiteId'
            ? {
                query: getWebsiteAutoCompleteQuery(),
                queryFilter: 'displayName',
              }
            : target === 'publisherId'
            ? {
                query: getPublisherAutoCompleteQuery(),
                queryFilter: 'publisherName',
              }
            : target === 'widgetId'
            ? {
                query: getWidgetAutoCompleteQuery(),
                queryFilter: 'widgetName',
              }
            : target === 'brandId'
            ? {
                query: getBrandAutoCompleteQuery(),
                queryFilter: 'brandName',
              }
            : target === 'providerId'
            ? {
                query: getProviderAutoCompleteQuery(),
                queryFilter: 'providerName',
              }
            : [
                ...Countries.filter(
                  (item) => item.group === 'Countries' && item.name !== 'All',
                ),
                { name: 'Unknown', code: 'Unknown' },
              ];
        case 'EARNINGS':
          return target === 'websiteId'
            ? {
                query: getWebsiteAutoCompleteQuery(),
                queryFilter: 'displayName',
              }
            : target === 'publisherId'
            ? {
                query: getPublisherAutoCompleteQuery(),
                queryFilter: 'publisherName',
              }
            : target === 'providerId'
            ? {
                query: getProviderAutoCompleteQuery(),
                queryFilter: 'providerName',
              }
            : [];
        case 'AD_GROUPS':
          return target === 'country'
            ? [
                ...Countries.filter(
                  (item) => item.group === 'Countries' && item.name !== 'All',
                ),
              ]
            : target === 'device'
            ? optionsForDevice
            : [];
        default:
          return [];
      }
    };

    const dataSource = [
      'country',
      'websiteCategory',
      'websiteLanguage',
      'widgetType',
      'dealType',
    ].includes(target)
      ? getEntities()
          .filter(
            (thing, index, self) =>
              index ===
              self.findIndex((t) => t[targetValue] === thing[targetValue]),
          )
          .map((v) => {
            return {
              value: v[targetValue],
              text: v[targetLabel],
            };
          })
      : getEntities();
    body = [
      'country',
      'websiteCategory',
      'websiteLanguage',
      'widgetType',
      'dealType',
    ].includes(target) ? (
      <Selectbox
        optionLabel="text"
        optionValue="value"
        options={dataSource}
        onChange={(e, value) => setValue(value)}
      />
    ) : (
      <AutoCompleteBox
        name={'valSelect'}
        query={dataSource.query}
        queryFilter={dataSource.queryFilter}
        optionLabel={dataSource.optionLabel || 'name'}
        optionValue={dataSource.optionValue || '_id'}
        onChange={(e, value) => {
          if (target.includes('Id')) {
            setValue(value._id);
          } else if (dataSource.optionLabel === 'description') {
            setValue(value.description);
          } else if (target === 'firstName') {
            setValue(value.name.split(' ')[0]);
          } else if (target === 'lastName') {
            setValue(value.name.split(' ')[1]);
          } else {
            setValue(value.name);
          }
        }}
      />
    );
  } else {
    body =
      targetType === 'boolean' ? (
        <BooleanSelection setValue={setValue} />
      ) : null;
  }

  const onSubmit = () => {
    let valueToSend = value;
    if (value && targetType === 'number') {
      valueToSend = isMoreThan ? `>=${value}` : `<=${value}`;
    } else if (targetType === 'date') {
      if (fromDate && untilDate) {
        valueToSend = `${fromDate}_${untilDate}`;
      } else if (fromDate) {
        valueToSend = `after_${fromDate}`;
      } else if (untilDate) {
        valueToSend = `before_${untilDate}`;
      }
    }
    batch(() => {
      if (valueToSend) {
        dispatch(
          addAndApplyFilter({
            location,
            viewMode,
            key: target,
            value: valueToSend,
          }),
        );
      }
      dispatch(closeFilterPanel());
    });
  };

  return (
    <>
      <div className={classes.valueSelector}>
        <div className={classes.body}>{body}</div>
        <div className={classes.footer}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={!value && !fromDate && !untilDate}
          >
            Add Filter
          </Button>
        </div>
      </div>
    </>
  );
};

export default ValueSelector;
