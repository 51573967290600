import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px',
  },
  tableHead: {
    backgroundColor: theme.palette.secondary.main,
    position: 'sticky',
    top: '0px',
    zIndex: '1',
  },
  tableRow: {
    borderRadius: '10px 10px 0px 0px',
  },
  tableCell: {
    borderBottom: 0,
    padding: '10px 0px 10px 0px',
    fontFamily: 'Poppins, sans-serif',
    marginLeft: '16px',
    //borderStyle: 'dotted',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));
