import {
  SET_CUSTOM_REPORTS_ITEMS,
  APPEND_CUSTOM_REPORTS_ITEMS,
  SET_CUSTOM_REPORTS_PAGE,
  SET_CUSTOM_REPORTS_TOTAL_COUNT,
  CLEAR_CUSTOM_REPORTS,
} from '../../../../constants/actionTypes';
import { initialState } from './InitialState';

export const customReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOM_REPORTS_ITEMS:
      return { ...state, items: action.payload };
    case APPEND_CUSTOM_REPORTS_ITEMS:
      return { ...state, items: [...state.items, ...action.payload] };
    case SET_CUSTOM_REPORTS_TOTAL_COUNT:
      return { ...state, totalCount: action.payload };
    case SET_CUSTOM_REPORTS_PAGE:
      return { ...state, page: action.payload };
    case CLEAR_CUSTOM_REPORTS:
      return { ...initialState };
    default:
      return state;
  }
};
