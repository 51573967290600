import {
  SET_CREATE_MODAL_IS_OPENED,
  SET_CREATED_USER,
  SET_DATE_RANGE_VALUE,
  SET_DIRECT_PARENT,
  SET_EDIT_MODAL_IS_OPENED,
  SET_PROPERTY_ID,
  SET_SEARCH_PARAM,
  SET_LAST_NODE,
  SET_HIGHLIGHT_TOP_SELECTOR,
  SET_WEBSITES_VIEW_MODE,
  SET_WIDGETS_VIEW_MODE,
  SET_SELECTED_CUSTOM_REPORT,
  SET_CUSTOM_REPORT_TABLE_CHART_PAGE,
} from '../../../../constants/actionTypes';

export const setDirectParent = (payload) => {
  return {
    type: SET_DIRECT_PARENT,
    payload,
  };
};

export const setDateRangeValue = (payload) => {
  return {
    type: SET_DATE_RANGE_VALUE,
    payload,
  };
};

export const setIsCreateModalOpened = (payload) => {
  return {
    type: SET_CREATE_MODAL_IS_OPENED,
    payload,
  };
};

export const setIsEditModalOpened = (payload) => {
  return {
    type: SET_EDIT_MODAL_IS_OPENED,
    payload,
  };
};

export const setPropertyId = (payload) => {
  return {
    type: SET_PROPERTY_ID,
    payload,
  };
};

export const setCreatedUser = (payload) => {
  return {
    type: SET_CREATED_USER,
    payload,
  };
};

export const setSearchParam = (payload) => {
  return {
    type: SET_SEARCH_PARAM,
    payload,
  };
};

export const setLastNode = (payload) => {
  return {
    type: SET_LAST_NODE,
    payload,
  };
};

export const setHighlightTopSelector = (payload) => {
  return {
    type: SET_HIGHLIGHT_TOP_SELECTOR,
    payload,
  };
};

export const setWebsitesViewMode = (payload) => {
  return {
    type: SET_WEBSITES_VIEW_MODE,
    payload,
  };
};

export const setWidgetsViewMode = (payload) => {
  return {
    type: SET_WIDGETS_VIEW_MODE,
    payload,
  };
};

export const setSelectedCustomReport = (payload) => {
  return {
    type: SET_SELECTED_CUSTOM_REPORT,
    payload,
  };
};

export const setCustomReportTableChartPage = (payload) => {
  return {
    type: SET_CUSTOM_REPORT_TABLE_CHART_PAGE,
    payload,
  };
};
