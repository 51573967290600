import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Helmet } from 'react-helmet';
import Chip from '@material-ui/core/Chip';
import { Button, Icon } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import TooltipButton from '../../composition/TooltipButton';
import { useGraphLazyQuery } from '../../core/hooks';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDateRangeValue,
  selectLastNode,
  selectSearchParam,
  selectSelectedCustomReport,
} from '../../redux/store/ui/common';
import DateRangePickerComponent from '../DateRangePicker';
import { isPermittedToAccess, queryParamFilter } from '../../utils';
import { setDateRangeValue } from '../../redux/store/ui/common';
import { setSearchParam } from '../../redux/store/ui/common';
import { selectCurrentUser, impersonate } from '../../redux/store/auth';
import {
  extractIdFromViewOrEdit,
  getBreadcrumbMap,
  parsePath,
} from './breadcrumb.helpers';
import AddFilterButton from '../Filter/AddFilterButton';
import {
  FETCH_BRAND_BY_ID,
  FETCH_PUBLISHER_BY_ID,
  FETCH_WEBSITE_BY_ID,
  FETCH_USER_BY_ID,
  FETCH_WIDGET_BY_ID,
  FETCH_PROVIDER_BY_ID,
  GET_DOWNLOAD_LINK,
} from '../../graphql/query';
import { useQuery } from '@apollo/client';
import { selectLookUpWidgets } from '../../redux/store/ui/lookUp';
import WidgetCode from '../../pages/Widgets/WidgetCode';
import { useStyles } from './Breadcrumb.styles';
import { selectFilterFor } from '../../redux/store/ui/filters';
import { getViewMode } from '../Filter/helpers/getTargets';
import { QueryVariables } from '../../core/models';
import moment from 'moment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const queryVariables = new QueryVariables();

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0,0,0,0)',
    height: theme.spacing(8),
    color: '#A0A5BA',
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      color: '#2CB374',
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '&:active': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
}))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const BreadcrumbNav = ({ location }) => {
  const dateFormat = 'YYYY-M-D';
  const { pathnames, params } = parsePath(location);
  const viewOrEditPageId = extractIdFromViewOrEdit(location);
  const widgets = useSelector(selectLookUpWidgets);
  const selectedWidget = widgets.find(
    (widget) => widget._id === viewOrEditPageId,
  );
  const appliedFilters = useSelector(selectFilterFor(getViewMode(location)));
  const dateRangeValue = useSelector(selectDateRangeValue);
  const [open, setOpen] = useState(false);
  const selectedReport = useSelector(selectSelectedCustomReport);
  const currentUser = useSelector(selectCurrentUser);
  const searchParam = useSelector(selectSearchParam);
  const lastNode = useSelector(selectLastNode);
  const dispatch = useDispatch();
  const classes = useStyles();
  const breadcrumbNameMap = getBreadcrumbMap(location, currentUser);
  const [propName, setPropName] = useState();
  const [parentId, setParentId] = useState(null);
  const [parentName, setParentName] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [links, setLinks] = React.useState(null);

  const handleClick = (event, links) => {
    setLinks(links);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setLinks(null);
    setAnchorEl(null);
  };

  const directParentFactory = (location) => {
    const { filter } = queryParamFilter(location);
    const propertyType = filter[0]?.fieldType;
    const propertyName = propertyType
      ? propertyType?.replaceAll('Id', 's')
      : null;
    const propertyId = filter[0]?.fieldValue;
    const propertyPluralName =
      propertyName?.charAt(0).toUpperCase() + propertyName?.slice(1);

    return { propertyType, propertyName, propertyPluralName, propertyId };
  };

  const { propertyType, propertyPluralName, propertyId } = directParentFactory(
    location,
  );

  const [getDownloadLink] = useGraphLazyQuery(GET_DOWNLOAD_LINK, (data) => {
    const url = data?.downloadCustomReport?.url;
    if (url) {
      try {
        const win = window.open(url, '_blank');
        win.focus();
      } catch (e) {}
    }
  });

  const downloadCustomReport = () => {
    if (appliedFilters?.version >= 0) {
      queryVariables.clearFilters();
      queryVariables.setOffset(0);
      queryVariables.setUiAppliedFilters(appliedFilters);
    }
    const reportFilter = {
      reportId: selectedReport?._id,
      filters: queryVariables?.filter?.filters,
      dateFilter: selectedReport?.dateFilter,
    };
    if (dateRangeValue?.startDate && dateRangeValue?.endDate) {
      queryVariables.setStartDate(
        moment(dateRangeValue.startDate).format(dateFormat),
      );
      queryVariables.setEndDate(
        moment(dateRangeValue.endDate).format(dateFormat),
      );
      reportFilter.startDate = queryVariables.filter.startDate;
      reportFilter.endDate = queryVariables.filter.endDate;
    }
    getDownloadLink({
      variables: {
        reportFilter,
      },
    });
  };

  const getPropById = (propId) => {
    switch (propId) {
      case 'brandId':
        return {
          query: FETCH_BRAND_BY_ID,
          object: 'data.brand',
          name: 'brandName',
        };
      case 'publisherId':
        return {
          query: FETCH_PUBLISHER_BY_ID,
          object: 'data.publisher',
          name: 'publisherName',
        };

      case 'websiteId':
        return {
          query: FETCH_WEBSITE_BY_ID,
          object: 'data.website',
          name: 'displayName',
        };

      case 'widgetId':
        return {
          query: FETCH_WIDGET_BY_ID,
          object: 'data.widget',
          name: 'widgetName',
          parentId: 'data.widget.website._id',
          parentName: 'data.widget.website.displayName',
        };

      case 'providerId':
        return {
          query: FETCH_PROVIDER_BY_ID,
          object: 'data.provider',
          name: 'providerName',
        };

      case 'userId':
        return {
          query: FETCH_USER_BY_ID,
          object: 'data.user',
          name: 'email',
        };

      default:
        return {
          query: FETCH_BRAND_BY_ID,
          object: 'data.brand',
          name: 'brandName',
        };
    }
  };

  const { data, loading } = useQuery(getPropById(propertyType)?.query, {
    variables: { id: propertyId },
    skip: !propertyId,
  });

  useEffect(() => {
    if (data) {
      if (data && propertyType) {
        const obj = eval(getPropById(propertyType).object);
        let rootId, rootName;
        if (getPropById(propertyType)?.parentId)
          rootId = eval(getPropById(propertyType)?.parentId);
        rootName = eval(getPropById(propertyType)?.parentName);
        if (obj) {
          setPropName(obj[getPropById(propertyType)?.name]);
          if (rootId && rootName) {
            setParentId(rootId);
            setParentName(rootName);
          }
        }
      }
    }
  }, [data, propertyType]);

  if (
    JSON.stringify(searchParam) !== JSON.stringify(params) &&
    !location.search.includes('parent=setup')
  ) {
    dispatch(setSearchParam(params));
  }

  const Impersonate = (payload) => dispatch(impersonate(payload));
  const impersonateUser = (userId) => {
    Impersonate({ userId });
  };

  // useEffect(() => {
  //   if (propertyId) getProp({ variables: { id: propertyId } });
  // }, [propertyId]);

  return (
    <div className={classes.root}>
      {!loading && (
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<span>&#9679;</span>}
          className={classes.breadcurmb}
        >
          {pathnames?.map((value, index) => {
            const last = index === pathnames.length - 1;
            const first = index === 0;
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;
            return (
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                key={`breadcrumb-${index}`}
              >
                <Helmet>
                  <title>{breadcrumbNameMap[to]?.title || 'Ketshwa'}</title>
                </Helmet>
                {first && breadcrumbNameMap[to]?.parent ? (
                  <>
                    {breadcrumbNameMap[to]?.parent?.icon}
                    <StyledBreadcrumb
                      key={breadcrumbNameMap[to]?.parent?.title}
                      component="span"
                      label={breadcrumbNameMap[to]?.parent?.title}
                    />
                    {breadcrumbNameMap[to]?.title && <span>&#9679;</span>}
                  </>
                ) : null}
                {parentId && parentName && to === '/ad-groups' ? (
                  <React.Fragment>
                    <LinkRouter color="inherit" to={`/websites`}>
                      <StyledBreadcrumb label="Websites" />
                    </LinkRouter>
                    <span>&#9679;</span>
                    <LinkRouter
                      color="inherit"
                      to={`/websites/view/${parentId}`}
                    >
                      <StyledBreadcrumb label={parentName} />
                    </LinkRouter>
                    <span>&#9679;</span>
                  </React.Fragment>
                ) : null}
                {propertyId &&
                propertyType &&
                breadcrumbNameMap[to]?.directParents ? (
                  <React.Fragment>
                    <LinkRouter
                      color="inherit"
                      to={`/${breadcrumbNameMap[to]?.directParents
                        ?.find((parent) => parent.title === propertyPluralName)
                        ?.title?.toLowerCase()}`}
                    >
                      <StyledBreadcrumb
                        label={
                          breadcrumbNameMap[to]?.directParents?.find(
                            (parent) => parent.title === propertyPluralName,
                          )?.title
                        }
                      />
                    </LinkRouter>
                    <span>&#9679;</span>
                    <LinkRouter
                      color="inherit"
                      to={`/${breadcrumbNameMap[to]?.directParents
                        ?.find((parent) => parent.title === propertyPluralName)
                        ?.title?.toLowerCase()}/view/${propertyId}`}
                    >
                      <StyledBreadcrumb label={propName} />
                    </LinkRouter>
                    <span>&#9679;</span>
                  </React.Fragment>
                ) : null}
                {last ? (
                  <React.Fragment>
                    {breadcrumbNameMap[to]?.icon &&
                    !breadcrumbNameMap[to]?.parent
                      ? breadcrumbNameMap[to]?.icon
                      : null}
                    <StyledBreadcrumb
                      key={to}
                      component="span"
                      label={
                        (viewOrEditPageId && lastNode) ||
                        breadcrumbNameMap[to]?.title
                      }
                    />
                  </React.Fragment>
                ) : (
                  breadcrumbNameMap[to]?.title && (
                    <LinkRouter color="inherit" to={to} key={to}>
                      <StyledBreadcrumb label={breadcrumbNameMap[to]?.title} />
                    </LinkRouter>
                  )
                )}
              </div>
            );
          })}
        </Breadcrumbs>
      )}
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        style={{ position: 'absolute', right: 0 }}
      >
        {((pathnames.length === 1 &&
          breadcrumbNameMap[`/${pathnames[0]}`]?.enableDatePicker) ||
          location?.pathname?.includes('custom-reports/view')) && (
          <DateRangePickerComponent
            dateRangeUpdated={(value) => {
              dispatch(setDateRangeValue(value));
            }}
          />
        )}
        {selectedReport && (
          <div className={classes.download}>
            <TooltipButton
              title="Download"
              size="small"
              aria-label="Download"
              icon="cloud_download"
              style={{ color: '#848899' }}
              onClick={downloadCustomReport}
            />
          </div>
        )}
        {((pathnames.length === 1 &&
          breadcrumbNameMap[`/${pathnames[0]}`]?.enableFilter) ||
          location?.pathname?.includes('/custom-reports/view')) && (
          <AddFilterButton />
        )}
        {pathnames.length === 1 &&
          breadcrumbNameMap[`/${pathnames[0]}`]?.buttonTitle &&
          isPermittedToAccess(`/${pathnames[0]}/add`, currentUser) && (
            <div>
              <Button
                size="small"
                color="primary"
                className={classes.button}
                disabled={breadcrumbNameMap[`/${pathnames[0]}`]?.buttonDisable}
                startIcon={
                  <Icon>
                    {breadcrumbNameMap[`/${pathnames[0]}`]?.buttonIcon || 'add'}
                  </Icon>
                }
                onClick={() =>
                  breadcrumbNameMap[`/${pathnames[0]}`]?.onClickAdd(
                    location?.search,
                  )
                }
              >
                {breadcrumbNameMap[`/${pathnames[0]}`]?.buttonTitle}
              </Button>
            </div>
          )}
        <div className={classes.tooltipBox}>
          {viewOrEditPageId &&
          breadcrumbNameMap[`/${pathnames[0]}/${pathnames[1]}`]?.tooltipBtns
            ?.length > 0
            ? breadcrumbNameMap[
                `/${pathnames[0]}/${pathnames[1]}`
              ]?.tooltipBtns.map((btn, index) =>
                isPermittedToAccess(btn.link, currentUser) ? (
                  btn.type === 'TooltipButtonLink' ? (
                    <TooltipButton
                      key={index}
                      title={btn.name}
                      component={RouterLink}
                      to={`${btn.link}${btn.params}${viewOrEditPageId}`}
                      size="small"
                      aria-label={btn.name}
                      icon={btn.icon}
                      className={classes.xgap}
                    />
                  ) : btn.type === 'TooltipButton' ? (
                    <TooltipButton
                      key={index}
                      title={btn.name}
                      component={Button}
                      onClick={() => {
                        impersonateUser(viewOrEditPageId);
                      }}
                      size="small"
                      aria-label={btn.name}
                      icon={btn.icon}
                      style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                    />
                  ) : null
                ) : btn.type === 'WidgetCodeButton' ? (
                  <TooltipButton
                    title="Code"
                    size="small"
                    icon="developer_mode"
                    aria-label="code"
                    disabled={!selectedWidget?.isEnabled}
                    onClick={() => setOpen(true)}
                  />
                ) : btn.type === 'DropdownLinks' ? (
                  <TooltipButton
                    key={index}
                    title={btn.name}
                    size="small"
                    aria-label={btn.name}
                    icon={btn.icon}
                    className={classes.xgap}
                    onClick={(e) => handleClick(e, btn.links)}
                  />
                ) : null,
              )
            : null}
        </div>
      </Box>
      <WidgetCode
        widget={selectedWidget}
        open={open}
        onClosed={() => setOpen(false)}
      />
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={!!links}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {links?.map((item, i) => (
          <MenuItem onClick={handleClose}>
            <LinkRouter to={`${item.link}${item.params}${viewOrEditPageId}`}>
              {item.name}
            </LinkRouter>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default BreadcrumbNav;
