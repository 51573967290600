import { gql } from '@apollo/client';

const CREATE_EXTERNAL_REPORT = gql`
  mutation createExternalReport($externalReport: AddExternalReportInput!) {
    addExternalReport(addExternalReportInput: $externalReport) {
      _id
    }
  }
`;

const UPDATE_EXTERNAL_REPORT = gql`
  mutation modifyExternalReport($externalReport: EditExternalReportInput!) {
    editExternalReport(editExternalReportInput: $externalReport) {
      _id
    }
  }
`;

export { CREATE_EXTERNAL_REPORT, UPDATE_EXTERNAL_REPORT };
