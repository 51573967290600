import { gql } from '@apollo/client';

const FULL_SEARCH = gql`
  query search($term: String!) {
    search(term: $term) {
      type
      id
      name
      description
    }
  }
`;

export { FULL_SEARCH };
