import { gql } from '@apollo/client';

const FETCH_PUBLISHERS = gql`
  query fetchPublishers($filter: QueryFilter!) {
    publishers(queryFilter: $filter) {
      items {
        _id
        updatedAt
        publisherName
        publisherStatus
        brand {
          _id
        }
        publisherUser {
          _id
          email
        }
        dealInfo {
          dealType
          value
        }
        isEnabled
        publisherStatus
      }
      page
      totalCount
    }
  }
`;

const FETCH_PUBLISHERS_BY_USER = gql`
  query publishersByUser($userId: String!, $filter: QueryFilter!) {
    publishersByUser(userId: $userId, queryFilter: $filter) {
      items {
        _id
        publisherName
        publisherStatus
        brand {
          _id
        }
        publisherUser {
          _id
          email
        }
        dealInfo {
          dealType
          value
        }
        isEnabled
        publisherStatus
      }
      page
      totalCount
    }
  }
`;

const FETCH_PUBLISHER_BY_ID = gql`
  query fetchPublisher($id: String!) {
    publisher(id: $id) {
      _id
      publisherName
      paymentTerms
      dealInfo {
        dealType
        value
        percentage
      }
      isEnabled
      publisherStatus
      brand {
        _id
        brandName
      }
      publisherUser {
        _id
        firstName
        lastName
      }
      users {
        _id
        firstName
        lastName
        role {
          _id
          roleName
        }
      }
      managers {
        _id
        firstName
        lastName
        role {
          _id
          roleName
        }
      }
      statusHistory {
        status
        createdAt
      }
    }
  }
`;

const PUBLISHER_AUTO_COMPLETE_VALUE = gql`
  query fetchPublisher($id: String!) {
    autoCompleteValue: publisher(id: $id) {
      _id
      name: publisherName
    }
  }
`;

export {
  FETCH_PUBLISHERS,
  FETCH_PUBLISHERS_BY_USER,
  FETCH_PUBLISHER_BY_ID,
  PUBLISHER_AUTO_COMPLETE_VALUE,
};
