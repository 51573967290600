import {
  SET_CREATE_MODAL_IS_OPENED,
  SET_CREATED_USER,
  SET_DATE_RANGE_VALUE,
  SET_DIRECT_PARENT,
  SET_EDIT_MODAL_IS_OPENED,
  SET_FILTER_NAME,
  SET_LAST_NODE,
  SET_PROPERTY_ID,
  SET_SEARCH_PARAM,
  SET_SEARCH_TEXT,
  SET_TOP_SELECTOR_VALUE,
  SET_HIGHLIGHT_TOP_SELECTOR,
  SET_WEBSITES_VIEW_MODE,
  SET_WIDGETS_VIEW_MODE,
  SET_APPLIED_FILTERS_HEIGHT,
  SET_SELECTED_CUSTOM_REPORT,
  SET_CUSTOM_REPORT_TABLE_CHART_PAGE,
} from '../../../../constants/actionTypes';
import { initialState } from './InitialState';

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DIRECT_PARENT:
      return { ...state, directParent: action.payload };
    case SET_LAST_NODE:
      return { ...state, lastNode: action.payload };
    case SET_DATE_RANGE_VALUE:
      return { ...state, dateRangeValue: action.payload };
    case SET_CREATE_MODAL_IS_OPENED:
      return { ...state, isCreateModalOpened: action.payload };
    case SET_EDIT_MODAL_IS_OPENED:
      return { ...state, isEditModalOpened: action.payload };
    case SET_PROPERTY_ID:
      return { ...state, propertyId: action.payload };
    case SET_CREATED_USER:
      return { ...state, createdUser: action.payload };
    case SET_SEARCH_PARAM:
      return { ...state, searchParam: action.payload };
    case SET_HIGHLIGHT_TOP_SELECTOR:
      return { ...state, highlightTopSelector: action.payload };
    case SET_WEBSITES_VIEW_MODE:
      return { ...state, websitesViewMode: action.payload };
    case SET_WIDGETS_VIEW_MODE:
      return { ...state, widgetsViewMode: action.payload };
    case SET_APPLIED_FILTERS_HEIGHT:
      return { ...state, appliedFiltersHeight: action.payload };
    case SET_SELECTED_CUSTOM_REPORT:
      return { ...state, selectedCustomReport: action.payload };
    case SET_CUSTOM_REPORT_TABLE_CHART_PAGE:
      return { ...state, customReportTableChartPage: action.payload };
    default:
      return state;
  }
};
