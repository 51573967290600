import {
  FILTER_FIELD_TYPE_DISPLAYNAME,
  FILTER_OPERATION_EQUALS,
  FILTER_OPERATION_IN,
  PAGE_SIZE,
} from '../../constants/enum';

const getFilterFieldType = (fieldType) => {
  switch (fieldType) {
    case 'roleName':
      return 'role';
    default:
      return fieldType;
  }
};

const getFilterFieldValue = (fieldValue) => {
  switch (fieldValue) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return fieldValue;
  }
};
class AggregationFilter {
  constructor() {
    this.fieldType = FILTER_FIELD_TYPE_DISPLAYNAME;
    this.fieldValue = '';
    this.operator = '';
  }

  deserialize(model) {
    this.fieldType = model.fieldType || FILTER_FIELD_TYPE_DISPLAYNAME;
    this.fieldValue = model.fieldValue || '';
    this.operator = model.operator || '';

    return this;
  }
}

class PaginatedFilter {
  constructor() {
    this.limit = PAGE_SIZE;
    this.offset = 0;
    this.filtersType = 'AND';
  }

  deserialize(model) {
    this.limit = model.limit || PAGE_SIZE;
    this.offset = model.offset || 0;
    this.filtersType = model.filtersType || 'AND';

    return this;
  }
}

class QueryFilter extends PaginatedFilter {
  constructor() {
    super();
    this.filters = [];
  }

  deserialize(model) {
    super.deserialize(model);

    if (model.filters && model.filters?.length) {
      for (let index = 0; index < model.filters.length; index++) {
        const filter = model.filters[index];
        this.filters.push(new AggregationFilter(filter));
      }
    }

    return this;
  }
}

class QueryVariables {
  constructor() {
    this.filter = new QueryFilter();
  }

  deserialize(model) {
    this.filter = new QueryFilter().deserialize(model);
    return this;
  }

  setLimit(limit = PAGE_SIZE) {
    this.filter.limit = limit;
    return this;
  }

  setStartDate(startDate) {
    if (startDate) {
      this.filter.startDate = startDate;
    }
  }

  setTimeZone(timeZone) {
    if (timeZone) {
      this.filter.timezone = timeZone;
    }
  }

  setEndDate(endDate) {
    if (endDate) {
      this.filter.endDate = endDate;
    }
  }

  setOffset(page = 0) {
    this.filter.offset = this.filter.limit * page;
    return this;
  }

  setFilters(filter) {
    if (filter && !Array.isArray(filter))
      this.filter.filters.push(new AggregationFilter().deserialize(filter));
    else if (filter && Array.isArray(filter))
      this.filter.filters = this.filter.filters.concat(filter);
    return this;
  }

  setUiAppliedFilters(appliedFilters) {
    const filterKeys = Object.keys(appliedFilters);
    filterKeys.forEach((fieldType, index) => {
      const filterValues = Object.values(appliedFilters)[index];
      if (fieldType !== 'version') {
        const filterValuesLength = filterValues?.length;
        let fieldValue = '';
        if (filterValuesLength > 1) {
          filterValues.forEach((field, index) => {
            if (index === 0) {
              fieldValue += `${field}`;
            } else {
              fieldValue += `,${field}`;
            }
          });
        } else {
          fieldValue = `${filterValues[0]}`;
        }
        this.setFilters({
          fieldType: getFilterFieldType(fieldType),
          fieldValue,
          operator:
            filterValuesLength > 1
              ? FILTER_OPERATION_IN
              : FILTER_OPERATION_EQUALS,
        });
      }
    });
    if (Object.keys(appliedFilters).length > 1) {
      this.setLimit(4000);
    } else {
      this.setLimit();
    }
  }

  clearFilters() {
    this.setLimit();
    this.filter.filters = [];
    return this;
  }

  setMetrics = (metrics) => {
    this.filter.metrics = metrics;
  };

  setDimensions = (dimensions) => {
    this.filter.dimensions = dimensions;
  };

  setOrderBy = (orderBy) => {
    this.filter.orderBy = orderBy;
  };

  setOrder = (order) => {
    this.filter.order = order;
  };

  clearFilter(filterBy) {
    if (filterBy && this.filter.filters?.length) {
      const index = this.filter.filters.findIndex(
        (filter) => filter.fieldType == filterBy,
      );
      if (index > -1) this.filter.filters.splice(index, 1);
    }
    return this;
  }
}

export { QueryVariables, AggregationFilter, QueryFilter };
