import { gql } from '@apollo/client';

const FETCH_DEDUCTIONS = gql`
  query fetchDeductions {
    getDeductions {
      revenue
      websiteInfo {
        websiteDomain
        _id
      }
      providerInfo {
        providerName
        _id
      }
      externalWidgetInfo {
        _id
        widgetName
      }
    }
  }
`;

export { FETCH_DEDUCTIONS };
