import React from 'react';
import { Checkbox } from 'antd';
import { useStyles } from './Selection.styles';

const Selection = ({ options, setValue, defaultValue }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.selection}>
        <div className={classes.checkboxes}>
          <Checkbox.Group
            options={options}
            onChange={setValue}
            defaultValue={defaultValue}
          />
        </div>
      </div>
    </>
  );
};

export default Selection;
