import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '8px',
  },
  numberDisplayContainer: {
    background: '#fff',
    borderRadius: '5px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  allTimeRevenueValue: {
    fontSize: '13px',
    color: '#2CB374',
  },
}));
